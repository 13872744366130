import React, { useEffect } from "react";
import { Col, Row} from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { DiscussionMasterRequest } from "../Validation";
import moment from "moment";
// import debounce from "lodash/debounce";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    handleSubmit,
    handleChange,
    editValues,
    type,
		discussionTypeList,
		userList,
  }) => {

    // set the form values to edit
    useEffect(() => {

      // if (type === "add") {

      // }
      if (editValues && type === "edit") {
        form.setFieldsValue({
          discussion_type_id: editValues.discussion_type_id,
          discussion_date_time: moment(editValues.discussion_date_time),
          attendees: editValues.attendees_names,
          subject: editValues.subject,
          client_type_id: editValues.client_type_id,
          notes: editValues.notes,
        });

        handleChange();
      }
    }, [editValues, form]);


    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: discussionTypeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="discussion_type_id"
                placeholder="Select discussion Type"
                label="Discussion Type"
                rules={DiscussionMasterRequest().discussion_type_id('Discussion Type')}
              />
            </Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
						<InputBox.DateTimePicker
							name="discussion_date_time"
							placeholder="Select discussion Time"
							label="Discussion Time"
							rules={DiscussionMasterRequest().discussion_date_time('Discussion Time')}
							 />
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
							<InputBox.Select
								options={{
									list: userList,
									valueKey: "id",
									textKey: "name"
								}}
								mode={"multiple"}
								name="attendees"
								placeholder="Select Attendees"
								label="Attendees"
								rules={DiscussionMasterRequest().attendees('Attendees')}
							/>
						</Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                label="Subject"
                autoComplete="false"
                placeholder="Subject"
                name="subject"
                rules={DiscussionMasterRequest().subject("Subject")}
              />
            </Col>
						<Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.TextArea
                label="Notes"
                autoComplete="false"
                placeholder="Notes"
                name="notes"
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
