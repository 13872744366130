import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "antd";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { LockOutlined } from '@ant-design/icons';
import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";
import { Regex } from "../../../config/Validators";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const ChangePasswordRequest = (): RequestProps => ({
    password: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { pattern: Regex.password, message: Message().regex.password(field) }
    ],
    password_confirmation: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { pattern: Regex.password, message: Message().regex.password(field) }
    ],
});
const ChangePassword: React.FC<any> = observer(({ visible, close }) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);
    const { USER, ROOT } = useStore();
    const { ChangePassword } = USER;
    const { assignErrorToInput } = ROOT;
    const drawerClose = () => {
        form.resetFields();
        close();
    };

    const handleSubmit = (data: any) => {
        setSaving(true);

        ChangePassword(data)
            .then(() => {
                setSaving(false);
                drawerClose();
            })
            .catch((e: ErrorProps) => {
                const errors = e?.errors || e.data?.errors
                assignErrorToInput(form, errors);
                setSaving(false);
            });
    };

    return (
        <Modal
            title="Change Password"
            centered
            width={"50%"}
            open={visible}
            onCancel={drawerClose}
            destroyOnClose
            closeIcon={<FontAwesomeIcon icon={faTimes} />}
            footer={[
                <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
                    Close
                </Button>,
                <Button
                    key="1"
                    //disabled={disabled}
                    form="changePasswordForm"
                    loading={saving}
                    htmlType="submit"
                    type="primary"
                >
                    Change Password
                </Button>,
            ]}
        >
            <FormBox form={form} onFinish={handleSubmit} id="changePasswordForm">
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} className="mt-20">
                        <InputBox.Password
                            prefix={<LockOutlined />}
                            name="password"
                            //label="Password"
                            placeholder="New Password"
                            size="large"
                            rules={ChangePasswordRequest().password('Password')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} >
                        <InputBox.Password
                            prefix={<LockOutlined />}
                            name="password_confirmation"
                            //label="Email ID"
                            placeholder="Confirm Password"
                            size="large"
                            rules={ChangePasswordRequest().password_confirmation('Confirm Password')}
                        />
                    </Col>
                </Row>
            </FormBox>
        </Modal>
    );
});

export default ChangePassword;
