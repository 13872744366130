import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { UserMasterRequest } from "../Validation";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

// import debounce from "lodash/debounce";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    handleSubmit,
    handleChange,
    editValues,
    type,
    roleList,
    shiftList,
    departmentList,
    reportingManagerList,
    hideReportingManager,
    setHideReportingManager,
    fetchReportingManager,
  }) => {

    const disableDate = (current: any) => {
      return current && (current > moment().endOf('day') || current < moment().subtract(50, "years"));
    }

    // set the form values to edit
    useEffect(() => {
      if (editValues && type === "edit") {
        form.setFieldsValue({
          firstname: editValues.firstname,
          lastname: editValues.lastname,
          employee_id: editValues.employee_id,
          email: editValues.email,
          phone_number: editValues.phone_number,
          gender: editValues.gender,
          address: editValues.address,
          date_of_joining: moment(editValues?.date_of_joining),
          role_id: editValues.roleId,
          shift_id: editValues.shift_id,
          department_id: editValues.department_id,
          reporting_manager_id: editValues.reporting_manager_id,
          cut_off_points: editValues.cut_off_points,
          roleIds: editValues?.roleId ?? []
        });
        handleChange();
      }
    }, [editValues, form]);

    const configureManagers = async (e:any) => {
      setHideReportingManager(e==1)
      form.setFieldsValue({reporting_manager_id :null})
      await fetchReportingManager(e);
      //form.setFieldsValue({reporting_manager_id : reportingManagerList.filter((element:any)=> {return element.id == editValues?.reporting_manager_id})[0]?.id ?? null});
    } 

    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => {if(type === "edit" ) handleChange();}}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="First Name"
                placeholder="First Name"
                name="firstname"
                rules={UserMasterRequest().firstname("First Name")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Last Name"
                placeholder="Last Name"
                name="lastname"
                rules={UserMasterRequest().lastname("Last Name")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Email"
                autoComplete="false"
                placeholder="Email"
                name="email"
                rules={UserMasterRequest().email("Email")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Phone Number"
                autoComplete="false"
                placeholder="Phone Number"
                name="phone_number"
                rules={UserMasterRequest().phone_number("Phone Number")}
              />
            </Col>

            {type !== "edit" && <> <Col xs={{ span: 24 }} md={{ span: 12 }} >
              <InputBox.Password
                required
                autoComplete="new-password"
                label="Password"
                placeholder="Password"
                name="password"
                rules={UserMasterRequest().password("Password")}
              />
            </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <InputBox.Password
                  name="password_confirmation"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  size="large"
                  rules={UserMasterRequest().password_confirmation('Confirm Password')}
                />
              </Col>
            </>
            }
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: roleList,
                  valueKey: "id",
                  textKey: "name"
                }}
                onChange={(e) => {
                  configureManagers(e)
                }}
                //mode={"multiple"}
                name="role_id"
                placeholder="Select Role"
                label="Select Role"
                rules={UserMasterRequest().role_id('Role')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: departmentList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="department_id"
                placeholder="Select Department"
                label="Select Department"
                rules={UserMasterRequest().department_id('Department')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: shiftList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="shift_id"
                placeholder="Select Shift"
                label="Select Shift"
                rules={UserMasterRequest().shift_id('Shift')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              
             {!hideReportingManager && <InputBox.Select
                options={{
                  list: reportingManagerList.filter((element:any) => {return element.id !=editValues?.id}),
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="reporting_manager_id"
                placeholder="Select Reporting Manager"
                label="Select Reporting Manager"
                rules={UserMasterRequest().reporting_manager_id('Reporting Manager')}
              />}
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Employee Id "
                placeholder="Employee Id"
                name="employee_id"
                rules={UserMasterRequest().employee_id("Employee Id")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                name="cut_off_points"
                placeholder="Cut Off Points"
                label="Cut Off Points"
                rules={UserMasterRequest().cut_off_points("Cut Off Points")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.DatePicker
                name="date_of_joining"
                placeholder="Date Of Joining"
                label="Date Of Joining"
                rules={UserMasterRequest().date_of_joining('Date Of Joining')}
                disabledDate={disableDate}
                suffixIcon={<FontAwesomeIcon icon={faCalendar} />}
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
