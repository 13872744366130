import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import moment from "moment";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import { Button, Switch } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";

interface ListProps {
	rowData: any[];
	getData: () => Promise<any>;
	editRole: (id: number) => void | any;
	ChangeStatus: (data: any) => Promise<any>;
	ShowPermissionModel: (id: number) => void | any;
	editPermission: boolean;
	user: any;
}

const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	editRole,
	ChangeStatus,
	ShowPermissionModel,
	editPermission,
	user,
}) => {
	useEffect(() => {
		getData();
	}, []);

	const changeStatus = (data: any) => {
		ChangeStatus(data)
			.then(() => {
				return;
			})
			.finally(() => {
				return;
			});
	};

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title="Edit Role"
					onClick={() => editRole(params.data["id"])}
					hidden={
						!editPermission ||
						(params.data["slug"] == "super-admin" &&
							user &&
							user?.userRole[0]?.role.slug !== "super-admin")
					}
				>
					<FontAwesomeIcon icon={faPencilAlt} color={Config.blueBtn} />
				</Button>
				{/* <Button
					type="text"
					title="Delete Role"
					
					onClick={() => openDeleteRolePopup(params.data['id'])}
					hidden={!deletePermission}
				>
					<FontAwesomeIcon icon={faTrashAlt} color={Config.redBtn} />
				</Button> */}
			</div>
		);
	};

	const PermissionRenderer = (params: any) => {
		return (
			<div className="action-column-permission">
				<Button
					title="View Permissions"
					key={"permissionbox" + params.data.id}
					onClick={() => ShowPermissionModel(params.data.id)}
				>
					View Permissions
				</Button>
			</div>
		);
	};

	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data["status"]}
					onChange={() =>
						changeStatus({
							id: params.data["id"],
							status: params.data["status"] == 1 ? 0 : 1,
						})
					}
					disabled={!editPermission}
				/>
			</div>
		);
	};

	return	<AgGridWrapper
				rowData={rowData}
				components={{ ActionRenderer, SwitchRenderer, PermissionRenderer }}
				suppressFieldDotNotation={true}
			>
			<AgGridColumn
				field="id"
				headerName="# ID"
				width={100}
				filter={"agNumberColumnFilter"}
			/>
			<AgGridColumn field="name" headerName="Name" />
			<AgGridColumn
				headerName="Permissions"
				cellRenderer="PermissionRenderer"
				sortable={false}
				filter={false}
				width={200}
			/>
			<AgGridColumn
				field="created_by_name"
				headerName="Created By"
				width={190}
			/>
			<AgGridColumn
				headerName="Updated By"
				field="updated_by_name"
				width={190}
			/>
			<AgGridColumn
				field="created_at"
				headerName="Created At"
				valueGetter={({ data }) =>
					data["created_at"] &&
					moment(data["created_at"]).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				field="updated_at"
				headerName="Updated At"
				valueGetter={({ data }) =>
					data["updated_at"] &&
					moment(data["updated_at"]).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				headerName="Actions"
				type="actionColumn"
				sortable={false}
				filter={false}
				width={120}
				minWidth={120}
				pinned={"right"}
			/>
		</AgGridWrapper>
};

export default ListComponent;
