import React from "react";
import LOGO_IMAGE from "../../../assets/images/collapsed-logo.svg";
import CollapseLogo from "../../../assets/images/collapsed-logo.svg";

const LogoComponent: React.FC<any> = ({ collapsed }) => {
	return (
		<>
			<div className="logo__wrapper">
				{collapsed ? (
					<img src={CollapseLogo} alt="LOGO" />
				) : (
					<img src={LOGO_IMAGE} alt="LOGO" />
				)}
			</div>
		</>
	);
};

export default LogoComponent;
