import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Button, Drawer } from "antd";
import useStore from "../../../../../../store";
import FormComponent from "./FormComponent";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { COMMON, CAMPAIGN_KPI, ROOT } = useStore();
  const { EditData, editValues } = CAMPAIGN_KPI;
  const { fetchKpiListDropdownData } = COMMON;
  const { assignErrorToInput } = ROOT;

  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [kpiStatusList, setKpiStatusList] = useState<any>([]);


  useEffect(() => {
    fetchKpiListDropdownData().then((data: any) => {
      setKpiStatusList(data);
      setLoading(false);
    }).catch(() => {
      setKpiStatusList([]);
      setLoading(false);
    })
  }, [visible])

  const drawerClose = () => {
    close();
    form.resetFields();
  };

  const handleSubmit = (data: any) => {
    const dateField: string[] = ['start_date', 'end_date'];
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
          if (dateField.includes(name)) {
            formData.append(name, moment(data[name]).format("YYYY-MM-DD"));
          } else {
            data[name] && formData.append(name, data[name]);
          }
        }

        EditData(formData, editValues.id)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title={editValues?.title ? "Edit " + editValues.title + " KPI" : "Edit KPI"}
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose={true}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="editCampaignKPIform"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="editCampaignKPIform"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        editValues={editValues}
        type="edit"
        kpiStatusList={kpiStatusList}
        loading={loading}
      />
    </Drawer>
  );
});

export default EditComponent;
