import React, { useState } from "react";
import { Button, Divider, Form, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { LockOutlined } from '@ant-design/icons';
import { ResetPasswordRequest } from "./Validation";
import Logo from "../../../assets/images/mavlers-logo.svg";
import LoginBanner from "../../../assets/images/hero-banner-right.png";
import LoginBG from "../../../assets/images/login-pattern.png";

const ResetPasswordOtp: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useNavigate();
	const { AUTH, ROOT } = useStore();
	const { resetPasswordWithOtp } = AUTH;
	const { assignErrorToInput } = ROOT;
	const { token }: any = useParams();
	const handleSubmit = (data: any) => {
		setSaving(true);

		resetPasswordWithOtp(token, data)
			.then(() => {
				history("/");
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e.data?.errors
				assignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	return (
		<section className="loginSection">
			<div className="loginWrap">
				<div className="loginLogo">
					<div className="logo" >
						<img src={Logo} alt="Logo" />
					</div>
					<div className="loginBanner">
						<img src={LoginBanner} alt="Banner" />
					</div>
				</div>
				<div className="loginForm" style={{ backgroundImage: `url(${LoginBG})` }}>
					<FormBox form={form} onFinish={handleSubmit}>
						<InputBox.Password
							prefix={<LockOutlined />}
							name="password"
							//label="Password"
							placeholder="New Password"
							size="large"
							rules={ResetPasswordRequest().password('Password')}
						/>
						<InputBox.Password
							prefix={<LockOutlined />}
							name="password_confirmation"
							//label="Email ID"
							placeholder="Confirm Password"
							size="large"
							rules={ResetPasswordRequest().password_confirmation('Confirm Password')}
						/>
						<div className="text-center">
							<Space className="primary">
								Please enter a new password
							</Space>
						</div>
						<Button
							className="width-250 mt-30 mb-20"
							loading={saving}
							type="primary"
							size="large"
							htmlType="submit"
						>
							Change Password
						</Button>
						<Divider />

						<div className="text-center authback">
							<Space>
								<Link to="/">
									<b>Back to login</b>
								</Link>
							</Space>
						</div>
					</FormBox>
				</div>
			</div>
		</section>
	);
};

export default ResetPasswordOtp;
