import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";
import { Regex } from "../../../config/Validators";


export const LoginRequest = (): RequestProps => ({
    email: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { type: "email", message: Message().regex.other(field) },
    ],
    password: (field?: string) => [{ required: true, message: Message().required.text(field) }],
});

export const ForgotPasswordRequest = (): RequestProps => ({
    email: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { type: "email", message: Message().regex.other(field) },
    ]
});

export const ResetPasswordRequest = (): RequestProps => ({
    password: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { pattern: Regex.password, message: Message().regex.password(field) }
    ],
    password_confirmation: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { pattern: Regex.password, message: Message().regex.password(field) }
    ],
});