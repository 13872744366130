import React, { useEffect, useState } from "react";
import { Drawer, Form, Button } from "antd";
import useStore from "../../../../store";
import FormComponent from "./FormComponent";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";

const AddComponent: React.FC<any> = observer(({ visible, close }) => {
  const { CLIENT, ROOT, COMMON } = useStore();
  const { AddData } = CLIENT;
  const { assignErrorToInput } = ROOT;
  const { clientTypeList, shiftList, fetchShift, fetchClientType } = COMMON;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  //const [fileLogoList, setFileLogoList] = useState<any>([]);

  useEffect(() => {
    fetchClientType();
    fetchShift();
  }, [])
  const drawerClose = () => {
    form.resetFields();
    close();
  };

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
         if (name == 'email') {
            data[name].forEach((element:any) => {
              formData.append('email[]', element.email)
            });
          } else if (name == 'billing_email') {
            data[name].forEach((element:any) => {
              formData.append('billing_email[]', element.billing_email)
            });
          } else {
            data[name] && formData.append(name, data[name]); // there should be values.avatar which is a File object
          }
        }

        AddData(formData)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      })
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
       // setDisabled(false);
      })
      .catch(() => {
       // setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title="Create Client"
      placement="right"
      width={"70%"}
      open={visible}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="addUserForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="addUserForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        type="add"
        shiftList={shiftList}
        clientTypeList={clientTypeList}
      />
    </Drawer>
  );
});

export default AddComponent;
