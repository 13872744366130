import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Form } from 'antd';
import useStore from "../../../../store";
import FormComponent from "./FormComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Notification } from "../../../../config/Global";

const EditRoleComponent: React.FC<any> = observer(({
	visible,
	closeEditModal,
}) => {
	const { ROLE, ROOT } = useStore();
	const {
		permissionList,
		update,
		details,
		editRoleID,
		setRole,
		role,
		showPermissionModel,
		openPermissionModel,
		closePermissionModel
	} = ROLE;
	const drawerClose = () => {
		form.resetFields();
		setPermission([]);
		closeEditModal();
	}
	const { assignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [selectedPermission, setPermission] = useState<any>([]);
	useEffect(() => {
		setPermission([]);

		if (editRoleID) {
			form.resetFields();
			setSaving(true);

			details(editRoleID).then((data: any) => {
				const options: any = [];
				data.data.permissions.forEach((permissionObj: any) => {
					options.push(permissionObj.id);
				});
				setPermission(options);
				form.setFieldsValue({ name: data.data?.name });
				setSaving(false);
			});

		}

	}, [details, editRoleID])

	const onCheckChange = (e: any) => {
		const options = selectedPermission;
		let index
		if (e.target.checked) {
			options.push(+e.target.value)
		} else {
			index = options.indexOf(+e.target.value)
			options.splice(index, 1)
		}
		setPermission(options);
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		if (!selectedPermission.length) {
			Notification.error({ message: 'Please select at least one permission for role!' });
			return;
		}
		//data.id=editRoleID;
		data.permission = selectedPermission;
		setSaving(true);
		update(editRoleID, data)
			.then(() => {
				setSaving(false);
				setPermission([]);
				form.resetFields();
				setRole();
				closeEditModal();
			})
			.catch((e: any) => {
				const errors = e?.errors || e.data?.errors
				assignErrorToInput(form, errors);
				setSaving(false);
			})
	};

	return (
		<Drawer
			title={"Edit " + role?.name + " Role"}
			placement="right"
			width={"70%"}
			onClose={drawerClose}
			destroyOnClose
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			open={visible}
			footer={[
				<Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
					Cancel
				</Button>,
				<Button
					key="1"
					form="editRoleForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<FormComponent
				formId="editRoleForm"
				form={form}
				handleSubmit={handleSubmit}
				permissionList={permissionList}
				selectedPermission={selectedPermission}
				onCheckChange={onCheckChange}
				showPermissionModel={showPermissionModel}
				closePermissionModel={closePermissionModel}
				openPermissionModel={openPermissionModel}
				saving={saving}
			/></Drawer>
	);
});
export default EditRoleComponent;
