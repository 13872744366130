import { Button, Col, Form, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import useStore from "../../../../store";
import { AssignRoleRequest } from "../Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface AssignRoleProps {
    visible?: boolean;
    roleModelValues:any;
    close: () => void;
}

const AssignRoleToUserComponent: React.FC<AssignRoleProps> = ({
    visible,
    roleModelValues,
    close
}) => {
    const { ROOT, USER, COMMON } = useStore();
    const { assignErrorToInput } = ROOT;
    const { fetchRoles,
		roleList } = COMMON;
    const { assignRoleToUser } = USER;
    const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);
    
    const drawerClose = () => {
        close()
    }

    useEffect(() => {
        fetchRoles();
        form.setFieldsValue({
            roleIds:roleModelValues?.roleId ?? []//userRole?.map((element:any) => {return element?.id}) ?? []
        });
    }, [visible])
    const handleAssignRole = (data: any) => {
        setSaving(true);
        const payLoad = {user_id:roleModelValues?.id,roleId : [data.roleIds]}
        assignRoleToUser(payLoad).then(() => {
            close();
        }).catch((e:any) => {
            const errors = e?.errors || e.data?.errors
            assignErrorToInput(form, errors);
        })
        .finally(() => setSaving(false));
    }
    return (
        <Modal
        title={"Assign Role to "+ roleModelValues?.firstname + " "+ roleModelValues?.lastname}
        width={"70%"}
        open={visible}
        onCancel={drawerClose}
        destroyOnClose
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}  
        closeIcon={<FontAwesomeIcon icon={faTimes} />}
        key="Assignrolemodel"
        footer={[
            <Button
                key="okbuttonassignrole"
                loading={saving}
                form="ASSIGN_ROLE_TO_USER_MODEL"
                type="primary"
                htmlType="submit"
            >
                Assign Role
            </Button>,
            <Button key="cancelbuttonassignrole" htmlType="button"  onClick={close} className="cancelBtn">
                Cancel
            </Button>,
        ]}
    >
            <FormBox
                id={"ASSIGN_ROLE_TO_USER_MODEL"}
                form={form}
                encType="multipart/formdata"
                onFinish={handleAssignRole}
                key="formbasichandelassignrole"
            >
                    <Row gutter={6} wrap={false}>
                        <Col span={8} className="form__input__textbox form__input__selectBox">
                            <InputBox.Select
                                options={{
                                    list: roleList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                name="roleIds"
                                hidden={roleList.length<=0}
                                placeholder="Select Role"
                                label="Select Role"
                                rules={AssignRoleRequest()?.roleIds("Role")}
                            />
                        </Col>
                    </Row>
            </FormBox>
        </Modal>
    );
};

export default AssignRoleToUserComponent;
