// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { DefaultPointsSystemBreadcrumb } from "../../../../config/BreadcrumbConfig";

import PointTypeMaster from "./PointTypeMaster";
import { Tabs, TabsProps } from "antd";
import AllPermissionList from "../../../../utils/AllPermissionList";
import useStore from "../../../../store";
import BoosterTireMaster from "./BoosterTireMaster";
import PackageMaster from "./PackageMaster";
const PointSystemMaster: React.FC = observer(() => {
  const {AUTH} = useStore();
  const [tabItem, setTabItem] = useState<TabsProps["items"]>([]);
  useEffect(() => {
    const tabList = [];
		AUTH.checkPermission(AllPermissionList.LIST_POINT_TYPE) && tabList.push({key: 'POINT_TYPE', label: 'Point Types', children: <PointTypeMaster/>});
		AUTH.checkPermission(AllPermissionList.LIST_PACKAGE) && tabList.push({key: 'PACKAGE', label: 'Packages', children: <PackageMaster/>});
		AUTH.checkPermission(AllPermissionList.LIST_BOOSTER_TIRE_MASTER) && tabList.push({ key: 'LIST_BOOSTER_TIRE', label: 'Booster Tires', children: <BoosterTireMaster /> });
		setTabItem(tabList);
  }, []);
  return (
    <>
      <BreadcrumbComponent items={DefaultPointsSystemBreadcrumb.path} />
      <div className="mainPageWrap">
        <ContentBox>
            <Tabs size={'large'} defaultActiveKey="1" items={tabItem} onChange={(key) => {
              key
            }} />
        </ContentBox>
      </div>
    </>
  );
});

export default PointSystemMaster;
