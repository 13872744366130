import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Button, Drawer } from "antd";
import useStore from "../../../../../../store";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Notification } from "../../../../../../config/Global";
import FormComponent from "./FormComponent";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { PACKAGE, ROOT, COMMON } = useStore();
  const { EditData, editValues } = PACKAGE;
  const { fetchCampaign, fetchRoles, campaignList, roleList } = COMMON;
  const { assignErrorToInput } = ROOT;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    setSaving(true);
    fetchRoles().then(() => {
      fetchCampaign().then(() => {
        setSaving(false);
      });
    });
  }, [visible]);
  const drawerClose = () => {
    close();
    form.resetFields();
  };

  const handleSubmit = async (data: any) => {
    if (data?.point_master_data?.length <= 0) {
      Notification.error({ message: "Please enter atleast one role for package!" });
      return;
    }
    let pointPercent: any = 0;
    await data?.point_master_data?.forEach((element: any) => {
      pointPercent = parseFloat(pointPercent) + parseFloat(element?.points);
    });
    if (pointPercent > 100) {
      Notification.error({ message: "Point % total of all role must be maximum 100% !" });
      return;
    } else {
      form
        .validateFields()
        .then(() => {
          setSaving(true);
          EditData(data, editValues.id)
            .then(() => {
              drawerClose();
            })
            .catch((e: any) => {
              if (e.data) {
                assignErrorToInput(form, e?.data.errors);
              }
            })
            .finally(() => setSaving(false));
        });
    }
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title={editValues?.name ? "Edit " + editValues.name + " Package" : "Edit Package"}
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="editPackageForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="editPackageForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        editValues={editValues}
        type="edit"
        campaignList={campaignList}
        roleList={roleList}
        fetchCampaign={fetchCampaign}
      />
    </Drawer>
  );
});

export default EditComponent;
