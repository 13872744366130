import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Button, Drawer } from "antd";
import useStore from "./../../../../store";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Notification } from "../../../../config/Global";
import FormComponent from "./FormComponent";

const AddComponent: React.FC<any> = observer(({ visible, close }) => {
  const { EXTRA_POINT, ROOT, COMMON } = useStore();
  const { AddData } = EXTRA_POINT;
  const { allCampaignList, pointTypeList, campaignTeam, clearCampaignTeam, fetchCampaignTeam,fetchCampaignDropdownData, fetchPointTypeDropdownData } = COMMON;
  const { assignErrorToInput } = ROOT;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    fetchCampaignDropdownData().then(() => {
        fetchPointTypeDropdownData().then(() => {
            //
        });
    })
  }, [visible]);
  const drawerClose = () => {
    close();
    clearCampaignTeam();
    form.resetFields();
  };

  const handleSubmit = (data: any) => {
    if(data?.extra_points?.length <= 0) {
      Notification.error({message:"Please enter atleast one point type!"});
      return;
    }
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        AddData(data)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title={"Add Extra Points"}
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="addExtraPointForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
     <FormComponent
      form={form}
      id="addExtraPointForm"
      handleSubmit={handleSubmit}
      type={'add'}
      campaignTeam={campaignTeam}
      allCampaignList={allCampaignList}
      pointTypeList={pointTypeList}
      handleChange={handleChange}
      fetchCampaignTeam={fetchCampaignTeam}
      />
    </Drawer>
  );
});

export default AddComponent;
