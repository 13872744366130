import { UserOutlined } from "@ant-design/icons";
import { Checkbox, Col, Layout, Row } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { RoleValidation } from "../Validation";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import Config from "../../../../config/Config";

interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	permissionList: any[];
	selectedPermission: any[];
	onCheckChange: (e: any) => void | any;
	showPermissionModel: boolean;
	closePermissionModel: () => void | any;
	openPermissionModel: () => void | any;
	saving: boolean;
}


const FormComponent: React.FC<FormDataProps> = observer(({
	formId,
	form,
	handleSubmit,
	permissionList,
	selectedPermission,
	onCheckChange,
}) => {

	return (
		<>
			<ContentBox>
				<FormBox
					id={formId}
					form={form}
					encType="multipart/formdata"
					onFinish={handleSubmit}
					key="formbasic">

					<Layout style={{ padding: '0 24px' }} className="ml-5 pb-5">
						<Row gutter={6} wrap={false}>
							<Col span={8} className="form__input__textbox">
								<InputBox.Text
									size="large"
									label="Role Name"
									placeholder="Role Name"
									name="name"
									prefix={<UserOutlined />}
									rules={RoleValidation().name()} />
							</Col>
						</Row>
						<Row gutter={12} wrap={false}>
							<Col span={24} className="form__input__textbox">
								<div className="ant-col ant-form-item-label" >
									<label className="ant-form-item-required" style={{ color: Config.redBtn }}>Do note that view permission is required in order to provide other permissions of that module.</label>
								</div>
							</Col>
						</Row>
						<Row gutter={6} wrap={false}>
							<Col span={8} className="form__input__textbox">
								<div className="ant-col ant-form-item-label">
									<label className="ant-form-item-required">Role Permissions</label>
								</div>
							</Col>
						</Row>
						<Row gutter={6} wrap={false}>
							<Col span={24} className="form__input__textbox">
								<div className="permission__table">
									<table key="table">
										<thead key="thead">
											<tr key="headtr">
												<th key="modules">
													Modules
												</th>
												<th colSpan={5} key="permissions">
													Permissions
												</th>
											</tr>
										</thead>
										<tbody key={"module"}>

											{permissionList?.map((item: any) => {
												return (
													<tr key={"tr__" + item.id + Math.random().toString()}>
														<td key={"td_" + item.id.toString() + Math.random().toString()}><b key={item.id.toString() + item.name + Math.random().toString()}>{item.name}</b></td>
														<td key={"td__" + Math.random().toString()}>
															{item.permissions.map((permission: any) => (
																<>
																	<div className="ant-col-12" key={"div__" + Math.random().toString()} >
																		<Checkbox onChange={onCheckChange} name={"permission"}
																			value={permission.id}
																			key={"checkbvalue" + permission.id.toString() + Math.random().toString()}
																			defaultChecked={(selectedPermission.indexOf(permission.id) >= 0) ? true : false} /> {permission.permission_name} &nbsp;
																	</div>
																</>
															))}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</Col>
						</Row>
					</Layout>
				</FormBox>
			</ContentBox>
		</>
	);
});
export default FormComponent;
