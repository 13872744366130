import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Upload, } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { TestimonialMasterRequest } from "../Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { LoadingOutlined, PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { Notification } from "../../../../config/Global";
import API_URL from "../../../../config/ApiUrl";

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].indexOf(file.type) >= 0;
  if (!isJpgOrPng) {
    Notification.error({ message: 'You can only upload JPG/PNG/GIF file!' })
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    Notification.error({ message: "Image must be smaller than 5MB!" });
  }
  return isJpgOrPng && isLt2M;
};

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    configureCampaignwiseDropdown,
    shiftList,
    handleSubmit,
    editValues,
    allCampaignList,
    platformList,
    accountManagerList,
    tlList,
    smeList,
    seniorList,
    executorList,
    loading,
    fetchCampaignDetails,
    managerList,
  }) => {
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<any>(null);
    
    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
      if (info.file.status === 'uploading') {
        setImageLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        setFormResponse(info?.file?.response);
        setImageUrl(info?.file?.response?.data?.file_url);
        setImageLoading(false);
      }
      if (info.file.status === 'error') {
        setFormResponse(null);
        setImageUrl(null);
        setImageLoading(false);
      }
    };

    const handleCancel = () => {
      setImageUrl(null);
      setFormResponse(null);
    };

    const setFormResponse = (response: any) => {
      form.setFieldsValue({
        snapshot_url: response?.data?.file_name ?? null,
        snapshot_show_url: response?.data?.file_url ?? null
      });
    }
    // set the form values to edit
    useEffect(() => {
      if (editValues) {
        form.setFieldsValue({
          title: editValues.title,
          platform_master_id: editValues.platform_master_id,
          manager_id: editValues.manager_id,
          campaign_id: editValues?.campaign_id,
          client_email: editValues.client_email,
          shift_id: editValues.shift_id,
          link: editValues.link,
          nbd_id: editValues.nbd_id,
          ba_id: editValues.ba_id,
          account_manager_id: editValues.account_manager_id,
          executor_id: editValues.executor_id,
          senior_id: editValues.senior_id,
          sme_id: editValues.sme_id,
          tl_id: editValues.tl_id,
          snapshot_url: editValues?.snapshot_url,
          snapshot_show_url: editValues?.snapshot_show_url,
        });
        setImageUrl(editValues?.snapshot_show_url);
        if (editValues.recieved_at) {
          form.setFieldsValue({
            recieved_at: editValues.recieved_at ? moment(editValues.recieved_at) : null,
          });
        }
      }
    }, [loading]);

    const disableDate = (current: any) => {
      return current && (current > moment().endOf('day') || current < moment().subtract(50, "years"));
    }
    const uploadButton = (
      <div>
        {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      </div>
    );
    return (
      <>
        <FormBox
          form={form}
          disabled={editValues && editValues.status !== 0}
          id={id}
          onFinish={handleSubmit}
          initialValues={{ proposal_status: 0 }}
        //onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Title"
                placeholder="Title"
                name="title"
                rules={TestimonialMasterRequest().title("Title")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: platformList,
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="platform_master_id"
                placeholder="Platform"
                label="Platform"
                rules={TestimonialMasterRequest().platform_master_id('Platform')}
              />
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Client Email"
                placeholder="Client Email"
                name="client_email"
                rules={TestimonialMasterRequest().client_email("Client Email")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                name="link"
                placeholder="Link"
                label="Link"
                rules={TestimonialMasterRequest().link('Link')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: allCampaignList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Campaign"
                label="Campaign"
                name="campaign_id"
                onChange={(e) => {
                  fetchCampaignDetails(e).then((data: any) => {
                    form.setFieldsValue({
                      manager_id: data?.manager_id,
                      account_manager_id: data?.account_manager_id,
                      shift_id: data?.shift_id,
                      executor_id: data?.executor_id,
                      senior_id: data?.senior_id,
                      sme_id: data?.sme_id,
                      tl_id: data?.tl_id
                    });
                    configureCampaignwiseDropdown(e);
                  })
                }}
                rules={TestimonialMasterRequest().campaign_id('Campaign')}
              />
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: shiftList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="GEO"
                label="GEO"
                name="shift_id"
                rules={TestimonialMasterRequest().shift_id('GEO')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: accountManagerList,
                  valueKey: "id",
                  textKey: "name"
                }}
                required={true}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="account_manager_id"
                placeholder="Account Manager"
                label="Account Manager"
                rules={TestimonialMasterRequest().account_manager_id('Account Manager')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                required={true}
                options={{
                  list: managerList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="manager_id"
                placeholder="Manager"
                label="Manager"
                rules={TestimonialMasterRequest().manager_id('Manager')}
              />
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: tlList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                required={true}
                name="tl_id"
                placeholder="Team Lead"
                label="Team Lead"
                rules={TestimonialMasterRequest().tl_id('Team Lead')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: smeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="sme_id"
                placeholder="SME"
                label="SME"
                required={true}
                rules={TestimonialMasterRequest().sme_id('SME')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: seniorList,
                  valueKey: "id",
                  textKey: "name"
                }}
                required={true}
                showSearch={true}
                allowClear={true}
                mode={"multiple"}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="senior_id"
                placeholder="Senior"
                label="Senior"
                rules={TestimonialMasterRequest().tl_id('Senior')}
              />
            </Col>
            <Form.Item hidden={true} name="snapshot_url">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item hidden={true} name="snapshot_show_url">
              <Input type="hidden" />
            </Form.Item>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: executorList,
                  valueKey: "id",
                  textKey: "name"
                }}
                mode={"multiple"}
                allowClear={true}
                showSearch={true}
                required={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="executor_id"
                placeholder="Executor"
                label="Executor"
                rules={TestimonialMasterRequest().tl_id('Executor')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.DatePicker
                label="Recieved At"
                disabledDate={disableDate}
                name={"recieved_at"}
                rules={TestimonialMasterRequest().recieved_at("Recieved At")}
                suffixIcon={<FontAwesomeIcon icon={faCalendar} />} />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <div className="ant-col ant-form-item-label"><label title="Snapshot" className="ant-form-item-required ">Snapshot</label></div>
              <Upload
                name="image"
                action={
                  process.env.REACT_APP_API_URL + API_URL.FILE_UPLOAD}
                headers={{
                  Authorization: "Bearer " + localStorage.getItem('token')
                }}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                className="avatar-uploader"
                showUploadList={false}
                listType="picture-card"
              >
                {imageUrl ? null : uploadButton}
              </Upload>
              {imageUrl ? <>
                <div >
                  <img className="avatar-uploader" alt="example" style={{ width: '20%', height: "20%" }} src={imageUrl} />
                  <CloseCircleOutlined onClick={handleCancel} title="Remove Image" />
                </div>
              </> : null}
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
