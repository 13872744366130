import { IServerSideGetRowsParams } from "ag-grid-community";
import { FormInstance } from "antd";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import { ErrorProps, FormattedErrorProps } from "./RootInterface";

export default class RootStore {
  public login_redirect_timer?: any;
  constructor() {
    makeAutoObservable(this);
    this.setAxiosBaseUrl();
    window.addEventListener('storage', this.handleInvalidToken);
  }

  public handleInvalidToken = (e: any) => {
    if (e.key === 'token') {
      if (e.oldValue && !e.newValue) {
        window.location.href = "/";
        this.resetStore();
      } else if (!e.oldValue && e.newValue) {
        window.location.reload();
      }
    }
  }

  public setAxiosBaseUrl = (): void => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL || "";
  };

  public resetStore = (): void => {
    localStorage.clear();
  };

  public setAxiosHeaders = (access_token?: string): Promise<any> => {
    if (access_token) localStorage.setItem("token", access_token);
    const auth_token = localStorage.getItem("token");
    if (auth_token && auth_token !== "undefined") {
      return Promise.resolve(auth_token);
    } else {
      return Promise.reject();
    }
  };

  public assignErrorToInput = (
    form: FormInstance,
    errors?: ErrorProps
  ): void => {
    const formatedErrors: FormattedErrorProps[] = [];
		if (errors) {
      Object.keys(errors).forEach((key) => {
      	formatedErrors.push({
					name: key,
					errors: errors[key],
				});
			});
			formatedErrors.forEach((x) => {
				if (typeof x.name === "string" && x.name.indexOf(".") !== -1) {
					const name: any = x.name.split(".");
					name.forEach((e: any) => {
						if (!isNaN(parseInt(e))) {
							e = parseInt(e);
						}
					});
				}
			});
		}
		form.setFields(formatedErrors);
  };

  public getServerListPayload = (params: IServerSideGetRowsParams) => {
    return {
      filter_data: params.request.filterModel,
      sort_data: params.request.sortModel,
      per_page: (params.request.endRow ?? 0) - (params.request.startRow ?? 0),
      page: Math.ceil(
        ((params.request.startRow ?? 0) + 1) /
        ((params.request.endRow ?? 0) - (params.request.startRow ?? 0))
      ),
    };
  };
}
