import { RequestProps } from "../../../../config/InterfacesAndTypes";
import Message from "../../../../config/Message";

export const DiscussionMasterRequest = (): RequestProps => ({
    discussion_type_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    discussion_date_time: (field?: string) =>[{ required: true, message: Message().required.text(field) }],
    attendees: (field?: string) =>[{ required: true, message: Message().required.text(field) }],
    subject: (field?: string) =>[{ required: true, message: Message().required.text(field) }],
    notes: (field?: string) =>[{ required: true, message: Message().required.text(field) }],
});
