import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import moment from "moment";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../config/Config";
import DateComparator from "../../../../../utils/DateComparator";
import { Button, Switch } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
interface ListProps {
	rowData: any[];
	getData: () => Promise<any>;
	editTemplate: (id: number) => void | any;
	ChangeStatus: (data: any) => Promise<any>;
	editPermission: boolean;
}

const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	editTemplate,
	ChangeStatus,
	editPermission,
}) => {
	useEffect(() => {
		getData();
	}, [getData]);

	const changeStatus = (data: any) => {
		ChangeStatus(data)
			.then(() => {
				return;
			})
			.finally(() => {
				return;
			});
	}

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title="Edit Role"
					onClick={() => editTemplate(params.data['id'])}
					hidden={!editPermission}
				>
					<FontAwesomeIcon icon={faPencilAlt} color={Config.blueBtn} />
				</Button>
			</div>
		);
	};

	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data['status']}
					onChange={() => changeStatus({ id: params.data['id'], status: params.data['status'] == 1 ? 0 : 1 })}
					disabled={!editPermission}
				/>
			</div>
		);
	};

	return rowData ? (

		<AgGridWrapper rowData={rowData} components={{ ActionRenderer, SwitchRenderer }} suppressFieldDotNotation={true}>
			<AgGridColumn field="id" headerName="# ID" width={100} filter={'agNumberColumnFilter'} />
			<AgGridColumn field="title" headerName="Title" />
			<AgGridColumn field="subject" headerName="Subject" />
			<AgGridColumn
				field="created_by_name"
				headerName="Created By"
				width={190}
			/>
			<AgGridColumn
				headerName="Updated By"
				field="updated_by_name"
				width={190}
			/>
			<AgGridColumn
				field="created_at"
				headerName="Created At"
				valueGetter={({ data }) =>
					data['created_at'] &&
					moment(data['created_at']).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				field="updated_at"
				headerName="Updated At"
				valueGetter={({ data }) =>
					data['updated_at'] &&
					moment(data['updated_at']).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				headerName="Actions"
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
				pinned={"right"}
			/>
		</AgGridWrapper>
	) : <></>;
};

export default ListComponent;
