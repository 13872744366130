import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";
import { maxMobile, maxName, maxUrl, minMobile, Regex } from "../../../config/Validators";



export const AssignRoleRequest = ():RequestProps => ({
	user_id: (field?:string) => [{ required: true, message: Message().required.text(field) }],
	roleIds: (field?:string) => [{ required: true, message: Message().required.select(field) }],
});

export const UserMasterRequest = (): RequestProps => ({
    firstname: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    lastname: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    employee_id: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    email: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
        { pattern: Regex.email, message: Message().regex.other(field) },
    ],
    phone_number: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { pattern: Regex.mobile, message: Message().regex.other(field)},
        { min: minMobile, message: Message().min.string(minMobile, field) },
        { max: maxMobile, message: Message().max.string(maxMobile, field) },
    ],
    // address: (field?: string) => [
    //     { required: true, message: Message().required.text(field) },
    //     { max: maxName, message: Message().max.string(maxName, field) },
    // ],
    password: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxUrl, message: Message().max.string(maxUrl, field) },
    ],
    password_confirmation: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { pattern: Regex.password, message: Message().regex.password(field) }
    ],
    date_of_joining: (field?: string) =>[{ required: true, message: Message().required.text(field) }],
    //gender: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    role_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    department_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    shift_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    reporting_manager_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    cut_off_points: (field) =>[
        { required: true, message: Message().required.text(field) },
        {
        pattern: /^[1-9]{1,10}$|(?=^.{1,10}$)^([1-9]\d*)(\.\d{0,2})?$/,
        message: "Please enter cut off points with minimum 1 and with 2 decimal points only."
    }],
	
});
