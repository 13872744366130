const API_URL = {
	ADMIN_LOGIN: "admin/login",
	FORGET_PASSWORD: "admin/forget-password",
	RESET_PASSWORD_OTP: (otp: string): string =>
		"admin/" + otp + "/reset-password",
	REGISTER_WITH_EMAIL: "register",
	ME: "admin/me",
	CHANGE_PASSWORD: "admin/change-password",
	LOGOUT: "admin/logout",
	VERIFY_ACCOUNT: (verificationCode: string): string =>
		"verify-email/" + verificationCode,
	RESEND_VERIFICATION_LINK: (email: string): string =>
		"resend-verification-code/" + btoa(email),
	ADMIN_FORGOT_PASSWORD: "admin/forget-password",
	FORGOT_PASSWORD: "forget-password",
	ROLE_LIST: "role-list",
	SHIFT_LIST: "shift-list",
	DEPARTMENT_LIST: "department-list",
	MANAGER_LIST: "reporting-manager-list",
	CLIENT_TYPE_LIST: "client-type-list",
	SERVICE_TYPE_LIST: "service-type-list",
	CAMPAIGN_LIST: "campaign-list",
	CURRENCY_LIST: "currency-list",
	DISCUSSION_TYPE_LIST: "discussion-type-list",
	WORK_MODEL_LIST: "work-model-list",
	USER_LIST: "user-list",
	CLIENT_LIST: "client-list",
	ROLE_WISE_USER_LIST: "role-wise-user-list",
	QUOTE_DROPDOWN_LIST: "quote-dropdown-list",
	CAMPAIGN_TEAM_DROPDOWN_LIST: "campaign-team-dropdown-list",
	KPI_STATUS_LIST: "kpi-status-list",
	PACKAGE_MASTER_LIST: "package-master-list",
	CAMPAING_LIST: "all-campaign-list",
	PLATFORM_LIST: "platform-list",
	POINT_TYPE_LIST: "point-type-master-list",
	TESTIMONIAL_LIST: "testimonial-dropdown-list",
	CAMPAIGN_TEAM: "campaign-team",
	ESCALATION_LIST: "escalation-dropdown-list",
	FILE_UPLOAD: "admin/file-upload",
	USER: {
		LIST: "admin/user",
		CREATE: "admin/user/create",
		DETAILS: (id: number): string => "admin/user/" + id + "/details",
		UPDATE: (id: number): string => "admin/user/" + id + "/update",
		DELETE: (id: number): string => "admin/user/" + id + "/delete",
		CHANGE_STATUS: (id: number): string =>
		"admin/user/" + id + "/change-status",
		REWARD_POINTS: (id: number): string => "admin/user/" + id + "/reward-points",
		MY_REWARD_POINTS: "admin/user/my-reward-points",
		MY_PROFILE: "admin/user/my-profile",
	},

	ROLE: {
		LIST: "admin/role",
		CREATE: "admin/role/create",
		ASSIGN_ROLE_TO_USER: "admin/role/assign-role",
		PERMISSION_LIST: "admin/role/permissionlist",
		DETAILS: (id: number): string => "admin/role/" + id + "/details",
		UPDATE: (id: number): string => "admin/role/" + id + "/update",
		DELETE: (id: number): string => "admin/role/" + id + "/delete",
		CHANGE_STATUS: (id: number): string =>
			"admin/role/" + id + "/change-status",
	},
	TEMPLATE: {
		LIST: "admin/template",
		DETAILS: (id: number): string => "admin/template/" + id + "/details",
		UPDATE: (id: number): string => "admin/template/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/template/" + id + "/change-status",
	},
	CLIENT: {
		LIST: "admin/client",
		CREATE: "admin/client/create",
		DETAILS: (id: number): string => "admin/client/" + id + "/details",
		UPDATE: (id: number): string => "admin/client/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/client/" + id + "/change-status",
		DELETE: (id: number): string => "admin/client/" + id + "/delete",
	},
	QUOTE_DISCUSSION: {
		LIST: "admin/quote-discussion",
		CREATE: "admin/quote-discussion/create",
		DETAILS: (id: number): string =>
			"admin/quote-discussion/" + id + "/details",
		UPDATE: (id: number): string => "admin/quote-discussion/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/quote-discussion/" + id + "/change-status",
		DELETE: (id: number): string => "admin/quote-discussion/" + id + "/delete",
	},
	SERVICE_TYPE: {
		LIST: "admin/service-type",
		CREATE: "admin/service-type/create",
		DETAILS: (id: number): string => "admin/service-type/" + id + "/details",
		UPDATE: (id: number): string => "admin/service-type/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/service-type/" + id + "/change-status",
		DELETE: (id: number): string => "admin/service-type/" + id + "/delete",
	},
	CAMPAIGN_TYPE: {
		LIST: "admin/campaign-master",
		CREATE: "admin/campaign-master/create",
		DETAILS: (id: number): string => "admin/campaign-master/" + id + "/details",
		UPDATE: (id: number): string => "admin/campaign-master/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/campaign-master/" + id + "/change-status",
		DELETE: (id: number): string => "admin/campaign-master/" + id + "/delete",
	},
	CURRENCY: {
		LIST: "admin/currency",
		CREATE: "admin/currency/create",
		DETAILS: (id: number): string => "admin/currency/" + id + "/details",
		UPDATE: (id: number): string => "admin/currency/" + id + "/update",
		CONVRSION_RATE: "admin/currency/update-conversion-rate",
		CHANGE_STATUS: (id: number): string =>
			"admin/currency/" + id + "/change-status",
		DELETE: (id: number): string => "admin/currency/" + id + "/delete",
	},
	DISCUSSION_TYPE: {
		LIST: "admin/discussion-type",
		CREATE: "admin/discussion-type/create",
		DETAILS: (id: number): string => "admin/discussion-type/" + id + "/details",
		UPDATE: (id: number): string => "admin/discussion-type/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/discussion-type/" + id + "/change-status",
		DELETE: (id: number): string => "admin/discussion-type/" + id + "/delete",
	},
	ESCALATION_TYPE: {
		LIST: "admin/escalation-type-master",
		CREATE: "admin/escalation-type-master/create",
		DETAILS: (id: number): string => "admin/escalation-type-master/" + id + "/details",
		UPDATE: (id: number): string => "admin/escalation-type-master/" + id + "/update",
		DELETE: (id: number): string => "admin/escalation-type-master/" + id + "/delete",
	},
	ESCALATION: {
		LIST: "admin/escalation",
		CREATE: "admin/escalation/create",
		DETAILS: (id: number): string => "admin/escalation/" + id + "/details",
		UPDATE: (id: number): string => "admin/escalation/" + id + "/update",
		DELETE: (id: number): string => "admin/escalation/" + id + "/delete",
	},
	WORK_MODEL: {
		LIST: "admin/work-model",
		CREATE: "admin/work-model/create",
		DETAILS: (id: number): string => "admin/work-model/" + id + "/details",
		UPDATE: (id: number): string => "admin/work-model/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/work-model/" + id + "/change-status",
		DELETE: (id: number): string => "admin/work-model/" + id + "/delete",
	},
	QUOTE: {
		LIST: "admin/quote",
		CREATE: "admin/quote/create",
		DETAILS: (id: number): string => "admin/quote/" + id + "/details",
		UPDATE: (id: number): string => "admin/quote/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/quote/" + id + "/change-status",
		DELETE: (id: number): string => "admin/quote/" + id + "/delete",
	},
	CAMPAIGN: {
		LIST: "admin/campaign",
		PAUSED_LIST: "admin/campaign/paused-list",
		LOST_LIST: "admin/campaign/lost-list",
		CREATE: "admin/campaign/create",
		DETAILS: (id: number): string => "admin/campaign/" + id + "/details",
		UPDATE: (id: number): string => "admin/campaign/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/campaign/" + id + "/change-status",
		GET_REPORT: (id: number): string => "admin/campaign/" + id + "/get-report-link",
		UPDATE_REPORT: (id: number): string => "admin/campaign/" + id + "/update-report-link",
	},
	CAMPAIGN_DISCUSSION: {
		LIST: "admin/campaign-discussion",
		CREATE: "admin/campaign-discussion/create",
		DETAILS: (id: number): string =>
			"admin/campaign-discussion/" + id + "/details",
		UPDATE: (id: number): string =>
			"admin/campaign-discussion/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/campaign-discussion/" + id + "/change-status",
		DELETE: (id: number): string =>
			"admin/campaign-discussion/" + id + "/delete",
	},
	CAMPAIGN_TASK: {
		LIST: "admin/campaign-task",
		CREATE: "admin/campaign-task/create",
		DETAILS: (id: number): string => "admin/campaign-task/" + id + "/details",
		UPDATE: (id: number): string => "admin/campaign-task/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/campaign-task/" + id + "/change-status",
		DELETE: (id: number): string => "admin/campaign-task/" + id + "/delete",
	},
	CAMPAIGN_KPI: {
		LIST: "admin/campaign-kpi",
		CREATE: "admin/campaign-kpi/create",
		DETAILS: (id: number): string => "admin/campaign-kpi/" + id + "/details",
		UPDATE: (id: number): string => "admin/campaign-kpi/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
		"admin/campaign-kpi/" + id + "/change-status",
		DELETE: (id: number): string => "admin/campaign-kpi/" + id + "/delete",
		CLIENT_KPI_LIST: "admin/campaign-kpi/client-kpi",
	},
	CAMPAIGN_FEEDBACK: {
		LIST: "admin/campaign-feedback",
		CREATE: "admin/campaign-feedback/create",
		DETAILS: (id: number): string =>
			"admin/campaign-feedback/" + id + "/details",
		UPDATE: (id: number): string => "admin/campaign-feedback/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/campaign-feedback/" + id + "/change-status",
	},
	CHECKLIST: {
		LIST: "admin/campaign-checklist-master",
		CREATE: "admin/campaign-checklist-master/create",
		DETAILS: (id: number): string =>
			"admin/campaign-checklist-master/" + id + "/details",
		UPDATE: (id: number): string =>
			"admin/campaign-checklist-master/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/campaign-checklist-master/" + id + "/change-status",
		DELETE: (id: number): string =>
			"admin/campaign-checklist-master/" + id + "/delete",
	},
	BOOSTER_TIRE: {
		LIST: "admin/booster-tire-master",
		CREATE: "admin/booster-tire-master/create",
		DETAILS: (id: number): string =>
			"admin/booster-tire-master/" + id + "/details",
		UPDATE: (id: number): string =>
			"admin/booster-tire-master/" + id + "/update",
	},
	SHIFT: {
		LIST: "admin/shift-geo",
		CREATE: "admin/shift-geo/create",
		DETAILS: (id: number): string => "admin/shift-geo/" + id + "/details",
		UPDATE: (id: number): string => "admin/shift-geo/" + id + "/update",
		CHANGE_STATUS: (id: number): string =>
			"admin/shift-geo/" + id + "/change-status",
		DELETE: (id: number): string => "admin/shift-geo/" + id + "/delete",
	},
	CAMPAIGN_CHECKLIST: {
		LIST: "admin/campaign-checklist",
		CREATE: "admin/campaign-checklist/create",
		DETAILS: "admin/campaign-checklist/details",
		ADD_COMMENT: "admin/campaign-checklist/comment",
		CHANGE_STATUS: "admin/campaign-checklist/change-status",
	},
	POINT_TYPE: {
		LIST: "admin/point-type",
		DETAILS: (id: number): string => "admin/point-type/" + id + "/details",
		UPDATE: (id: number): string => "admin/point-type/" + id + "/update",
	},
	PACKAGE: {
		LIST: "admin/package",
		CREATE: "admin/package/create",
		DETAILS: (id: number): string => "admin/package/" + id + "/details",
		UPDATE: (id: number): string => "admin/package/" + id + "/update",
	},
	TESTIMONIAL: {
		LIST: "admin/testimonial",
		CREATE: "admin/testimonial/create",
		DETAILS: (id: number): string => "admin/testimonial/" + id + "/details",
		UPDATE: (id: number): string => "admin/testimonial/" + id + "/update",
		CHANGE_STATUS: (id: number): string => "admin/testimonial/" + id + "/change-status",
	},
	EXTRA_POINT: {
		LIST: "admin/extra-point",
		CREATE: "admin/extra-point/create",
		DETAILS: (id: number): string => "admin/extra-point/" + id + "/details",
		UPDATE: (id: number): string => "admin/extra-point/" + id + "/update",
		CHANGE_STATUS: (id: number): string => "admin/extra-point/" + id + "/change-status",
	},

};

export default API_URL;
