import React, { useEffect, useState } from "react";
import { Checkbox, Col, Row, } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { QuoteMasterRequest } from "../Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
// import debounce from "lodash/debounce";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    //handleChange,
    editValues,
    type,
    clientList,
    serviceTypeList,
    campaignList,
    currencyList,
    fetchCampaign,
    nbdList,
    baList,
    accountManagerList,
    proposalStatus,
    questionnaireStatus,
    onCheckChange,
    sendLink,
    converted,
    tlList,
    smeList,
    seniorList,
    executorList,
    loading
  }) => {
    const [emailList, setEmailList] = useState([]);
    const [finalamount, setfinalamount] = useState(0);
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [questionLink, setquestionLink] = useState('');
    const [billingEmailList, setBillingEmailList] = useState([]);

    // set the form values to edit
    useEffect(() => {
      if (editValues && type === "edit") {
        configureEmails(editValues?.client_id);
        form.setFieldsValue({
          campaign_name: editValues.campaign_name,
          client_id: editValues.client_id,
          email: editValues.email,
          billing_email: editValues.billing_email,
          service_type_id: serviceTypeList?.filter((element: any) => { return element.id == editValues?.service_type_id }).length > 0 ? editValues.service_type_id : null,
          campaign_type_id: campaignList?.filter((element: any) => { return element.id == editValues?.campaign_type_id }).length > 0 ? editValues.campaign_type_id : null,
          nbd_id: editValues.nbd_id,
          ba_id: editValues.ba_id,
          account_manager_id: editValues.account_manager_id,
          executor_id: editValues.executor_id,
          senior_id: editValues.senior_id,
          sme_id: editValues.sme_id,
          tl_id: editValues.tl_id,
          service_document_link: editValues.service_document_link,
          company_name: editValues.company_name,
          phone_number: editValues.phone_number,
          client_type_id: editValues.client_type_id,
          shift_id: editValues.shift_id,
          proposal: editValues.proposal,
          proposal_status: editValues.proposal_status,
          questionnaire_status: editValues.questionnaire_status,
          currency_id: editValues.currency_id,
          amount: editValues.amount,
          discount: editValues.discount,
          final_amount: editValues.final_amount,
          notes: editValues.notes,
        });

        if (editValues.start_date) {
          form.setFieldsValue({
            start_end_date: editValues.start_date ? [moment(editValues.start_date), moment(editValues.end_date)] : [null, null],
          });
        }

        if (editValues.tenure_start_date) {
          form.setFieldsValue({
            tenure_start_end_date: editValues.tenure_start_date ? [moment(editValues.tenure_start_date), moment(editValues.tenure_end_date)] : [null, null],
          });
        }
        configureCampaignType(editValues?.service_type_id, editValues?.campaign_type_id ?? null);
        setCurrencySymbol(currencyList.filter((ele: any) => { return ele.id == editValues.currency_id })[0]?.symbol ?? '');
        setFinalAmount();
        setquestionLink(serviceTypeList.filter((element: any) => { return element.id == editValues?.service_type_id })[0]?.link ?? '');
        //handleChange();
      }
    }, [loading]);

    const configureEmails = (clientId: any) => {
      const selectedClient = clientList?.length > 0 ? clientList.filter((client: any) => { return client.id == clientId })[0] : {};
      if (selectedClient?.id) {
        const emailData = selectedClient?.email?.split(', ')?.map((email: string) => { return { id: email, value: email } }) ?? []
        const billingEmailData = selectedClient?.billing_email?.split(', ')?.map((email: string) => { return { id: email, value: email } }) ?? []
        editValues && emailData?.filter((ele: any) => ele.value == editValues.email).length < 1 && emailData.push({ id: editValues.email, value: editValues.email });
        editValues && editValues?.billing_email && billingEmailData?.filter((ele: any) => ele.value == editValues.billing_email) < 1 && billingEmailData.push({ id: editValues.billing_email, value: editValues.billing_email });
        form.resetFields(['billing_email', "email"]);
        setEmailList(emailData);
        setBillingEmailList(billingEmailData);
      } else {
        form.resetFields(['billing_email', "email"]);
        setEmailList([]);
        setBillingEmailList([]);
      }
    }
    const configureCampaignType = (serviceTypeId: any, campaignTypeId: any = null) => {
      fetchCampaign(serviceTypeId ?? null, campaignTypeId ?? null);
      !campaignTypeId && form.resetFields(['campaign_type_id']);
      setquestionLink(serviceTypeList.filter((element: any) => { return element.id == serviceTypeId })[0]?.link ?? '');
    }
    const setFinalAmount = () => {
      setfinalamount(form?.getFieldsValue().amount > 0 && form?.getFieldsValue().discount > 0 ? form?.getFieldsValue().amount * (100 - form?.getFieldsValue().discount ?? 0) / 100 : form?.getFieldsValue().amount);
    }
    return (
      <>
        <FormBox
          form={form}
          id={id}
          disabled={editValues?.quote_status > 0}
          // onFinish={handleSubmit}
          initialValues={{ proposal_status: 0 }}
        //onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: clientList,
                  valueKey: "id",
                  textKey: "name"
                }}
                onChange={(e) => {
                  configureEmails(e)
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="client_id"
                placeholder="Select Client"
                label="Select Client"
                rules={QuoteMasterRequest().client_id('Client')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Campaign Name"
                placeholder="Campaign Name"
                name="campaign_name"
                rules={QuoteMasterRequest().campaign_name("Campaign Name")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: emailList,
                  valueKey: "id",
                  textKey: "value"
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="email"
                placeholder="Select Email"
                label="Select Email"
                rules={QuoteMasterRequest().email('Email')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: billingEmailList,
                  valueKey: "id",
                  textKey: "value"
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="billing_email"
                placeholder="Select Billing Email"
                label="Select Billing Email"
                rules={QuoteMasterRequest().billing_email('Billing Email', converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: serviceTypeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                onChange={(e) => { configureCampaignType(e) }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="service_type_id"
                placeholder="Select Service Type"
                label="Select Service Type"
                rules={QuoteMasterRequest().service_type_id('Service Type')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: campaignList,
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="campaign_type_id"
                placeholder="Select Campaign Type"
                label="Select Campaign Type"
                rules={QuoteMasterRequest().campaign_type_id('Campaign Type', converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Questionnaire Link"
                placeholder="Questionnaire Link"
                value={!questionLink || questionLink.length <= 0 ? "No Link" : questionLink}
                disabled={true}
              />
            </Col>
            {!editValues?.questionnaire_status || editValues?.questionnaire_status == 0 ?
              <Col xs={{ span: 24 }} md={{ span: 12 }} >
                <br />
                <div hidden={!questionLink || questionLink.length <= 0}><Checkbox  name={"questionnaire_status"} id="questionnaire_status" checked={sendLink} defaultChecked={editValues?.questionnaire_status > 0} key={'questionnaire_status' + Math.random().toString()}
                  onChange={onCheckChange}>Send Questionnaire</Checkbox></div>
              </Col> :
              <Col xs={{ span: 24 }} md={{ span: 12 }} hidden={!questionLink || questionLink.length <= 0}>
                <InputBox.Select
                hidden={!questionLink || questionLink.length <= 0}
                  options={{
                    list: questionnaireStatus.filter((ele: any) => { return [0].indexOf(ele.id) < 0 }),
                    valueKey: "id",
                    textKey: "value"
                  }}
                  showSearch={true}
                  filterOption={(input, option: any) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={true}
                  placeholder="Questionnaire Status"
                  label="Questionnaire Status"
                  name="questionnaire_status"
                />
              </Col>}

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: nbdList,
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="nbd_id"
                placeholder="Select NBD"
                label="Select NBD"
                rules={QuoteMasterRequest().nbd_id('NBD')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: baList,
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="ba_id"
                placeholder="Select BA"
                label="Select BA"
                rules={QuoteMasterRequest().ba_id('BA', converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: accountManagerList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="account_manager_id"
                placeholder="Select Account Manager"
                label="Select Account Manager"
                rules={QuoteMasterRequest().account_manager_id('Account Manager', converted)}
              />
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: tlList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="tl_id"
                placeholder="Select Team Lead"
                label="Select Team Lead"
                rules={QuoteMasterRequest().tl_id('Team Lead', converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: smeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="sme_id"
                placeholder="Select SME"
                label="Select SME"
                rules={QuoteMasterRequest().tl_id('SME', converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: seniorList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                mode={"multiple"}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="senior_id"
                placeholder="Select Senior"
                label="Select Senior"
                rules={QuoteMasterRequest().tl_id('Senior', converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: executorList,
                  valueKey: "id",
                  textKey: "name"
                }}
                mode={"multiple"}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="executor_id"
                placeholder="Select Executor"
                label="Select Executor"
                rules={QuoteMasterRequest().tl_id('Executor', converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text

                name="service_document_link"
                placeholder="Service Document Link"
                label="Service Document Link"
                rules={QuoteMasterRequest().service_document_link('Service Document Link', converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.TextArea
                placeholder="Proposal"
                label="Proposal"
                name={"proposal"}
                rules={QuoteMasterRequest().proposal('Proposal')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: proposalStatus,
                  valueKey: "id",
                  textKey: "value"
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Proposal Status"
                label="Proposal Status"
                allowClear={true}
                name="proposal_status"
                rules={QuoteMasterRequest().proposal_status("Start and end date", converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}></Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.DateRangePicker
                label="Start Date - End Date"
                name={"start_end_date"}
                rules={QuoteMasterRequest().start_end_date("Start and end date", converted)}
                suffixIcon={<FontAwesomeIcon icon={faCalendar} />} />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.DateRangePicker
                label="Tenure Start Date - End Date"
                name={"tenure_start_end_date"}
                rules={QuoteMasterRequest().start_end_date("Tenure Start and end date", converted)}
                suffixIcon={<FontAwesomeIcon icon={faCalendar} />} />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: currencyList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="currency_id"
                placeholder="Select Currency"
                label="Select Currency"
                onChange={(e) => {
                  setCurrencySymbol(currencyList.filter((ele: any) => { return ele.id == e })[0]?.symbol ?? '');
                }}
                rules={QuoteMasterRequest().currency_id('Currency', converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Number
                required
                label="Amount"
                placeholder="Amount"
                name="amount"
                min={1}
                onChange={() => { setFinalAmount() }}
                rules={QuoteMasterRequest().amount("Amount", converted)}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Number
                required
                size="large"
                label="Discount %"
                placeholder="Discount %"
                name="discount"
                onChange={() => { setFinalAmount() }}
                rules={QuoteMasterRequest().discount("Discount %")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} >

              <div className="ant-col ant-form-item-label"> <label className="ant-form-item-required"> Final Amount</label></div>
              <br />
              <b style={{ padding: '10px' }}>{currencySymbol} {finalamount}</b>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.TextArea
                placeholder="Notes"
                label="Notes"
                name={"notes"}
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
