import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Tabs, TabsProps } from "antd";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { useParams } from "react-router-dom";
import { CampaignDetailsBreadcrumb } from "../../../../config/BreadcrumbConfig";
import TeamDetails from "./TeamDetailsComponent/TeamDetails";
import TaskMaster from "./TaskMaster";
import useStore from "../../../../store";
import AllPermissionList from "../../../../utils/AllPermissionList";
import CampaignKpiMaster from "./CampaignKpi";
import CampaignFeedbackMaster from "./CampaignFeedback";
import CampaignChecklistMaster from "./CampaignChecklist";

const CampaignDetailsComponent: React.FC<any> = observer(() => {
	const { AUTH, CAMPAIGN } = useStore();
	const { details, editValues } = CAMPAIGN;
	const { id } = useParams();
	const [tabItem, setTabItem] = useState<TabsProps["items"]>([]);

  useEffect(() => {
    details(id).then(() => {
		const tabList = [];
		AUTH.checkPermission(AllPermissionList.LIST_TASK) && tabList.push({ key: 'LIST_TASK', label: 'Tasks', children: <TaskMaster id={id} campaignDetails={editValues} /> });
		AUTH.checkPermission(AllPermissionList.VIEW_TEAM_DETAILS) && tabList.push({ key: 'LIST_CAMPAIGN_KPI', label: 'KPI & Reports', children: <CampaignKpiMaster id={id} /> });
		AUTH.checkPermission(AllPermissionList.VIEW_TEAM_DETAILS) && tabList.push({ key: 'EDIT_CAMPAIGN', label: 'Team & Details', children: <TeamDetails id={id} /> });
		AUTH.checkPermission(AllPermissionList.LIST_CAMPAIGN_CHECKLIST) && tabList.push({ key: 'LIST_CAMPAIGN_CHECKLIST', label: 'Checklists', children: <CampaignChecklistMaster id={id} /> });
		AUTH.checkPermission(AllPermissionList.LIST_CAMPAIGN_FEEDBACK) && tabList.push({ key: "LIST_CAMPAIGN_FEEDBACK", label: "Feedbacks", children: <CampaignFeedbackMaster id={id} />,});
		setTabItem(tabList);
    });
  }, [id])

	return (
		<>
			<BreadcrumbComponent items={CampaignDetailsBreadcrumb.path} />
			<div className="mainPageWrap">
				<ContentBox>
					<Tabs
						size={"large"}
						defaultActiveKey="LIST_CAMPAIGN_KPI"
						items={tabItem}
						onChange={(key) => {
							key
						}}
					/>
				</ContentBox>
			</div>
		</>
	);
});

export default CampaignDetailsComponent;
