import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

interface HeaderButtonsProps {
	addRoleRedirect: () => void;
	addPermission : boolean;
	assignRoleToUserPermission : boolean;
	OpenAssignRoleModel :  () => void | any;
}
const HeaderButtons: React.FC<HeaderButtonsProps>= ({addRoleRedirect, addPermission, assignRoleToUserPermission}) => {
	return (
		<>
			<Space hidden={!addPermission && !assignRoleToUserPermission}>
				<Button
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => {addRoleRedirect()}}
					key="createButton"
				>
					Create
				</Button>
				{/* <Button
					hidden={!assignRoleToUserPermission}
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => OpenAssignRoleModel()}
					key="assignrolebutton"
				>
					Assign Role To User
				</Button> */}
			</Space>
		</>
	);
};

export default HeaderButtons;
