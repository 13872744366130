// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import useStore from "../../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import { PageHeader } from "antd";
import RecordPerPage from "../../../../../components/AgGridWrapper/RecordPerPage";
import ChangeStatusComponent from "./components/ChangeStatusComponent";

const TaskMaster: React.FC<any> = observer(({ id, campaignDetails }) => {
  const { CAMPAIGN_TASK, COMMON } = useStore();
  const {
    setPageSize,
    perPage,
    details,
    setupGrid,
    selectedCampaignId,
    setSelectedCampaign,
    ChangeStatus,
  } = CAMPAIGN_TASK;
  const { fetchQuoteDropdownData } = COMMON;
  const [addModal, setAddModal] = useState<boolean>(false);
  const [changeStatusModal, setChangeStatusModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [seniorList, setSeniorList] = useState<any>([]);
  const [executorList, setExecutorList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {

    setSelectedCampaign(id);
    fetchQuoteDropdownData(id).then((data: any) => {
      setSeniorList(data?.seniorList);
      setExecutorList(data?.executorList);
      setLoading(false);
    }).catch(() => {
      setSeniorList([]);
      setExecutorList([]);
      setLoading(false);
    })
  }, [id])
  const openAddModal = () => {
    setAddModal(true);
  };

  const closeAddModal = () => {
    setAddModal(false);
  };

  const openChangeStatusModal = (data: any) => {
    details(data['id']).then(() => {
      setChangeStatusModal(true);
    })
  };

  const closeChangeStatusModal = () => {
    setChangeStatusModal(false);
  };

  const openEditModal: any = (data: any) => {
    details(data['id']).then(() => {
      //setEditValues(data?.user);
      setEditModal(true);
    })
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  return (
    <>
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          extra={[
            <HeaderButtons key={1} open={openAddModal} />,
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
          <ListComponent
            setupGrid={setupGrid}
            campaignDetails={campaignDetails}
            ChangeStatus={ChangeStatus}
            openEditModal={openEditModal}
            openChangeStatusModal={openChangeStatusModal}
            selectedCampaignId={selectedCampaignId}
          />
          <AddComponent visible={addModal} close={closeAddModal} id={id} />
          <EditComponent visible={editModal} close={closeEditModal} id={id} executorList={executorList} seniorList={seniorList} loading={loading} />
          <ChangeStatusComponent visible={changeStatusModal} close={closeChangeStatusModal} />
        </ContentBox>
      </div>
    </>
  );
});

export default TaskMaster;
