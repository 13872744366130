import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";
import { maxMobile, maxName, minMobile, Regex } from "../../../config/Validators";



export const ClientMasterRequest = (): RequestProps => ({
    firstname: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    lastname: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    company_name: (field?: string) => [
        //{ required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    email: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    billing_email: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    phone_number: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { pattern: Regex.mobile, message: Message().regex.other(field)},
        { min: minMobile, message: Message().min.string(minMobile, field) },
        { max: maxMobile, message: Message().max.string(maxMobile, field) },
    ],
    client_type_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    shift_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
});
