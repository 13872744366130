import { RequestProps } from "../../../../../config/InterfacesAndTypes";
import Message from "../../../../../config/Message";
import { Regex } from "../../../../../config/Validators";

export const PackageMasterRequest = (): RequestProps => ({
    title: (field?: string) =>[{ required: true, message: Message().required.text(field) }],
    package_number: (field?: string) =>[{ required: true, message: Message().required.text(field) }],
    campaign_type_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    point_convertion_ratio: (field?: string) =>[
        { required: true, message: Message().required.select(field) },
        { pattern: /^[1-9][0-9]?$|^100$/, message: "Please enter points convertion between 1 to 100"}
    ],
    points: (field?: string) =>[
        { required: true, message: Message().required.select(field) },
        { min: 1, message: Message().min.select(1, field) },
        { pattern: Regex.twoDecimal, message: Message().regex.decimal(2) },
    ],
    role_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
});