import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "antd";
import useStore from "../../../../../../store";
import FormComponent from "./FormComponent";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";

const AddComponent: React.FC<any> = observer(({ visible, close }) => {
  const { CAMPAIGN_TYPE, ROOT, COMMON } = useStore();
  const { AddData } = CAMPAIGN_TYPE;
  const { serviceTypeList,fetchServiceType } = COMMON;
  const { assignErrorToInput } = ROOT;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  useEffect(() => {
    fetchServiceType()
  }, [])
  const drawerClose = () => {
    form.resetFields();
    close();
  };

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
          data[name] && formData.append(name, data[name]);
         
        }

        AddData(formData)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      })
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
       // setDisabled(false);
      })
      .catch(() => {
       // setDisabled(true);
      });
  }, 500);

  return (
    <Modal
      title="Create Campaign"
      centered
      width={"70%"}
      open={visible}
      onCancel={drawerClose}
      destroyOnClose={true}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="addServiceTypeForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="addServiceTypeForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        serviceTypeList={serviceTypeList}
        type="add"
      />
    </Modal>
  );
});

export default AddComponent;
