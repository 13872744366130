// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React from "react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { MyRewardPointMasterBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import ListComponent from "./components/ListComponent";
import { PageHeader } from "antd";
import MyPointsSummery from "./components/MyPointsSummery";

const MyRewardPointMaster: React.FC = observer(() => {
  const { MY_REWARD_POINT_STORE } = useStore();
  const {
    setupGrid,
  } = MY_REWARD_POINT_STORE;

  return (
    <>
      <BreadcrumbComponent items={MyRewardPointMasterBreadcrumb.path} />
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          title={MyRewardPointMasterBreadcrumb.title}
          // extra={[
          //   <RecordPerPage
          //     key={2}
          //     style={{ width: "180px" }}
          //     defaultValue={perPage + " per page"}
          //     onChange={setPageSize}
          //   />,
          // ]}
        />
        <ContentBox>
        <MyPointsSummery/>
        
          <ListComponent
            setupGrid={setupGrid}
          />
        </ContentBox>
      </div>
    </>
  );
});

export default MyRewardPointMaster;
