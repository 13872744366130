// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React from "react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { ServiceTypeMasterBreadcrumb } from "../../../../config/BreadcrumbConfig";

import ServiceTypeMaster from "./ServiceTypeMaster";
import { Tabs, TabsProps } from "antd";
import useStore from "../../../../store";
import CampaignTypeMaster from "./CampaignTypeMaster";

const ServiceTypeAndCampaignMaster: React.FC = observer(() => {
    const { COMMON } = useStore();
    const { fetchServiceType } = COMMON;
  
    const items: TabsProps['items'] = [
        {
          key: 'Service_Type',
          label: 'Service Types',
          children: <ServiceTypeMaster/>,
        },
        {
          key: 'Campaign',
          label: 'Campaigns',
          children: <CampaignTypeMaster/>,
        },
      ];
  return (
    <>
      <BreadcrumbComponent items={ServiceTypeMasterBreadcrumb.path} />
      <div className="mainPageWrap">
        <ContentBox>
            <Tabs size={'large'} defaultActiveKey="1" items={items} onChange={(key) => {
              if (key == 'Campaign') {
                fetchServiceType();
              }
            }} />
        </ContentBox>
      </div>
    </>
  );
});

export default ServiceTypeAndCampaignMaster;
