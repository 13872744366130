import React from "react";
import { HomeOutlined } from "@ant-design/icons";
import { BreadcrumbConfigProps } from "./InterfacesAndTypes";

const defaultBreadcrumbPath = [
  { name: <HomeOutlined />, link: "/" },
];

export const UserMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Users",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Users", link: "/user-management" },
  ],
};

export const MyRewardPointMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Reward Points",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Reward Points", link: "/my-reward-points" },
  ],
};
export const RoleMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Roles & Permissions",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Roles & Permissions", link: "/role-management" },
  ],
};
export const TemplateMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Email Templates",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Email Templates", link: "/template-management" },
  ],
};

export const ClientMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Clients",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Clients", link: "/client-management" },
  ],
};

export const ServiceTypeMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Service Types And Campaigns",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Service Types And Campaigns", link: "/service-type-campaign-management" },
  ],
};

export const CurrencyMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Currencies",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Currencies", link: "/currency-management" },
  ],
};
export const DiscussionTypeMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Discussion Types",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Discussion Types", link: "/discussion-type-management" },
  ],
};

export const WorkModelMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Work Models",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Work Models", link: "/work-model-management" },
  ],
};

export const QuoteMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Quotes",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Quotes", link: "/quote-management" },
  ],
};

export const CampaignMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Campaigns",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Campaigns", link: "/campaign-management" },
  ],
};

export const EscalationMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Escalations",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Escalations", link: "/escalation-management" },
  ],
};

export const CampaignDetailsBreadcrumb: BreadcrumbConfigProps = {
  title: "Campaigns",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Campaigns", link: "/campaign-management" },
    { name: "Campaign Details", link: "" },
  ],
};
export const PausedCampaignBreadcrumb: BreadcrumbConfigProps = {
  title: "Paused Campaigns",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Business Analysis", link: "" },
    { name: "Paused Campaigns", link: "/business-analysis/paused-campaigns" },
  ],
};
export const LostCampaignBreadcrumb: BreadcrumbConfigProps = {
  title: "Lost Campaigns",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Business Analysis", link: "" },
    { name: "Lost Campaigns", link: "/business-analysis/lost-campaigns" },
  ],
};
export const ClientKpiBreadcrumb: BreadcrumbConfigProps = {
  title: "Client KPIs",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Business Analysis", link: "" },
    { name: "Client KPIs", link: "/business-analysis/client-kpi-management" },
  ],
};

export const CampaignChecklistMasterBreadcrumb: BreadcrumbConfigProps = {
  title: "Campign Checklists",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Campign Checklists", link: "/checklist-management" }
  ],
};

export const ShiftGeoBreadcrumb: BreadcrumbConfigProps = {
  title: "Shift/GEO",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Shift/GEO", link: "/shift-geo-management" },
  ],
};

export const EscalationTypeBreadcrumb: BreadcrumbConfigProps = {
  title: "Escalation Types",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Escalation Types", link: "/escalation-types-management" },
  ],
};

export const DefaultPointsSystemBreadcrumb: BreadcrumbConfigProps = {
  title: "Default Points System",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Default Points System", link: "/point-system-management" },
  ],
};

export const TestimonialBreadcrumb: BreadcrumbConfigProps = {
  title: "Testimonials",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Testimonials", link: "/testimonial-management" },
  ],
};

export const ExtraPointsBreadcrumb: BreadcrumbConfigProps = {
  title: "Extra Points",
  path: [
    ...defaultBreadcrumbPath,
    { name: "Extra Points", link: "/extra-point-management" },
  ],
};
