import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ExtraPointMasterRequest } from "../Validation";
import Message from "../../../../config/Message";
// import debounce from "lodash/debounce";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    handleSubmit,
    handleChange,
    editValues,
    type,
    campaignTeam,
    allCampaignList,
    pointTypeList,
    fetchCampaignTeam,
    setCampaignTeam,
  }) => {

    // set the form values to edit
    useEffect(() => {
      if (type === "add") {
        form.setFieldsValue({
          extra_points: [{
            user_id: null,
            type: null,
            points: null
          }],
        });
      }
      if (editValues && type === "edit") {
        form.setFieldsValue({
          title: editValues.title,
          point_type_id: editValues.point_type_id,
          campaign_id: editValues.campaign_id,
        });
        const extraPointDetails: any = [];
        fetchCampaignTeam(editValues.campaign_id).then((data: any) => {
          setCampaignTeam(data);
          editValues?.extraPointUserMatrix?.forEach((element: any) => {
            data.filter((campaignElement: any) => {
              return element?.user_id == campaignElement.id
            }).length == 0 && setCampaignTeam([...data, { id: element?.user_id, name: element?.user?.firstname + " " + element?.user?.lastname }]);
            extraPointDetails.push({
              user_id: element?.user_id,
              type: element?.type,
              points: element?.points
            })
          });
          form.setFieldsValue({
            extra_points: extraPointDetails,
          });
        })
        handleChange();
      }
    }, [editValues, form]);


    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
          disabled={editValues && editValues?.status > 0}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Select
                name={'point_type_id'}
                options={{
                  list: pointTypeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                placeholder="Point Type"
                label="Point Type"
                rules={ExtraPointMasterRequest().point_type_id("Point Type")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Text
                required
                label="Title"
                placeholder="Title"
                name="title"
                rules={ExtraPointMasterRequest().title("Title")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Select
                name={'campaign_id'}
                options={{
                  list: allCampaignList,
                  valueKey: "id",
                  textKey: "name"
                }}
                onChange={(e) => { fetchCampaignTeam(e) }}
                showSearch={true}
                filterOption={(input, option: any) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Campaign"
                label="Campaign"
                rules={ExtraPointMasterRequest().campaign_id("Campaign")}
              />
            </Col>
            {campaignTeam && campaignTeam.length > 0 ? <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Form.List name="extra_points">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={15} key={key} >
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'user_id']}
                            rules={ExtraPointMasterRequest().user_id("User")}
                          >
                            <InputBox.Select
                              options={{
                                list: campaignTeam,
                                valueKey: "id",
                                textKey: "name"
                              }}
                              showSearch={true}
                              filterOption={(input, option: any) =>
                                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              //mode={"multiple"}
                              placeholder="User"
                              label="User"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 4 }}>

                          <Form.Item
                            {...restField}
                            name={[name, 'type']}
                            rules={ExtraPointMasterRequest().type("Type")}
                          >
                            <InputBox.Radio
                              options={{
                                list: [{ id: 1, value: "+" }, { id: 2, value: "-", }],
                                valueKey: "id",
                                textKey: "value"
                              }}
                              //mode={"multiple"}
                              label="Type"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>

                          <Form.Item
                            {...restField}
                            name={[name, 'points']}
                            rules={[
                              { required: true, message: Message().required.text("Points") },
                              {
                                pattern: /^[1-9]{1,10}$|(?=^.{1,10}$)^([1-9]\d*)(\.\d{0,2})?$/,
                                message: "Please enter points with minimum 1 and with 2 decimal points only"
                              },
                            ]}
                          >
                            <InputBox.Text label="Points" placeholder="Points" />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 2 }} hidden={editValues && editValues?.status > 0}>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} hidden={editValues && editValues?.status > 0}>
                        Add More
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col> : ''}
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
