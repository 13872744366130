import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Button, Drawer } from "antd";
import useStore from "../../../../../../store";
import FormComponent from "./FormComponent";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const EditComponent: React.FC<any> = observer(({ visible, close, id }) => {
  const { COMMON, CAMPAIGN_TASK, ROOT } = useStore();
  const { EditData, editValues } = CAMPAIGN_TASK;
  const { fetchCampaignTeamDropdownData } = COMMON;
  const { assignErrorToInput } = ROOT;

  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [seniorList, setSeniorList] = useState<any>([]);
  const [executorList, setExecutorList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);


  useEffect(() => {
    fetchCampaignTeamDropdownData(id, editValues?.id).then((data: any) => {
      setSeniorList(data?.seniorList);
      setExecutorList(data?.executorList);
      setLoading(false);
    }).catch(() => {
      setSeniorList([]);
      setExecutorList([]);
      setLoading(false);
    })
  }, [visible])

  const drawerClose = () => {
    close();
    form.resetFields();
  };

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
          if (name == 'estimated_delivery_date') {
            formData.append(name, moment(data[name]).format("YYYY-MM-DD"));
          } else {
            data[name] && formData.append(name, data[name]);
          }

        }

        EditData(formData, editValues.id)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title={editValues?.title ? "Edit " + editValues.title + " Task" : "Edit Task"}
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose={true}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="editCampaignTaskForm"
          loading={saving}
          hidden={editValues?.status >= 5}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="editCampaignTaskForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        editValues={editValues}
        type="edit"
        executorList={executorList}
        seniorList={seniorList}
        loading={loading}
      />
      {editValues?.statusHistory?.length > 0 ?
        <>
          {editValues?.statusHistory?.map((element: any) => {
            return <p key={element.id}>{<> {"> "}<b>{moment(element.updated_at).format("YYYY-MM-DD hh:mm:ss A")}</b> {element.history_comment} </>}</p>
          })}
        </> : <></>}
    </Drawer>
  );
});

export default EditComponent;
