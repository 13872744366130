import { RequestProps } from "../../../../../config/InterfacesAndTypes";
import Message from "../../../../../config/Message";
import { maxName } from "../../../../../config/Validators";

export const CampaignKpiRequest = (): RequestProps => ({
    title: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    start_date: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    end_date: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    status: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
});

export const MonthEndReportRequest = (): RequestProps => ({
    month_end_report_link: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: 255, message: Message().max.string(255, field) },
    ]
});
