import { RequestProps } from "../../../../../config/InterfacesAndTypes";
import Message from "../../../../../config/Message";
import { maxDescription, maxName } from "../../../../../config/Validators";




export const TaskMasterRequest = (): RequestProps => ({
    title: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    assign_to_id: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    senior_id: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    estimated_delivery_date: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    status: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    notes: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxDescription, message: Message().max.string(maxDescription, field) },
    ],
});
