import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../../config/Config";
import moment from "moment";
import DateComparator from "../../../../../../utils/DateComparator";
import { observer } from "mobx-react";

const ListComponent: React.FC<any> = observer(({
  setupGrid
}) => {
  return (
    <>
      <AgGridWrapper
        onGridReady={setupGrid}
        type="serverSide"
        serverSideInfiniteScroll={true}
        suppressFieldDotNotation={true}
      >
        <AgGridColumn field='order_number' headerName="#CAMPAIGN ID" filter={"agNumberColumnFilter"}/>
        <AgGridColumn field='campaign_name' headerName="Campaign Name" />
        <AgGridColumn field='client_name' headerName="Client Name" />
        <AgGridColumn field='email' headerName="Email" />
        <AgGridColumn field='shift_name' headerName="GEO" />
        <AgGridColumn field='manager_name' headerName="Manager" />
				<AgGridColumn field="reason" headerName="Reason for Lost" sortable={false} filter={false}/>
        <AgGridColumn field='notes' headerName="Notes" />
        <AgGridColumn
          field="status_change_at"
          headerName="Lost At"
          valueGetter={({ data }) =>
            data['status_change_at'] && moment(data['status_change_at']).format(Config.dateTimeFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}

        />

      </AgGridWrapper>
    </>
  );
});

export default ListComponent;
