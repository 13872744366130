// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import useStore from "../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import { PageHeader } from "antd";
import RecordPerPage from "../../../../components/AgGridWrapper/RecordPerPage";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { CurrencyMasterBreadcrumb } from "../../../../config/BreadcrumbConfig";
import ConversionRateComponent from "./components/ConversionRateComponent";

const CurrencyMaster: React.FC = observer(() => {
  const { CURRENCY } = useStore();
  const {
    setPageSize,
    perPage,
    details,
    setupGrid,
    ChangeStatus,
  } = CURRENCY;

  const [addModal, setAddModal] = useState<boolean>(false);
  const [conversionModel, setConversion] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);

  const openAddModal = () => {
    setAddModal(true);
  };

  const closeAddModal = () => {
    setAddModal(false);
  };
 
  const openConversionModel = () => {
    setConversion(true);
  };
  const closeConversionModel = () => {
    setConversion(false);
  };

  const openEditModal: any = (data: any) => {
    details(data['id']).then(() => {
      //setEditValues(data?.user);
      setEditModal(true);
    })
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  return (
    <>
    <BreadcrumbComponent items={CurrencyMasterBreadcrumb.path} />
				
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          title={CurrencyMasterBreadcrumb.title}
          extra={[
            <HeaderButtons key={1} open={openAddModal} openConversionModel={openConversionModel}/>,
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
          <ListComponent
            setupGrid={setupGrid}
            ChangeStatus={ChangeStatus}
            openEditModal={openEditModal}
          />
          <AddComponent visible={addModal} close={closeAddModal} />
          <ConversionRateComponent visible={conversionModel} close={closeConversionModel} />
          <EditComponent visible={editModal} close={closeEditModal}/>
        </ContentBox>
      </div>
    </>
  );
});

export default CurrencyMaster;
