import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Form, Row } from "antd";
import useStore from "../../../store";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react";
import moment from "moment";
import { debounce } from "lodash";
import { faCalendar, faTimes } from "@fortawesome/free-solid-svg-icons";


const MyProfile: React.FC<any> = observer(({ visible, close }) => {

    const [form] = Form.useForm();
    const { AUTH, COMMON } = useStore();
    const { user } = AUTH;
    const { roleList, shiftList, departmentList, reportingManagerList, fetchReportingManager } = COMMON;
    const [hideReportingManager, setHideReportingManager] = useState<boolean>(false);
    const drawerClose = () => {
        form.resetFields();
        close();
    };

    const configureManagers = async (e:any) => {
        setHideReportingManager(e==1)
        form.setFieldsValue({reporting_manager_id :null})
        await fetchReportingManager(e);
    } 
    useEffect(() => {
        form.setFieldsValue({
            firstname: user?.user?.firstname,
            lastname: user?.user?.lastname,
            employee_id: user?.user?.employee_id,
            email: user?.user?.email,
            phone_number: user?.user?.phone_number,
            gender: user?.user?.gender,
            address: user?.user?.address,
            date_of_joining: moment(user?.user?.date_of_joining),
            role_id: user?.user?.roleId,
            shift_id: user?.user?.shift_id,
            department_id: user?.user?.department_id,
            reporting_manager_id: user?.user?.reporting_manager_id,
            cut_off_points: user?.user?.cut_off_points,
            roleIds: user?.user?.roleId ?? []
        });
        handleChange();
    }, [visible]);
    const handleChange = debounce(() => {
        setHideReportingManager(user?.user?.roleId == 1);
        form
            .validateFields()
            .then(() => {
                //setDisabled(false);
            })
            .catch(() => {
                //setDisabled(true);
            });
    }, 500);
    return (
        <Drawer
            title="My Profile"
            className="myProfile"
            width={"50%"}
            open={visible}
            onClose={drawerClose}
            destroyOnClose
            closeIcon={<FontAwesomeIcon icon={faTimes} />}
            footer={[
                <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
                    Close
                </Button>
            ]}
        >

            <FormBox
                form={form}
                disabled={true}
                id={'myProfileForm'}
            >
                <Row gutter={15}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label="First Name"
                            placeholder="First Name"
                            name="firstname"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label="Last Name"
                            placeholder="Last Name"
                            name="lastname"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label="Email"
                            autoComplete="false"
                            placeholder="Email"
                            name="email"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label="Phone Number"
                            autoComplete="false"
                            placeholder="Phone Number"
                            name="phone_number"
                        />
                    </Col>

                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Select
                            options={{
                                list: roleList,
                                valueKey: "id",
                                textKey: "name"
                            }}
                            onChange={(e) => {
                                configureManagers(e)
                            }}
                            //mode={"multiple"}
                            name="role_id"
                            placeholder="Role"
                            label="Role"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Select
                            options={{
                                list: departmentList,
                                valueKey: "id",
                                textKey: "name"
                            }}
                            //mode={"multiple"}
                            name="department_id"
                            placeholder="Department"
                            label="Department"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Select
                            options={{
                                list: shiftList,
                                valueKey: "id",
                                textKey: "name"
                            }}
                            //mode={"multiple"}
                            name="shift_id"
                            placeholder="Shift"
                            label="Shift"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>

                        {!hideReportingManager && <InputBox.Select
                            options={{
                                list: reportingManagerList.filter((element: any) => { return element.id != user?.id }),
                                valueKey: "id",
                                textKey: "name"
                            }}
                            //mode={"multiple"}
                            name="reporting_manager_id"
                            placeholder="Reporting Manager"
                            label="Reporting Manager"
                        />}
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            label="Employee Id "
                            placeholder="Employee Id"
                            name="employee_id"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.Text
                            name="cut_off_points"
                            placeholder="Cut Off Points"
                            label="Cut Off Points"
                        />
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <InputBox.DatePicker
                            name="date_of_joining"
                            placeholder="Date Of Joining"
                            label="Date Of Joining"
                            disabled={true}
                            suffixIcon={<FontAwesomeIcon icon={faCalendar} />}
                        />
                    </Col>
                </Row>
            </FormBox>
        </Drawer>
    );
});

export default MyProfile;
