import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";
import { Regex, maxName } from "../../../config/Validators";

export const TestimonialMasterRequest = (): any => ({
    title: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    platform_master_id: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    shift_id: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    campaign_id: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    client_email: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
        { pattern: Regex.email, message: Message().regex.other(field) },
    ],
    link: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    recieved_at: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    manager_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    account_manager_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    tl_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    sme_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
});

export const TestimonialStatusRequest = (): RequestProps => ({
    status: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
});
