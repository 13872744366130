import React, { Dispatch, SetStateAction, useState } from "react";
import { Dropdown, Layout } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Avatar from "../../../../assets/images/user.png";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { ReactComponent as LeftArrow } from "../../../../assets/images/icon/left-arrow-icon.svg";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../../../app_pages/MyProfile/ChangePassword";
import MyProfile from "../../../app_pages/MyProfile/MyProfile";

interface HeaderViewProps {
	collapsed: boolean;
	SetCollapsed: Dispatch<SetStateAction<boolean>>;
}

const AppHeaderView: React.FC<HeaderViewProps> = observer(
	({ collapsed, SetCollapsed }) => {
		const { AUTH, COMMON } = useStore();
		const { user, doLogout } = AUTH;
		const [changePasswordModel, setChangePasswordModel] = useState<boolean>(false);
		const [myProfileModel, setMyProfileModel] = useState<boolean>(false);
		const { fetchRoles, fetchShift, fetchDepartment, fetchReportingManager } = COMMON;
		const navigate = useNavigate();
		// call logout function
		const logout = () => {
			doLogout().then(() => {
				navigate("/login");
			});
		};
		
		const openChangePasswordModel = () => {
			setChangePasswordModel(true);
		}

		const closeChangePasswordModel = () => {
			setChangePasswordModel(false);
		}
		
		const openMyProfileModel = () => {
			AUTH.fetchAuthUser().then((data:any) => {
				fetchRoles();
				fetchShift();
				fetchDepartment();
				fetchReportingManager(data?.data?.user?.roleId[0] ?? null);
				setMyProfileModel(true);
			})
		}

		const closeMyProfileModel = () => {
			setMyProfileModel(false);
		}

		const items = [
			{ label: <div onClick={() => openMyProfileModel()}>My Profile</div>, key: "My-Profile" },
			{ label: <div onClick={() => openChangePasswordModel()}>Change Password</div>, key: "Change-Password" },
			{ label: <div onClick={() => logout()}>Logout</div>, key: "Logout" },
		];

		return (
			<>
			<Layout.Header className="main__page__appheader">
				<div className="headerWrap">
					<div className="appheader__left">
						{/* {visible ? ( */}
						<div className="menuToggle">
							{collapsed ? (
								<div
									className="icon right"
									onClick={() => SetCollapsed(!collapsed)}
								>
									<LeftArrow />
								</div>
							) : (
								<div className="icon" onClick={() => SetCollapsed(!collapsed)}>
									<LeftArrow />
								</div>
							)}
						</div>
					</div>
					<div className="appheader__right">
						<div className="userDropdown">
							<Dropdown
								menu={{ items }}
								trigger={["click"]}
								overlayClassName="userMenuList"
							>
								<div className="userMenuWrap">
									<span className="avatar">
										<img src={Avatar} alt="Avatar" />
									</span>
									<span className="userName"><b>{user?.user?.firstname + " " + user?.user?.lastname }</b></span>
									<DownOutlined />
								</div>
							</Dropdown>
						</div>
					</div>
				</div>
			</Layout.Header>
			{changePasswordModel && <ChangePassword visible={changePasswordModel} close={closeChangePasswordModel}/>}
			{myProfileModel && <MyProfile visible={myProfileModel} close={closeMyProfileModel}/>}
			</>
		);
	}
);

export default AppHeaderView;
