import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { DiscussionTypeMasterRequest } from "../Validation";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    handleSubmit,
    handleChange,
    editValues,
    type,
    discussionTypeList,
  }) => {

    // set the form values to edit
    useEffect(() => {

      if (editValues && type === "edit") {
        form.setFieldsValue({
          name: editValues.name,
          type: editValues.type,
        });

        handleChange();
      }
    }, [editValues, form]);


    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Text
                required
                label="Name"
                placeholder="Name"
                name="name"
                rules={DiscussionTypeMasterRequest().name("Name")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <InputBox.Select
                options={{
                  list: discussionTypeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="type"
                placeholder="Select Type"
                label="Select Type"
                rules={DiscussionTypeMasterRequest().type('Type')}
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
