import React, { useEffect, useState } from "react";
import { Form, Button, Drawer } from "antd";
import useStore from "../../../../../../store";
import FormComponent from "./FormComponent";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import moment from "moment";

const AddComponent: React.FC<any> = observer(({ visible, close,  id  }) => {
  const { CAMPAIGN_TASK, COMMON, ROOT } = useStore();
  const { AddData} = CAMPAIGN_TASK;
  const { fetchCampaignTeamDropdownData } = COMMON;
  const { assignErrorToInput } = ROOT;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [seniorList, setSeniorList] = useState<any>([]);
  const [executorList, setExecutorList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchCampaignTeamDropdownData(id).then((data: any) => {
      setSeniorList(data?.seniorList);
      setExecutorList(data?.executorList);
      setLoading(false);
    }).catch(() => {
      setSeniorList([]);
      setExecutorList([]);
      setLoading(false);
    })
  }, [visible])
  const drawerClose = () => {
    form.resetFields();
    close();
  };

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
          if (name == 'estimated_delivery_date') {
            formData.append(name, moment(data[name]).format("YYYY-MM-DD"));
          } else {
            data[name] && formData.append(name, data[name]);
          }
        }

        AddData(formData)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      })
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
       // setDisabled(false);
      })
      .catch(() => {
       // setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title="Create Task"
      width={"70%"}
      open={visible}
      onClose={drawerClose}
      destroyOnClose={true}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="addTaskForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="addTaskForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        executorList={executorList}
        seniorList={seniorList}
        loading={loading}
        type="add"
      />
    </Drawer>
  );
});

export default AddComponent;
