import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Button, Row, Col, Modal } from "antd";
import useStore from "../../../../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { CampaignChecklistRequest } from "../Validation";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { CAMPAIGN_CHECKLIST, ROOT } = useStore();
  const { comment, editValues } = CAMPAIGN_CHECKLIST;
  const { assignErrorToInput } = ROOT;

  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);


  useEffect(() => {
    if (editValues) {
      form.setFieldsValue({
        comment: editValues?.comment,
      });
    }
  }, [visible])

  const drawerClose = () => {
    close();
    form.resetFields();
  };

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        formData.append('campaign_id', editValues?.campaign_id);
        formData.append('campaign_checklist_master_id', editValues?.campaign_checklist_master_id);
        for (const name in data) {
          data[name] && formData.append(name, data[name]);
        }

        comment(formData)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  return (
    <Modal
      title={editValues?.checklist?.title ? "Edit " + editValues?.checklist?.title + " Checklist Comment" : "Edit Checklist Comment"}
      width={"70%"}
      onCancel={drawerClose}
      destroyOnClose={true}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="editComment"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormBox
        form={form}
        id={"editComment"}
        onFinish={handleSubmit}
      >
        <Row gutter={15}>
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <InputBox.TextArea
              //mode={"multiple"}
              name="comment"
              autoComplete="false"
              placeholder="Comment"
              label="Comment"
              rules={CampaignChecklistRequest().comment("Comment")}
            />
          </Col>
        </Row>
      </FormBox>
    </Modal>
  );
});

export default EditComponent;
