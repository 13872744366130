import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { BoostTireMasterRequest } from "../Validation";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    handleSubmit,
    handleChange,
    editValues,
    type,
  }) => {

    // set the form values to edit
    useEffect(() => {
      if (editValues && type === "edit") {
        form.setFieldsValue({
          title: editValues.title,
          tire_number: editValues.tire_number,
          points: editValues.points,
        });

        handleChange();
      }
    }, [editValues, form]);

    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Title"
                placeholder="Title"
                name="title"
                rules={BoostTireMasterRequest().title("Title")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Tire Number"
                placeholder="Tire Number"
                name="tire_number"
                rules={BoostTireMasterRequest().tire_number("Tire Number")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                type="number"
                label="Points"
                placeholder="Points"
                name="points"
                rules={BoostTireMasterRequest().points("Points")}
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
