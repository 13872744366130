import React, { useEffect, useState } from "react";
import { Space, Table } from "antd";
import { observer } from "mobx-react";
import useStore from "../../../../../store";
import moment from "moment";

const MyPointsSummery: React.FC<any> = observer(() => {
    const { USER, AUTH } = useStore();
    const { user } = AUTH;
    const { getMyProfile } = USER;
    const [myDetails, setMyDetails] = useState<any>();
    useEffect(() => {
        if (user?.user?.id) {
            getMyProfile().then((data: any) => {
                setMyDetails([
                    {
                        key: '1',
                        label: "Credit Points In " + moment().format('MMMM'),
                        points: <b>{data?.currentMontRewardPoints?.credit_points}</b>,
                    },
                    {
                        key: '2',
                        label: "Escalation Points In " + moment().format('MMMM'),
                        points: <b>{data?.currentMontRewardPoints?.debit_points}</b>,
                    },
                    {
                        key: '3',
                        label: "Total Points Achieved In " + moment().format('MMMM'),
                        points: <b>{data?.currentMontRewardPoints?.total_points}</b>,
                    },
                    {
                        key: '4',
                        label: "Cut Off Points In " + moment().format('MMMM'),
                        points: <b>{data?.cut_off_points}</b>,
                    },
                    {
                        key: '5',
                        label: "Final Points In " + moment().format('MMMM'),
                        points: <b>{(data?.currentMontRewardPoints?.total_points > data?.cut_off_points) ? (data?.currentMontRewardPoints?.total_points - data?.cut_off_points) : 0}</b>,
                    },
                ]);

            });
        }
    }, []);


    const columns = [
        {
            title: <b>{'Points Summery Of ' + moment().format('MMMM') + " - " + moment().format('YYYY')}</b>,
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: <b>{'Points'}</b>,
            dataIndex: 'points',
            key: 'points',
        },
    ];

    return (
        <>
        <Space>
            {myDetails && <Table dataSource={myDetails} columns={columns} pagination={false}  className="mb-20"/>}
        </Space>
        </>
    );
}
);

export default MyPointsSummery;
