import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import LogoComponent from "../../Components/LogoComponent";
import Config from "../../../../config/Config";
import { Link, useLocation } from "react-router-dom";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { DashboardOutlined, UserOutlined, ApartmentOutlined, IdcardOutlined, ShoppingCartOutlined, DollarOutlined, ReconciliationOutlined, AuditOutlined, WarningOutlined, PieChartOutlined, OrderedListOutlined, CrownOutlined } from "@ant-design/icons";
import AllPermissionList from "../../../../utils/AllPermissionList";

interface AppSidebarViewProps {
	collapsed?: boolean;
}

const SidebarView: React.FC<AppSidebarViewProps> = observer(({ collapsed }) => {
	const location = useLocation();
	const [menu, setMenu] = useState<any>([]);
	const { AUTH } = useStore();
	const { user, getChildMenu, permissions } = AUTH;
	const menuItemsTemp = [
		{
			key: "/",
			url: "/",
			icon: <DashboardOutlined />,
			label: (
				<Link to="/" rel="noopener noreferrer">
					Dashboard
				</Link>
			),
		},
	];
	const menuList: any = [
		{
			icon: <UserOutlined />,
			label: (
				<Link to="/user-management" rel="noopener noreferrer">
					Users
				</Link>
			),
			key: "/user-management",
			url: "/user-management",
			permission: AllPermissionList.LIST_USER,
		}, // remember to pass the key prop which is required
		{
			icon: <ApartmentOutlined />,
			label: (
				<Link to="/role-management" rel="noopener noreferrer">
					Roles & Permissions
				</Link>
			),
			key: "/role-management",
			url: "/role-management",
			permission: AllPermissionList.LIST_ROLE,
		},
		{
			icon: <IdcardOutlined />,
			label: (
				<Link to="/client-management" rel="noopener noreferrer">
					Clients
				</Link>
			),
			key: "/client-management",
			url: "/client-management",
			permission: AllPermissionList.LIST_CLIENT,
		},
		{
			icon: <ShoppingCartOutlined />,
			label: (
				<Link to="/quote-management" rel="noopener noreferrer">
					Quotes
				</Link>
			),
			key: "/quote-management",
			url: "/quote-management",
			permission: AllPermissionList.LIST_QUOTE,
		},
		{
			icon: <DollarOutlined />,
			label: (
				<Link to="/campaign-management" rel="noopener noreferrer">
					Campaigns
				</Link>
			),
			key: "/campaign-management",
			url: "/campaign-management",
			permission: AllPermissionList.LIST_CAMPAIGN,
		},
		{
			icon: <ReconciliationOutlined />,
			label: (
				<Link to="/my-reward-points" rel="noopener noreferrer">
					Reward Points
				</Link>
			),
			key: "/my-reward-points",
			url: "/my-reward-points",
			permission: null,
		},
		{
			icon: <AuditOutlined />,
			label: (
				<Link to="/extra-point-management" rel="noopener noreferrer">
					Extra Points
				</Link>
			),
			key: "/extra-point-management",
			url: "/extra-point-management",
			permission: AllPermissionList.LIST_EXTRA_POINT,
		},
		{
			icon: <CrownOutlined />,
			label: (
				<Link to="/testimonial-management" rel="noopener noreferrer">
					Testimonials
				</Link>
			),
			key: "/testimonial-management",
			url: "/testimonial-management",
			permission: AllPermissionList.LIST_TESTIMONIAL,
		},
		{
			icon: <WarningOutlined />,
			label: (
				<Link to="/escalation-management" rel="noopener noreferrer">
					Escalations
				</Link>
			),
			key: "/escalation-management",
			url: "/escalation-management",
			permission: AllPermissionList.LIST_ESCALATION,
		},
		{
			icon: <PieChartOutlined />,
			label: " Business Analysis",
			key: "/business-analysis",
			children: [
				{
					label: (
						<Link
							to="/business-analysis/paused-campaigns"
							rel="noopener noreferrer"
						>
							Paused Campaigns
						</Link>
					),
					key: "/business-analysis/paused-campaigns",
					url: "/business-analysis/paused-campaigns",
					permission: AllPermissionList.LIST_BUSINESS_ANALYSIS,
				},
				{
					label: (
						<Link
							to="/business-analysis/lost-campaigns"
							rel="noopener noreferrer"
						>
							Lost Campaigns
						</Link>
					),
					key: "/business-analysis/lost-campaigns",
					url: "/business-analysis/lost-campaigns",
					permission: AllPermissionList.LIST_BUSINESS_ANALYSIS,
				},
				{
					label: (
						<Link
							to="/business-analysis/client-kpi-management"
							rel="noopener noreferrer"
						>
							Client KPIs
						</Link>
					),
					key: "/business-analysis/client-kpi-management",
					url: "/business-analysis/client-kpi-management",
					permission: AllPermissionList.LIST_BUSINESS_ANALYSIS,
				},
			],
		},
		{
			icon: <OrderedListOutlined />,
			label: "Masters",
			key: "masters",
			children: [
				{
					label: (
						<Link
							to="/service-type-campaign-management"
							rel="noopener noreferrer"
						>
							Service Types & Campaigns
						</Link>
					),
					key: "/service-type-campaign-management",
					url: "/service-type-campaign-management",
					permission: AllPermissionList.LIST_SERVICE_TYPE_AND_CAMPAIGN,
				},
				{
					label: (
						<Link to="/currency-management" rel="noopener noreferrer">
							Currencies
						</Link>
					),
					key: "/currency-management",
					url: "/currency-management",
					permission: AllPermissionList.LIST_CURRENCY,
				},
				{
					label: (
						<Link to="/discussion-type-management" rel="noopener noreferrer">
							Discussion Types
						</Link>
					),
					key: "/discussion-type-management",
					url: "/discussion-type-management",
					permission: AllPermissionList.LIST_DISCUSSION_TYPE,
				},
				{
					label: (
						<Link to="/template-management" rel="noopener noreferrer">
							Email Templates
						</Link>
					),
					key: "/template-management",
					url: "/template-management",
					permission: "View Template Listing",
				},
				{
					label: (
						<Link to="/checklist-management" rel="noopener noreferrer">
							Campaign Checklists
						</Link>
					),
					key: "/checklist-management",
					url: "/checklist-management",
					permission: AllPermissionList.LIST_CHECKLIST,
				},
				{
					label: (
						<Link to="/shift-geo-management" rel="noopener noreferrer">
							Shift/GEO
						</Link>
					),
					key: "/shift-geo-management",
					url: "/shift-geo-management",
					permission: AllPermissionList.LIST_CHECKLIST,
				},
				{
					label: (
						<Link to="/escalation-type-management" rel="noopener noreferrer">
							Escalation Types
						</Link>
					),
					key: "/escalation-type-management",
					url: "/escalation-type-management",
					permission: AllPermissionList.LIST_ESCALATION_TYPE,
				},
				{
					label: (
						<Link to="/point-system-management" rel="noopener noreferrer">
							Default Points System
						</Link>
					),
					key: "/point-system-management",
					url: "/point-system-management",
					permission: AllPermissionList.VIEW_POINT_SYSTEM,
				},
				//{ permission: "View Work Model Listing", key: '/work-model-management', url: '/work-model-management', label: AllPermissionList.LIST_WORK_MODEL },
			],
		},
	];
	useEffect(() => {
		const newElement: any = [];
		if (permissions) {
			menuList.forEach((element: any) => {
				if (element?.children) {
					element.children = getChildMenu(element.children);
					if (element.children.length > 0) newElement.push(element);
				} else {
					if (element.permission && permissions.indexOf(element.permission) >= 0)
						newElement.push(element);
					else if (element.permission == null)
						newElement.push(element);
				}
			});
		}
		user && setMenu([...menuItemsTemp, ...newElement]);
	}, [user, permissions]);

	return (
		<Layout.Sider
			collapsed={collapsed}
			width={Config.sidebar_width}
			className="main__page__appsidebar"
		>
			<LogoComponent collapsed={collapsed} />
			<Menu
				//mode="inline"
				theme="dark"
				defaultSelectedKeys={["/"]}
				activeKey={location.pathname}
				defaultOpenKeys={["/"]}
				items={menu}
				mode="vertical"
			></Menu>
		</Layout.Sider>
	);
});

export default SidebarView;
