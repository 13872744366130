import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import RootStore from "../RootStore/RootStore";

export default class CampaignChecklistStore {
  agGrid: any = null;
  perPage = Config.grid.local.gridOptions?.paginationPageSize;
  editValues = null;
  seletedCampaignId:any = null;
  rootstore:any;
  constructor() {
    makeAutoObservable(this);
    this.rootstore = new RootStore();

  }
  // Setter Functions
	private setGrid = (value: any) => {
		this.agGrid = value;
	};

  setSelectedCampaign = (id: any) => {
    this.seletedCampaignId = id;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params: any) => {
    this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
    this.agGrid = params;
  };

  private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootstore.getServerListPayload(params);
        const queryParams = { ...payload, campaign_id: this.seletedCampaignId };
				this.fetchList(queryParams).then((data) => {
					params.success({rowData:data?.rows,rowCount: data?.count});
				});
			},
		};
	};

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.perPage) => {
    this.perPage = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(Number(page));
    }
  };

  setEditValues = (data: any) => {
    this.editValues = data;
  };

  // API Functions
  public fetchList = async (params?: any): Promise<any> => {
    return await axios
      .post(API_URL.CAMPAIGN_CHECKLIST.LIST,params)
      .then(({ data }) => {
        return data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  AddData = (formData: any) => {
    return axios
      .post(API_URL.CAMPAIGN_CHECKLIST.CREATE, formData)
      .then(() => {
        this.setupGrid(this.agGrid);
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  comment = (formData: any) => {
    return axios
      .post(API_URL.CAMPAIGN_CHECKLIST.ADD_COMMENT, formData)
      .then(() => {
        this.setupGrid(this.agGrid);
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };


	public ChangeStatus = (payload: any): Promise<any> => {
		return axios.post(API_URL.CAMPAIGN_CHECKLIST.CHANGE_STATUS, payload).then(({ data }) => {
			this.setupGrid(this.agGrid);
			return data.data;
		});
	};

  public details = (payload:any): Promise<any> => {
		return axios.post(API_URL.CAMPAIGN_CHECKLIST.DETAILS, payload).then(({ data }) => {
			this.setEditValues(data.data);
      return data.data;
		}).catch((response) => {
      this.setEditValues(null);
      return Promise.reject(response);
    });
	};
}
