import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";
import { Regex } from "../../../config/Validators";

export const ExtraPointMasterRequest = (): RequestProps => ({
    title: (field?: string) =>[{ required: true, message: Message().required.text(field) }],
    campaign_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    point_type_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    user_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    type: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    points: (field?: string) =>[
        { required: true, message: Message().required.select(field) },
        { min: 1, message: Message().min.select(1, field) },
        { pattern: Regex.twoDecimal, message: Message().regex.decimal(2) },
    ],
});
export const ExtraPointMasterStatusRequest = (): RequestProps => ({
    status: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
});