import React, { useEffect, useState } from "react";
import { Layout, Spin } from "antd";
import useStore from "../../../store";
import { Outlet, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import SidebarView from "./Sidebar/SidebarView";
import AppHeaderView from "./AppHeader/AppHeaderView";
import AppFooterView from "./AppFooter/AppFooterView";

const MainLayout: React.FC = observer(() => {
	const [collapsed, SetCollapsed] = useState<boolean>(false);
	const navigate = useNavigate();
	const { AUTH } = useStore();
	useEffect(() => {
		if (!AUTH.token) {
			navigate("/login");
		}
	}, [AUTH.token]);

	return (
		<Spin size={"large"} className="spinner__wrapper_inside loader" spinning={AUTH.spinLoading}>		
			<Layout className={`main__page__wrapper has__appsidebar`}>
				<SidebarView collapsed={collapsed}></SidebarView>
				<Layout className="site-layout">
					<AppHeaderView collapsed={collapsed} SetCollapsed={SetCollapsed} />
						<Layout.Content className="main__app__content">
							<Outlet />
						</Layout.Content>
					<AppFooterView />
				</Layout>
			</Layout>
		</Spin>
	);
});

export default MainLayout;
