import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { CampaignChecklistMasterRequest } from "../Validation";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    handleSubmit,
    handleChange,
    editValues,
    type,
    serviceTypeList,
    fetchCampaign,
    campaignList,
  }) => {

    // set the form values to edit
    useEffect(() => {

      if (editValues && type === "edit") {
        form.setFieldsValue({
          title: editValues.title,
          service_type_id: editValues.service_type_id,
          campaign_type_id: editValues.campaign_type_id,
          type: editValues.type,
        });
        configureCampaignType(editValues?.service_type_id, editValues?.campaign_type_id ?? null);

        handleChange();
      }
    }, [editValues, form]);

    const configureCampaignType = (serviceTypeId: any, campaignTypeId: any = null) => {
      fetchCampaign(serviceTypeId ?? null, campaignTypeId ?? null);
      !campaignTypeId && form.resetFields(['campaign_type_id']);
    }
    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: serviceTypeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                onChange={(e) => { configureCampaignType(e) }}
                
                name="service_type_id"
                placeholder="Service Type"
                label="Service Type"
                rules={CampaignChecklistMasterRequest().service_type_id('Service Type')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: campaignList,
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="campaign_type_id"
                placeholder="Campaign Type"
                label="Campaign Type"
                rules={CampaignChecklistMasterRequest().campaign_type_id('Campaign Type')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Title"
                placeholder="Title"
                name="title"
                rules={CampaignChecklistMasterRequest().title("Title")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: [{"id":1,"name":"Weekly"},{"id":2,"name":"Monthly"}],
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="type"
                placeholder="Type"
                label="Type"
                rules={CampaignChecklistMasterRequest().campaign_type_id('Type')}
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
