import React, { useEffect, useState } from "react";
import { Drawer, Form, Button } from "antd";
import useStore from "../../../../../store";
import FormComponent from "./FormComponent";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import Config from "../../../../../config/Config";

const AddComponent: React.FC<any> = observer(({ visible, close }) => {
  const { QUOTE_DISCUSSION, ROOT, COMMON } = useStore();
  const { AddData } = QUOTE_DISCUSSION;
  const { assignErrorToInput } = ROOT;
  const { fetchDiscussionType, discussionTypeList, userList, fetchUser } = COMMON;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
	const { quote_id } = useParams()
  //const [fileLogoList, setFileLogoList] = useState<any>([]);

  useEffect(() => {
    fetchDiscussionType(1);
		fetchUser();
  }, [])
  const drawerClose = () => {
    form.resetFields();
    close();
  };

  const handleSubmit = (data: any) => {
		data.quote_id = quote_id // Todo: change to quote after quote module finished
		form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
					if(name === "discussion_date_time"){
						data[name] && formData.append(name, data[name].format(Config.dateTimeFormat));
					}else{
            data[name] && formData.append(name, data[name]); // there should be values.avatar which is a File object
					}
        }
			  AddData(formData)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      })
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
       // setDisabled(false);
      })
      .catch(() => {
       // setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title="Create Discussion"
      placement="right"
      width={"70%"}
      open={visible}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="addQuoteDiscussionForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="addQuoteDiscussionForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        type="add"
        discussionTypeList={discussionTypeList}
        userList={userList}
      />
    </Drawer>
  );
});

export default AddComponent;
