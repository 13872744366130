import React, { useState } from "react";
import { Button, Checkbox, Divider, Form, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import LoginBG from "../../../assets/images/login-pattern.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { LoginRequest } from "./Validation";

const LoginForm: React.FC = () => {
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const navigate = useNavigate();
  const { AUTH, ROOT } = useStore();
  const { doLogin } = AUTH;
  const { assignErrorToInput } = ROOT;
  const handleSubmit = (data: any) => {
    setSaving(true);
    doLogin(data)
      .then(() => {
        if (data?.remember > 0) {
          sessionStorage.setItem('username', data?.username);
          sessionStorage.setItem('password', data?.password);
          sessionStorage.setItem('remember', data?.remember);
          navigate("/");
        } else {
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((e: ErrorProps) => {
        assignErrorToInput(form, e?.data?.NOTIFICATION);
        setSaving(false);
      })
      .finally(() => setSaving(false));
  };

  return (
    <div className="loginForm" style={{ backgroundImage: `url(${LoginBG})` }}>
      {/* <div className="loginBG"> */}
      {/* <img src={LoginBG} className="loginBG" /> */}
      {/* </div> */}
      <div className="loginFormWrap">
        <div className="formTitle">
          <h2>Welcome to {process.env.REACT_APP_NAME}</h2>
        </div>
        <FormBox form={form} onFinish={handleSubmit} initialValues={{ remember: true, username: sessionStorage.getItem('username') ?? undefined, password: sessionStorage.getItem('password') ?? undefined }}>
          <InputBox.Text
            name="username"
            label="Email ID"
            placeholder="example@domain.com"
            rules={LoginRequest().email("Email ID")}
            suffix={<FontAwesomeIcon icon={faAt} />}
          />
          <InputBox.Password
            name="password"
            label="Password"
            placeholder="xxxxxxx"
            rules={LoginRequest().password("Password")}
          />
          {/* <InputBox.Checkbox
            name="remember"
            options={{
              list: [{ id: 1, name: "Remember Me" }],
              valueKey: "id",
              textKey: "name"
            }}
          /> */}
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <div className="text-center">
            <Button loading={saving} type="primary" htmlType="submit">
              Login
            </Button>
          </div>
          <Divider />
          <div className="text-center authback">
            <Space>
              {`Forgot Password?`}
              <Link to="/forgot-password">
                <b>Click here to reset your password</b>
              </Link>
            </Space>
          </div>
        </FormBox>
      </div>
    </div>
  );
};

export default LoginForm;
