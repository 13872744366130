import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../components/ContentBox/ContentBox";
import useStore from "../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import PermissionComponent from "./components/PermissionComponent";
import AssignRoleComponent from "./components/AssignRoleComponent";
import { RoleMasterBreadcrumb } from "../../../config/BreadcrumbConfig";
import AllPermissionList from "../../../utils/AllPermissionList";
import { PageHeader } from "antd";
import EditRoleComponent from "./components/EditComponent";
import AddRoleComponent from "./components/AddComponent";

const RoleManagement: React.FC = observer(() => {
	const { ROLE, AUTH, COMMON } = useStore();
	const {
		listData,
		fetchList,
		changeStatus,
		details,
		getPermissionList,
		permissionList,
		openPermissionModel,
		closePermissionModel,
		showPermissionModel,
		openAssignRoleModel,
		closeAssignRoleModel,
		showAssignRoleModel,
		openEditRolePopup,
		closeEditRolePopup,
		editRolePopup,
		openAddRolePopup,
		closeAddRolePopup,
		addRolePopup,
	} = ROLE;

	const { user, permissions } = AUTH;
	const { roleList, userList, fetchUser, fetchRoles } = COMMON
	const [selectedPermission, setPermission] = useState<any>([]);
	const [permissionModelTitle, setPermissionModelTitle] = useState<any>("Permission");
	const editRole = (id: number) => {
		openEditRolePopup(id)
	}

	useEffect(() => {
		getPermissionList()
	}, []);

	const setPermissionModel = (id: number) => {
		setPermission([]);
		details(id).then((data: any) => {
			const options: any = [];
			data.data.permissions.forEach((permissionObj: any) => {
				options.push(permissionObj.id);
			});
			setPermissionModelTitle(data.data.name)
			setPermission(options);
			openPermissionModel();
		}).catch((e:any) => {
			e;
		});
	}

	const setAssignRoleModel = () => {
		setPermission([]);
		fetchUser().then(() => {
			fetchRoles().then(() => {
				openAssignRoleModel();
			});
		});
	}

	return (
			<>
				<BreadcrumbComponent items={RoleMasterBreadcrumb.path} />
				<div className="mainPageWrap">
					<PageHeader
						className="mainPageHeader"
						title={RoleMasterBreadcrumb.title}
						extra={[
							<HeaderButtons
								addRoleRedirect={openAddRolePopup}
								key="addrole"
								addPermission={permissions && permissions.indexOf(AllPermissionList.ADD_ROLE) >= 0}
								assignRoleToUserPermission={false}//permissions && permissions.indexOf(AllPermissionList.ASSIGN_ROLE) >= 0}
								OpenAssignRoleModel={setAssignRoleModel} />,
						]}
					/>
					<ContentBox>
						<ListComponent
							rowData={listData}
							getData={fetchList}
							editRole={editRole}
							ChangeStatus={changeStatus}
							key="listrolecomponent"
							user={user?.user ?? null}
							ShowPermissionModel={setPermissionModel}
							editPermission={permissions && permissions.indexOf(AllPermissionList.EDIT_ROLE) >= 0}
						/>
						{addRolePopup && <AddRoleComponent
							visible={addRolePopup}
							closeAddRolePopup={closeAddRolePopup}
							key="addrolekeycomponent"
						/>}
						{editRolePopup && <EditRoleComponent
							visible={editRolePopup}
							closeEditModal={closeEditRolePopup}
							key="editrolekeycomponent"
						/>}
						{showPermissionModel && <PermissionComponent
							permissionList={permissionList}
							selectedPermission={selectedPermission}
							onCheckChange={(e) => { e.preventDefault() }}
							visible={showPermissionModel}
							title={permissionModelTitle ?? "Permission"}
							closePermissionModel={closePermissionModel}
							disabled={true}
							key="permissionistdetailscomponent"
						/>}
						{showAssignRoleModel && <AssignRoleComponent
							userList={userList}
							roleList={roleList}
							visible={showAssignRoleModel}
							title={"Select user and assign role"}
							key="assignRoleComponentData"
							closeAssignRoleModel={closeAssignRoleModel}
						/>}
					</ContentBox>
				</div>
			</>
	);
});

export default RoleManagement;
