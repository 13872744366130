import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../config/Config";
import moment from "moment";
import DateComparator from "../../../../../utils/DateComparator";
import { observer } from "mobx-react";

const ListComponent: React.FC<any> = observer(
	({
		setupGrid,
	}) => {
		useEffect(() => {
			//    getData();
		}, []);		
		return (
			<>
				<AgGridWrapper
					onGridReady={setupGrid}
					type="serverSide"
					serverSideInfiniteScroll={true}
					suppressFieldDotNotation={true}
				>
					<AgGridColumn field="order_number" headerName="#CAMPAIGN ID" />
					<AgGridColumn field="campaign_name" headerName="Campaign Name" />
					<AgGridColumn field="point_type" headerName="Points For" />
					<AgGridColumn field="type" headerName="Credit/Debit" />
					<AgGridColumn field="points" headerName="Points" filter={"agNumberColumnFilter"}/>
					<AgGridColumn
						field="created_at"
						headerName="Created At"
						valueGetter={({ data }) =>
							data["created_at"] &&
							moment(data["created_at"]).format(Config.dateTimeFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn field="created_by_name" headerName="Added By" />
				</AgGridWrapper>
			</>
		);
	}
);

export default ListComponent;
