
const AllPermissionList =  {
    'LIST_USER'                      : "View User Listing",
    'ADD_USER'                       : "Add User",
    'EDIT_USER'                      : "Edit User Details",
    'LIST_USER_REWARD_POINTS'        : "View User Reward Points",
    'LIST_ROLE'                      : "View Role Listing",
    'ADD_ROLE'                       : "Add Role",
    'EDIT_ROLE'                      : "Edit Role Details",
    'LIST_TEMPLATE'                  : "View Template Listing",
    'EDIT_TEMPLATE'                  : "Edit Template Details",
    'LIST_CLIENT'                    : "View Client Listing",
    'ADD_CLIENT'                     : "Add Client",
    'EDIT_CLIENT'                    : "Edit Client Details",
    'LIST_SERVICE_TYPE_AND_CAMPAIGN' : "View Service Type And Campaign Listing",
    'ADD_SERVICE_TYPE_AND_CAMPAIGN'  : "Add Service Type And Campaign",
    'EDIT_SERVICE_TYPE_AND_CAMPAIGN' : "Edit Service Type And Campaign Details",
    'LIST_CURRENCY'                  : "View Currency Listing",
    'ADD_CURRENCY'                   : "Add Currency",
    'EDIT_CURRENCY'                  : "Edit Currency Details",
    'LIST_CHECKLIST'                 : "View Checklist Listing",
    'ADD_CHECKLIST'                  : "Add Checklist",
    'EDIT_CHECKLIST'                 : "Edit Checklist Details",
    'LIST_SHIFT_GEO'                 : "View Shift/GEO Listing",
    'ADD_SHIFT_GEO'                  : "Add Shift/GEO",
    'EDIT_SHIFT_GEO'                 : "Edit Shift/GEO Details",
    'LIST_WORK_MODEL'                : "View Work Model Listing",
    'ADD_WORK_MODEL'                 : "Add Work Model",
    'EDIT_WORK_MODEL'                : "Edit Work Model Details",
    'LIST_DISCUSSION_TYPE'           : "View Discussion Type Listing",
    'ADD_DISCUSSION_TYPE'            : "Add Discussion Type",
    'EDIT_DISCUSSION_TYPE'           : "Edit Discussion Type Details",
    'LIST_QUOTE'                     : "View Quote Listing",
    'ADD_QUOTE'                      : "Add Quote",
    'EDIT_QUOTE'                     : "Edit Quote Details",
    'LIST_DISCUSSION'                : "View Discussion Listing",
    'ADD_DISCUSSION'                 : "Add Discussion",
    'EDIT_DISCUSSION'                : "Edit Discussion Details",
    'LIST_CAMPAIGN'                  : "View Campaign Listing",
    'EDIT_CAMPAIGN'                  : "Edit Campaign Details",
    'CHANGE_CAMPAIGN_STATUS'         : "Change Campaign Status",
    'LIST_CAMPAIGN_DISCUSSION'       : "View Campaign Discussion Listing",
    'ADD_CAMPAIGN_DISCUSSION'        : "Add Campaign Discussion",
    'EDIT_CAMPAIGN_DISCUSSION'       : "Edit Campaign Discussion Details",
    'LIST_TASK'                      : "View Task Listing",
    'ADD_TASK'                       : "Add Task",
    'EDIT_TASK'                      : "Edit Task Details",
    'CHANGE_TASK_STATUS'             : "Change Task Status",
    'VIEW_TEAM_DETAILS'              : "View Team & Details",
    'EDIT_TEAM_DETAILS'              : "Edit Team & Details",
		'LIST_CAMPAIGN_KPI'              : "View Campaign KPI Listing",
    'ADD_CAMPAIGN_KPI'               : "Add Campaign KPI",
    'EDIT_CAMPAIGN_KPI'              : "Edit Campaign KPI Details",
		'LIST_CAMPAIGN_FEEDBACK'         : "View Campaign Feedback Listing",
    'ADD_CAMPAIGN_FEEDBACK'          : "Add Campaign Feedback",
    'EDIT_CAMPAIGN_FEEDBACK'         : "Edit Campaign Feedback Details",
    'LIST_CAMPAIGN_CHECKLIST'        : "View Checklists",
    'ADD_EDIT_CHECKLIST_COMMENT'     : "Manage Checklist Comment",
    'CHANGE_CHECKLIST_STATUS'        : "Edit Checklist Status",
    'VIEW_POINT_SYSTEM'              : "View Point System",
    'LIST_POINT_TYPE'                : "View Point Type Listing",
    'EDIT_POINT_TYPE'                : "Edit Point Type Details",
    'LIST_BOOSTER_TIRE_MASTER'       : "View Booster Point Listing",
    'ADD_BOOSTER_TIRE_MASTER'        : "Add Booster Point",
    'EDIT_BOOSTER_TIRE_MASTER'       : "Edit Booster Point Details",
    'LIST_PACKAGE'                   : "View Package Listing",
    'ADD_PACKAGE'                    : "Add Package",
    'EDIT_PACKAGE'                   : "Edit Package Details",
    'LIST_ESCALATION_TYPE'           : "View Escalation Type Listing",
    'ADD_ESCALATION_TYPE'            : "Add Escalation Type",
    'EDIT_ESCALATION_TYPE'           : "Edit Escalation Type Details",
    'LIST_TESTIMONIAL'               : "View Testimonial Listing",
    'ADD_TESTIMONIAL'                : "Add Testimonial",
    'EDIT_TESTIMONIAL'               : "Edit Testimonial Details",
    'CHANGE_TESTIMONIAL_STATUS'      : "Change Testimonial Status",
    'LIST_EXTRA_POINT'               : "View Extra Point Listing",
    'ADD_EXTRA_POINT'                : "Add Extra Point",
    'EDIT_EXTRA_POINT'               : "Edit Extra Point Details",
    'CHANGE_EXTRA_POINT_STATUS'      : "Change Extra Point Status",
    'LIST_ESCALATION'                : "View Escalation Listing",
    'ADD_ESCALATION'                 : "Add Escalation",
    'EDIT_ESCALATION'                : "Edit Escalation Details",
    'LIST_BUSINESS_ANALYSIS'         : "View Business Analysis",
}


export default AllPermissionList;
