import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";

export const RoleValidation = ():RequestProps => ({
	name: (field?:string) => [{ required: true, message: Message().required.text(field) }],
	permission: (field?:string) => [{ required: true, message: Message().required.select(field) }],
});

export const AssignRoleValidation = ():RequestProps => ({
	user_id: (field?:string) => [{ required: true, message: Message().required.select(field) }],
	roleIds: (field?:string) => [{ required: true, message: Message().required.select(field) }],
});