import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Col, Drawer, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface ListProps {
    rowData: any[];
    getRewardPoints: (id: number) => Promise<any>;
    user: any;
    visible: boolean;
    close: () => void;
}

const UserRewardPointsComponent: React.FC<ListProps> = ({
    rowData,
    getRewardPoints,
    user,
    visible,
    close,
}) => {
    useEffect(() => {
        visible && getRewardPoints(user?.id);
    }, [visible]);

    const drawerClose = () => {
        close();
    };


    return <Drawer
        title={user?.firstname ? "(" + user?.firstname + " " + user?.lastname + " - " + user?.employee_id + ") Points History" : "Points History"}
        placement="right"
        width={"70%"}
        open={visible}
        onClose={drawerClose}
        destroyOnClose
        closeIcon={<FontAwesomeIcon icon={faTimes} />}
        footer={[
            <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
                Close
            </Button>,
        ]}
    >
        <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-10">
                <div className="ant-row ant-form-item-row">
                    <div className="ant-col ant-form-item-label">
                        <b><label title="Cut Off Points">Cut Off Points : </label></b> {user?.cut_off_points}
                    </div>
                </div>
            </Col>
        </Row>
        <AgGridWrapper
            rowData={rowData}
            suppressFieldDotNotation={true}
        >
            <AgGridColumn field="month" headerName="Month & Year" />
            <AgGridColumn field="credit_points" headerName="Total Reward Points" />
            <AgGridColumn field="debit_points" headerName="Total Escalation Points" />
            <AgGridColumn field="total_points" headerName="Total Achieved Points" />
        </AgGridWrapper>
    </Drawer>
};

export default UserRewardPointsComponent;
