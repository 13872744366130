import React, { useEffect, useState } from "react";
import { Form, Button, Drawer } from "antd";
import useStore from "../../../../../../store";
import FormComponent from "./FormComponent";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import moment from "moment";

const AddComponent: React.FC<any> = observer(({ visible, close }) => {
  const { CAMPAIGN_KPI, COMMON, ROOT } = useStore();
  const { AddData } = CAMPAIGN_KPI;
  const { fetchKpiListDropdownData } = COMMON;
  const { assignErrorToInput } = ROOT;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [kpiStatusList, setKpiStatusList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchKpiListDropdownData().then((data: any) => {
      setKpiStatusList(data);
      setLoading(false);
    }).catch(() => {
      setKpiStatusList([]);
      setLoading(false);
    })
  }, [visible])
  const drawerClose = () => {
    form.resetFields();
    close();
  };

  const handleSubmit = (data: any) => {
    const dateField: string[] = ['start_date', 'end_date'];
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
          if (dateField.includes(name)) {
            formData.append(name, moment(data[name]).format("YYYY-MM-DD"));
          } else {
            data[name] && formData.append(name, data[name]);
          }
        }

        AddData(formData)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      })
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        // setDisabled(false);
      })
      .catch(() => {
        // setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title="Create KPI"
      width={"70%"}
      open={visible}
      onClose={drawerClose}
      destroyOnClose={true}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="addKPIform"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="addKPIform"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        kpiStatusList={kpiStatusList}
        loading={loading}
        type="add"
      />
    </Drawer>
  );
});

export default AddComponent;
