import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import {
  faCalendarCheck,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../config/Config";
import useStore from "../../../../store";
import AllPermissionList from "../../../../utils/AllPermissionList";
import moment from "moment";
import DateComparator from "../../../../utils/DateComparator";
import { observer } from "mobx-react";

const ListComponent: React.FC<any> = observer(({
  setupGrid,
  // getData,
  openStatusModal,
  openEditModal,
}) => {
  const { AUTH } = useStore();
  const ActionRenderer = (props: any) => {
    return (
      <div className="action-column">
          <Button
            type="text"
            title={"Edit"}
            className="editIcon"
            hidden={!AUTH.checkPermission(AllPermissionList.EDIT_EXTRA_POINT)}
            onClick={() => {
              openEditModal(props.data);
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} color={Config.blueBtn} />
          </Button>
          <Button
            type="text"
            title={"Change Status"}
            className="deleteIcon"
            hidden={!AUTH.checkPermission(AllPermissionList.CHANGE_EXTRA_POINT_STATUS) || props.data.status>0}
            onClick={() => {
              openStatusModal({...props.data});
            }}
          >
            <FontAwesomeIcon icon={faCalendarCheck} color={Config.orangeBtn} />
          </Button>
      </div>
    );
  };

  useEffect(() => {
//    getData();
  }, []);

  return (
    <>
      <AgGridWrapper
        components={{ ActionRenderer }}
        onGridReady={setupGrid}
        type="serverSide"
        serverSideInfiniteScroll={true}
        suppressFieldDotNotation={true}

      >
        <AgGridColumn field='order_number' headerName="#CAMPAIGN ID" />
        <AgGridColumn field='title' headerName="Title" />
        <AgGridColumn field='point_type_master_name' headerName="Point Type" />
        <AgGridColumn field='extra_point_status' headerName="Status" />
        <AgGridColumn
          field="created_at"
          headerName="Created At"
          valueGetter={({ data }) =>
            data['created_at'] && moment(data['created_at']).format(Config.dateTimeFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />
        <AgGridColumn
          field="updated_at"
          headerName="Updated At"
          valueGetter={({ data }) =>
            data['updated_at'] &&
            moment(data['updated_at']).format(Config.dateTimeFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />
        <AgGridColumn
          field="created_by_name"
          headerName="Created By"
        />
        <AgGridColumn
          field="updated_by_name"
          headerName="Last Modified By"

        />
        <AgGridColumn
          headerName="Actions"
          type="actionColumn"
          sortable={false}
          filter={false}
          width={120}
          minWidth={120}
          pinned="right"
        />
      </AgGridWrapper>
    </>
  );
});

export default ListComponent;
