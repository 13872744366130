import Message from "../../../config/Message";
import { maxName,maxUrl,Regex } from "../../../config/Validators";

export const QuoteMasterRequest = (): any => ({
    client_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    service_type_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    campaign_type_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    currency_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    nbd_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    ba_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    service_document_link: (field?: string) => [{ max: maxUrl, message: Message().max.string(maxUrl, field) },],
    account_manager_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    tl_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    sme_id: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    email: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    billing_email: (field?: string, isRequired?:boolean) => [
        { required: isRequired, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    campaign_name: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    start_end_date: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    tenure_start_end_date: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.select(field) }],
    amount: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.text(field) }],
    questionnaire_status: (field?: string) => [{ required: true, message: Message().required.text(field) }],
    proposal: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: 5000, message: Message().max.string(5000, field) }
    ],
    proposal_status: (field?: string, isRequired?:boolean) => [{ required: isRequired, message: Message().required.text(field) }],
    discount: (field?: string) => [
        { pattern: Regex.percentage, message: Message().regex.other(field) },
    ],
});
