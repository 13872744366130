// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React from "react";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import { LostCampaignBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import useStore from "../../../../../store";
import { PageHeader } from "antd";
import RecordPerPage from "../../../../../components/AgGridWrapper/RecordPerPage";
import ListComponent from "./components/ListComponent";

const LostCampaignMaster: React.FC = observer(() => {
  const { LOST_CAMPAIGN } = useStore();
  const {
    setPageSize,
    perPage,
    setupGrid
  } = LOST_CAMPAIGN;


  return (
    <>
      <BreadcrumbComponent items={LostCampaignBreadcrumb.path} />
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          title={LostCampaignBreadcrumb.title}
          extra={[
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
				<ListComponent
            setupGrid={setupGrid}
          />
        </ContentBox>
      </div>
    </>
  );
});

export default LostCampaignMaster;
