import React, { useState } from "react";
import { Button, Divider, Form, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { MailOutlined } from "@ant-design/icons";
import { ForgotPasswordRequest } from "./Validation";
import Logo from "../../../assets/images/mavlers-logo.svg";
import LoginBanner from "../../../assets/images/hero-banner-right.png";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoginBG from "../../../assets/images/login-pattern.png";

const ForgetPassword: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useNavigate();
	const { AUTH, ROOT } = useStore();
	const { forgetPassword } = AUTH;
	const { assignErrorToInput } = ROOT;

	const handleSubmit = (data: any) => {
		setSaving(true);

		forgetPassword(data)
			.then(() => {
				history("/");
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e.data?.errors;
				assignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	return (
		<section className="loginSection">
			<div className="loginWrap">
				<div className="loginLogo">
					<div className="logo">
						<img src={Logo} alt="Logo" />
					</div>
					<div className="loginBanner">
						<img src={LoginBanner} alt="Banner" />
					</div>
				</div>
				<div
					className="loginForm"
					style={{ backgroundImage: `url(${LoginBG})` }}
				>
					<div className="loginFormWrap">
						<FormBox form={form} onFinish={handleSubmit}>
							<InputBox.Text
								prefix={<MailOutlined />}
								name="email"
								label="Email ID"
								placeholder="example@domain.com"
								size="large"
								rules={ForgotPasswordRequest().email("Email Id")}
								suffix={<FontAwesomeIcon icon={faAt} />}
							/>
							<div className="text-center">
								<Space>
									<p className="primary">
										Please enter your email address.
										<br />
										We will send you Update Password link.
									</p>
								</Space>
							</div>
							<Button
								className="text-center"
								loading={saving}
								type="primary"
								size="large"
								htmlType="submit"
							>
								Reset Password
							</Button>
							<Divider />

							<div className="text-center authback">
								<Space>
									<Link to="/">
										<b>Back to login</b>
									</Link>
								</Space>
							</div>
						</FormBox>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ForgetPassword;
