import Message from "../../../config/Message";

export const EscalationMasterRequest = (): any => ({
    escalation_type_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    campaign_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    // senior_id: (field?: string) => [{ required: false, message: Message().required.select(field) }],
    // executor_id: (field?: string) => [{ required: false, message: Message().required.select(field) }],
    // learnings: (field?: string) => [{ required: false, message: Message().required.text(field) }],
    // brief: (field?: string) => [{ required: false, message: Message().required.text(field) }],
});
