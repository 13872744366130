import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Button, Drawer } from "antd";
import useStore from "./../../../../store";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Notification } from "../../../../config/Global";
import FormComponent from "./FormComponent";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { EXTRA_POINT, ROOT, COMMON } = useStore();
  const { allCampaignList, pointTypeList, clearCampaignTeam, fetchCampaignTeam,fetchCampaignDropdownData, fetchPointTypeDropdownData } = COMMON;

  const { EditData, editValues } = EXTRA_POINT;
  const { assignErrorToInput } = ROOT;
  const [campaignTeam, setCampaignTeam] = useState([]);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    fetchCampaignDropdownData().then(() => {
      fetchPointTypeDropdownData().then(() => {
         //
      });
  })
  }, [visible]);
  const drawerClose = () => {
    close();
    clearCampaignTeam();
    form.resetFields();
  };

  const handleSubmit = (data: any) => {
    if(data?.point_type_data?.length <= 0) {
      Notification.error({message:"Please enter atleast one point type!"});
      return;
    }
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        // const formData = new FormData();
        // for (const name in data) {
        //   data[name] && formData.append(name, data[name]); // there should be values.avatar which is a File object
        // }

        EditData(data, editValues.id)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
    title={editValues?.title ? "Edit " + editValues.title + " Extra Point" : "Edit Extra Point"}
      width={"70%"}
    onClose={drawerClose}
    destroyOnClose
    closeIcon={<FontAwesomeIcon icon={faTimes} />}
    open={visible}
    footer={[
      <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
        {editValues?.status>0 ? "Close": "Cancel"}
      </Button>,
      <Button
        key="1"
        hidden={editValues?.status>0}
        form="editExtraPointForm"
        loading={saving}
        htmlType="submit"
        type="primary"
      >
        Save
      </Button>,
    ]}
  >
   <FormComponent
    form={form}
    id="editExtraPointForm"
    handleSubmit={handleSubmit}
    type={'edit'}
    campaignTeam={campaignTeam}
    allCampaignList={allCampaignList}
    pointTypeList={pointTypeList}
    handleChange={handleChange}
    fetchCampaignTeam={fetchCampaignTeam}
    editValues={editValues}
    setCampaignTeam={setCampaignTeam}
    />
  </Drawer>

   
  );
});

export default EditComponent;
