import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { TaskMasterRequest } from "../Validation";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    executorList,
    seniorList,
    loading,
    handleSubmit,
    handleChange,
    editValues,
    type,
  }) => {

    const disableDate = (current: any) => {
      return current && (current < moment().endOf('day') || current > moment().add(50, "years"));
    }

    // set the form values to edit
    useEffect(() => {

      if (editValues && type === "edit") {
        form.setFieldsValue({
          title: editValues.title,
          assign_to_id: editValues.assign_to_id,
          senior_id: editValues.senior_id,
          estimated_delivery_date: moment(editValues.estimated_delivery_date),
          notes: editValues.notes,
        });

        handleChange();
      }
    }, [loading]);


    return (
      <>
        <FormBox
          form={form}
          id={id}
          disabled={editValues?.status >= 5}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Text
                required
                label="Title"
                placeholder="Title"
                name="title"
                rules={TaskMasterRequest().title("Title")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Select
                options={{
                  list: seniorList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="senior_id"
                placeholder="Senior"
                label="Senior"
                rules={TaskMasterRequest().senior_id('Senior')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Select
                options={{
                  list: executorList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="assign_to_id"
                placeholder="Assign To"
                label="Assign To"
                rules={TaskMasterRequest().assign_to_id('Assign To')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.DatePicker
                name="estimated_delivery_date"
                placeholder="Estimated Delivery Date"
                label="Estimated Delivery Date"
                rules={TaskMasterRequest().estimated_delivery_date('estimated_delivery_date')}
                disabledDate={disableDate}
                suffixIcon={<FontAwesomeIcon icon={faCalendar} />}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.TextArea
                
                //mode={"multiple"}
                name="notes"
                autoComplete="false"
                placeholder="Task Description"
                label="Task Description"
                rules={TaskMasterRequest().notes('Task Description')}
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
