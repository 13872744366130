import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Col, Form, Modal, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../../../../store";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { TaskMasterRequest } from "../Validation";

const ChangeStatusComponent: React.FC<any> = observer(({ visible, close }) => {
  const { CAMPAIGN_TASK, COMMON, ROOT } = useStore();
  const { taskStatus } = COMMON;
  const { ChangeStatus, editValues } = CAMPAIGN_TASK;
  const { assignErrorToInput } = ROOT;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  const changeStatus = (data: any) => {
    
    setSaving(true);
    ChangeStatus({id:editValues.id,status: data.status})
      .then(() => {
        close();
        //        form.resetFields();
        return;
      }).catch((e: any) => {
        if (e.data) {
          assignErrorToInput(e?.data.notify);
        }
      })
      .finally(() => setSaving(false));
  };
  useEffect(() => {
    //form.setFieldsValue({status:editValues?.status});
  }, [visible])
  return editValues ? (
    <Modal
      centered
      title={editValues?.title ? "Change "+ editValues?.title + " Task Status" : "Change Status"}
      
      open={visible}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      onCancel={close}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="1"
          htmlType="submit"
          type="primary"
          hidden={editValues?.status >= 5}
          loading={saving}
          form="changeTaskStatusForm"  
        >
          Save
        </Button>,
      ]}
    >
      <FormBox
        form={form}
        disabled={editValues?.status >= 5}
        id={'changeTaskStatusForm'}
        onFinish={changeStatus}
        initialValues={{status:editValues?.status}}
      >
        <Row gutter={15}>
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <InputBox.Select
              options={{
                list: taskStatus,
                valueKey: "id",
                textKey: "value"
              }}
              //mode={"multiple"}
              name="status"
              placeholder="Task Status"
              label="Task Status"
              rules={TaskMasterRequest().status('Task Status')}
            />
          </Col>
        </Row>
      </FormBox>
    </Modal>
  ) : (
    <></>
  );
});

export default ChangeStatusComponent;
