import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Drawer, Form, Button, Popconfirm } from "antd";
import useStore from "../../../../store";
import FormComponent from "./FormComponent";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { QUOTE, ROOT, COMMON } = useStore();
  const { EditData, editValues } = QUOTE;
  const { assignErrorToInput } = ROOT;
  const { clientList, serviceTypeList, campaignList, proposalStatus, questionnaireStatus, currencyList, fetchCampaign, fetchQuoteDropdownData } = COMMON;
  const [sendLink, setSendLink] = useState(false)
  const [converted, setConverted] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [nbdList, setNbdList] = useState<any>([]);
  const [baList, setBaList] = useState<any>([]);
  const [accountManagerList, setAccountManagerList] = useState<any>([]);
  const [tlList, setTlList] = useState<any>([]);
  const [smeList, setSmeList] = useState<any>([]);
  const [seniorList, setSeniorList] = useState<any>([]);
  const [executorList, setExecutorList] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    fetchQuoteDropdownData(editValues?.id ?? null).then((data: any) => {
      setNbdList(data?.bdeList);
      setAccountManagerList(data?.amList);
      setBaList(data?.baList);
      setTlList(data?.tlList);
      setSmeList(data?.smeList);
      setSeniorList(data?.seniorList);
      setExecutorList(data?.executorList);
      fetchCampaign(editValues?.service_type_id);
      setLoading(false);
    }).catch(() => {
      setNbdList([]);
      setBaList([]);
      setAccountManagerList([])
      setLoading(false);
    });
  }, [visible])

  const drawerClose = () => {
    close();
    form.resetFields();
  };

  const onCheckChange = (e: any) => {
    setSendLink(e.target.checked)
  }

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        sendLink ? formData.append('questionnaire_status', '1') : formData.append('questionnaire_status', editValues?.questionnaire_status);
        // converted ? formData.append('converted_to_campaign', '1') : formData.append('converted_to_campaign', '0');
        for (const name in data) {
          if (name == 'senior_id') {
            data[name] != undefined && data[name].forEach((element: any) => {
              formData.append('senior_id[]', element);
            });
          } else if (name == 'executor_id') {
            data[name] != undefined && data[name].forEach((element: any) => {
              formData.append('executor_id[]', element);
            });
          } else if(name == 'service_document_link') {
            data[name] && data[name] != '' && formData.append(name, data[name]);
          } else if (name == 'start_end_date') {
            data[name] != null && formData.append('start_date', moment(data[name][0]).format("YYYY-MM-DD"));
            data[name] != null && formData.append('end_date', moment(data[name][1]).format("YYYY-MM-DD"));
          } else if (name == 'tenure_start_end_date') {
            data[name] != null && formData.append('tenure_start_date', moment(data[name][0]).format("YYYY-MM-DD"));
            data[name] != null && formData.append('tenure_end_date', moment(data[name][1]).format("YYYY-MM-DD"));
          } else {
            data[name] != undefined && formData.append(name, data[name]); // there should be values.avatar which is a File object
          }
        }
        EditData(formData, editValues.id)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title={editValues?.quote_number ? "Edit " + editValues.quote_number + " Quote" : "Edit Quote"}
      placement="right"
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={close}>
          Close
        </Button>,
        <Button
          key="1"
          hidden={editValues.quote_status > 0}
          form="editQuoteForm"
          loading={saving}
          htmlType="submit"
          type="primary"
          onClick={async () => {
            await setConverted(false)
            handleSubmit({ ...form.getFieldsValue(), quote_status: 0 });
          }}
        >
          Save
        </Button>,
        <Button
          key="3"
          hidden={editValues.quote_status > 0}
          //disabled={disabled}
          form="editQuoteForm"
          loading={saving}
          htmlType="submit"
          type="primary"
          onClick={async () => {
            await setConverted(true);
            handleSubmit({ ...form.getFieldsValue(), quote_status: 1 });
          }}
        >
          Convert To Campaign
        </Button>,
        <Popconfirm
          title={<b>Are you sure you want to mark this quote cancelled?</b>}
          okText="Yes"
          cancelText="No"
          cancelButtonProps={{ type: "primary" }}
          okButtonProps={{ type: "primary", className: "btnDanger" }}
          key={'9'}
          onConfirm={async () => {
            await setConverted(false);
            handleSubmit({ ...form.getFieldsValue(), quote_status: 2 });
          }}
          //onOpenChange={() => console.log('open change')}
        >
          <Button
            key="4"
            hidden={editValues.quote_status > 0}
            form="editQuoteForm"
            loading={saving}
            htmlType="submit"
            type="primary"
          >
            Cancel Quote
          </Button>
        </Popconfirm>
      ]}
    >
      <FormComponent
        form={form}
        id="editQuoteForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        editValues={editValues}
        type="edit"
        clientList={clientList}
        serviceTypeList={serviceTypeList}
        campaignList={campaignList}
        currencyList={currencyList}
        fetchCampaign={fetchCampaign}
        nbdList={nbdList}
        baList={baList}
        accountManagerList={accountManagerList}
        proposalStatus={proposalStatus}
        questionnaireStatus={questionnaireStatus}
        onCheckChange={onCheckChange}
        sendLink={sendLink}
        converted={converted}
        tlList={tlList}
        smeList={smeList}
        seniorList={seniorList}
        executorList={executorList}
        loading={loading}
      />
    </Drawer>
  );
});

export default EditComponent;
