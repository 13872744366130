import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import useStore from "../../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import { PageHeader } from "antd";
import RecordPerPage from "../../../../../components/AgGridWrapper/RecordPerPage";

const CampaignFeedbackMaster: React.FC<any> = observer(({ id }) => {
  const { CAMPAIGN_FEEDBACK } = useStore();
  const {
    setPageSize,
    perPage,
    details,
    setupGrid,
    selectedCampaignId,
    setSelectedCampaign,
    ChangeStatus,
  } = CAMPAIGN_FEEDBACK;

  const [addModal, setAddModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);


  useEffect(() => {
    setSelectedCampaign(id);
  }, [id])
  const openAddModal = () => {
    setAddModal(true);
  };

  const closeAddModal = () => {
    setAddModal(false);
  };

  const openEditModal: any = (data: any) => {
    details(data['id']).then(() => {
      //setEditValues(data?.user);
      setEditModal(true);
    })
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  return (
    <>
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          extra={[
            <HeaderButtons key={1} open={openAddModal} />,
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
          <ListComponent
            setupGrid={setupGrid}
            ChangeStatus={ChangeStatus}
            openEditModal={openEditModal}
            selectedCampaignId={selectedCampaignId}
          />
          {addModal && <AddComponent visible={addModal} close={closeAddModal} id={id} />}
          {editModal && <EditComponent visible={editModal} close={closeEditModal} id={id}/>}
        </ContentBox>
      </div>
    </>
  );
});

export default CampaignFeedbackMaster;
