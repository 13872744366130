// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import ListComponent from "./components/ListComponent";
import EditComponent from "./components/EditComponent";
import { PageHeader } from "antd";
import RecordPerPage from "../../../components/AgGridWrapper/RecordPerPage";
import useStore from "../../../store";
import ContentBox from "../../../components/ContentBox/ContentBox";
import AddComponent from "./components/AddComponent";
import HeaderButtons from "./components/HeaderButton";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { ExtraPointsBreadcrumb } from "../../../config/BreadcrumbConfig";
import ChangeStatusComponent from "./components/ChangeStatusComponent";

const ExtraPointMaster: React.FC = observer(() => {
  const { EXTRA_POINT } = useStore();
  const {
    setPageSize,
    perPage,
    details,
    setupGrid,
  } = EXTRA_POINT;

  const [addModal, setAddModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [changeStatusModal, setChangeStatusModal] = useState<boolean>(false);

  
  const openAddModal= () => {
    setAddModal(true);
  };

  const closeAddModal = () => {
    setAddModal(false);
  };
  const openEditModal: any = (data: any) => {
    details(data['id']).then(() => {
      //setEditValues(data?.user);
      setEditModal(true);
    })
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const openStatusModal: any = (data: any) => {
    details(data?.id).then(() => {
      setChangeStatusModal(true);
    })
  };

  const closeStatusModal = () => {
    setChangeStatusModal(false);
  };

  return (
    <>
      <BreadcrumbComponent items={ExtraPointsBreadcrumb.path} />
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          title={ExtraPointsBreadcrumb.title}
          extra={[
            <HeaderButtons key={1} open={openAddModal} />,
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
          <ListComponent
            setupGrid={setupGrid}
            openEditModal={openEditModal}
            openStatusModal={openStatusModal}
          />
          {addModal && <AddComponent visible={addModal} close={closeAddModal}/>}
          {editModal && <EditComponent visible={editModal} close={closeEditModal}/>}
          {changeStatusModal && <ChangeStatusComponent visible={changeStatusModal} close={closeStatusModal}/>}
        </ContentBox>
      </div>
    </>
  );
});

export default ExtraPointMaster;
