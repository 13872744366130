import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../../components/AgGridWrapper/AgGridWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../../../config/Config";
import useStore from "../../../../../../store";
import AllPermissionList from "../../../../../../utils/AllPermissionList";
import moment from "moment";
import DateComparator from "../../../../../../utils/DateComparator";
import { observer } from "mobx-react";

const ListComponent: React.FC<any> = observer(({
  setupGrid,
  openEditModal,
  selectedCampaignId,
}) => {
  const { AUTH } = useStore();
  const ActionRenderer = (props: any) => {
    return (
      <div className="action-column">
        <Button
          type="text"
          title={"Edit"}
          className="editIcon"
          hidden={!AUTH.checkPermission(AllPermissionList.EDIT_TASK)}
          onClick={() => {
            openEditModal(props.data);
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} color={Config.blueBtn} />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    //    getData();
  }, [selectedCampaignId]);

  return (
    <>
      <AgGridWrapper
        components={{ ActionRenderer }}
        onGridReady={setupGrid}
        type="serverSide"
        serverSideInfiniteScroll={true}
        suppressFieldDotNotation={true}

      >
        <AgGridColumn field='title' headerName="Title" />
        <AgGridColumn
          field="start_date"
          headerName="Start Date"
          valueGetter={({ data }) =>
            data['start_date'] &&
            moment(data['start_date']).format(Config.dateFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />
        <AgGridColumn
          field="end_date"
          headerName="End Date"
          valueGetter={({ data }) =>
            data['end_date'] &&
            moment(data['end_date']).format(Config.dateFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />

        <AgGridColumn field='status' headerName="Status" pinned={'right'} />
        <AgGridColumn
          field="created_at"
          headerName="Created At"
          valueGetter={({ data }) =>
            data['created_at'] && moment(data['created_at']).format(Config.dateFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />
        <AgGridColumn
          field="updated_at"
          headerName="Updated At"
          valueGetter={({ data }) =>
            data['updated_at'] && moment(data['updated_at']).format(Config.dateFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />
        <AgGridColumn
          field="created_by_name"
          headerName="Created By"
        />
        <AgGridColumn
          field="updated_by_name"
          headerName="Last Modified By"

        />
        <AgGridColumn
          headerName="Actions"
          type="actionColumn"
          sortable={false}
          filter={false}
          width={120}
          minWidth={120}
          pinned="right"
        />
      </AgGridWrapper>
    </>
  );
});

export default ListComponent;
