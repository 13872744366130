import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { CampaignKpiRequest } from "../Validation";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    kpiStatusList,
    loading,
    handleSubmit,
    handleChange,
    editValues,
    type,
  }) => {

    // set the form values to edit
    useEffect(() => {

      if (editValues && type === "edit") {
        form.setFieldsValue({
          title: editValues.title,
          status: editValues.status,
          start_date: moment(editValues.start_date),
          end_date: moment(editValues.end_date),
          notes: editValues.notes,
        });

        handleChange();
      }
    }, [loading]);


    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Text
                required
                label="Title"
                placeholder="Title"
                name="title"
                rules={CampaignKpiRequest().title("Title")}
              />
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.DatePicker
                name="start_date"
                placeholder="Start Date"
                label="Start Date"
                rules={CampaignKpiRequest().start_date('Start Date')}
                suffixIcon={<FontAwesomeIcon icon={faCalendar} />}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.DatePicker
                name="end_date"
                placeholder="End Date"
                label="End Date"
                rules={CampaignKpiRequest().end_date('End Date')}
                suffixIcon={<FontAwesomeIcon icon={faCalendar} />}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Select
                options={{
                  list: kpiStatusList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="status"
                placeholder="KPI Status"
                label="KPI Status"
                rules={CampaignKpiRequest().status('KPI Status')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.TextArea
                //mode={"multiple"}
                name="notes"
                autoComplete="false"
                placeholder="Notes"
                label="Notes"
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
