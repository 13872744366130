import React, { useEffect, useState } from "react";
import { Button, Col, Form, Space } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { MonthEndReportRequest } from "../Validation";
import useStore from "../../../../../../store";
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import AllPermissionList from "../../../../../../utils/AllPermissionList";

const MonthEndReportComponent: React.FC<any> = observer(
    ({ id }) => {
        const { CAMPAIGN, ROOT, AUTH } = useStore();
        const { assignErrorToInput } = ROOT;
        const { getCampaignMonthlyReportLink, updateCampaignMonthlyReportLink } = CAMPAIGN;
        const [saving, setSaving] = useState<boolean>(false);
        const [editMode, setEditMode] = useState<boolean>(false);
        const [form] = Form.useForm();
        useEffect(() => {
            if (AUTH.checkPermission(AllPermissionList.EDIT_CAMPAIGN_KPI)) {
                getCampaignMonthlyReportLink(id).then((data: any) => {
                    form.setFieldsValue({
                        month_end_report_link: data,
                    });
                })
            }
        }, [id]);

        const handleSubmit = (data: any) => {
            form
                .validateFields()
                .then(() => {
                    setSaving(true);
                    const formData = new FormData();
                    for (const name in data) {
                        data[name] && formData.append(name, data[name]);
                    }
                    updateCampaignMonthlyReportLink(formData, id)
                        .then((response: any) => {
                            form.setFieldsValue({
                                month_end_report_link: response,
                            });
                            setEditMode(false);
                        })
                        .catch((e: any) => {
                            if (e.data) {
                                assignErrorToInput(form, e?.data.errors);
                            }
                        })
                        .finally(() => setSaving(false));
                });
        };
        return (
            <>
               <Space hidden={!AUTH.checkPermission(AllPermissionList.EDIT_CAMPAIGN_KPI)}>
               <Col xs={{ span: 24 }} md={{ span: 24 }}>
                    <FormBox
                        form={form}
                        id={'monthendReportForm'}
                        onFinish={handleSubmit}
                        disabled={!editMode}
                    >
                        <InputBox.Text
                            required
                            label="Month End Report Link"
                            placeholder="Month End Report Link"
                            name="month_end_report_link"
                            rules={MonthEndReportRequest().month_end_report_link("Month End Report Link")}
                        />
                    </FormBox>
                </Col>
                
                <Button
                    key="1"
                    //disabled={disabled}
                    form="monthendReportForm"
                    loading={saving}
                    htmlType="submit"
                    type="primary"
                    title="Save report"
                    hidden={!editMode}
                >
                    <CheckOutlined/>
                    Save
                </Button>
                <Button
                    key="2"
                    htmlType="button"
                    type="primary"
                    onClick={() => {
                        setEditMode(true);
                    }}
                    hidden={editMode}
                    title="Edit Report"
                >
                    <EditOutlined />
                    Edit
                </Button>
                </Space>
            </>
        );
    }
);

export default MonthEndReportComponent;
