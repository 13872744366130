import React, { useEffect, useState } from "react";
import { Drawer, Form, Button } from "antd";
import useStore from "../../../../store";
import FormComponent from "./FormComponent";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import moment from "moment";

const AddComponent: React.FC<any> = observer(({ visible, close }) => {
  const { TESTIMONIAL, ROOT, COMMON } = useStore();
  const { AddData } = TESTIMONIAL;
  const { assignErrorToInput } = ROOT;
  const { allCampaignList, platformList, uploadFileToServer, shiftList,  campaignDetails, fetchCampaignDetails, fetchCampaign, fetchQuoteDropdownData, fetchTestimonialDropdownData } = COMMON;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [managerList, setManagerList] = useState<any>([]);
  const [accountManagerList, setAccountManagerList] = useState<any>([]);
  const [tlList, setTlList] = useState<any>([]);
  const [smeList, setSmeList] = useState<any>([]);
  const [seniorList, setSeniorList] = useState<any>([]);
  const [executorList, setExecutorList] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    fetchQuoteDropdownData().then((data: any) => {
      setManagerList(data?.managerList);
      setAccountManagerList(data?.amList);
      setTlList(data?.tlList);
      setSmeList(data?.smeList);
      setSeniorList(data?.seniorList);
      setExecutorList(data?.executorList);
      setLoading(false);
      fetchTestimonialDropdownData();
    }).catch(() => {
      setManagerList([]);
      setAccountManagerList([])
      setLoading(false);
    });
  }, [visible])
  const drawerClose = () => {
    form.resetFields();
    close();
  };

  
  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
          if(name == 'senior_id') {
            data[name] && data[name].forEach((element:any) => {
              formData.append('senior_id[]', element);
            });
          } else if(name == 'executor_id') {
            data[name] && data[name].forEach((element:any) => {
              formData.append('executor_id[]', element);
            });
          } else if(name == 'link') {
            data[name] && data[name] != '' && formData.append(name, data[name]);
          } else if (name == 'recieved_at') {
            formData.append('recieved_at', moment(data[name]).format("YYYY-MM-DD"));
          } else {
            data[name] != undefined && formData.append(name, data[name]); // there should be values.avatar which is a File object
          }
        }
        AddData(formData)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      })
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        // setDisabled(false);
      })
      .catch(() => {
        // setDisabled(true);
      });
  }, 500);

  const configureCampaignwiseDropdown = (campaignId:any =null) => {
    setLoading(true);
    fetchQuoteDropdownData(campaignId).then((data: any) => {
      setManagerList(data?.managerList);
      setAccountManagerList(data?.amList);
      setTlList(data?.tlList);
      setSmeList(data?.smeList);
      setSeniorList(data?.seniorList);
      setExecutorList(data?.executorList);
      setLoading(false);
    }).catch(() => {
      setManagerList([]);
      setAccountManagerList([])
      setLoading(false);
    });
  }
  return (
    <Drawer
      title="Create Testimonial"
      placement="right"
      width={"70%"}
      open={visible}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Close
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="addTestimonialForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>
      ]}
    >
      <FormComponent
        form={form}
        id="addTestimonialForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        type="add"
        fetchCampaign={fetchCampaign}
        accountManagerList={accountManagerList}
        tlList={tlList}
        smeList={smeList}
        seniorList={seniorList}
        executorList={executorList}
        loading={loading}
        allCampaignList={allCampaignList}
        shiftList={shiftList}
        platformList={platformList}
        configureCampaignwiseDropdown={configureCampaignwiseDropdown}
        fetchCampaignDetails={fetchCampaignDetails}
        campaignDetails={campaignDetails}
        uploadFileToServer={uploadFileToServer}
        managerList={managerList}
      />
    </Drawer>
  );
});

export default AddComponent;
