import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Drawer, Form, Button } from "antd";
import useStore from "../../../../store";
import FormComponent from "./FormComponent";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { TESTIMONIAL, ROOT, COMMON } = useStore();
  const { EditData, editValues } = TESTIMONIAL;
  const { assignErrorToInput } = ROOT;
  const { allCampaignList, platformList, shiftList, fetchCampaignDetails, fetchQuoteDropdownData, fetchTestimonialDropdownData } = COMMON;
  const [sendLink, setSendLink] = useState(false)
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [managerList, setManagerList] = useState<any>([]);
  const [accountManagerList, setAccountManagerList] = useState<any>([]);
  const [tlList, setTlList] = useState<any>([]);
  const [smeList, setSmeList] = useState<any>([]);
  const [seniorList, setSeniorList] = useState<any>([]);
  const [executorList, setExecutorList] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    fetchQuoteDropdownData(editValues?.campaign_id ?? null).then((data: any) => {
      setManagerList(data?.managerList);
      setAccountManagerList(data?.amList);
      setTlList(data?.tlList);
      setSmeList(data?.smeList);
      setSeniorList(data?.seniorList);
      setExecutorList(data?.executorList);
      setLoading(false);
      fetchTestimonialDropdownData();
    }).catch(() => {
      setManagerList([]);
      setAccountManagerList([])
      setTlList([]);
      setSmeList([]);
      setSeniorList([]);
      setExecutorList([]);
      setLoading(false);
    });
  }, [visible])

  const drawerClose = () => {
    close();
    form.resetFields();
  };

  const onCheckChange = (e: any) => {
    setSendLink(e.target.checked)
  }

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        sendLink ? formData.append('questionnaire_status', '1') : formData.append('questionnaire_status', editValues?.questionnaire_status);
        // converted ? formData.append('converted_to_campaign', '1') : formData.append('converted_to_campaign', '0');
        for (const name in data) {
          if (name == 'senior_id') {
            data[name] != undefined && data[name].forEach((element: any) => {
              formData.append('senior_id[]', element);
            });
          } else if (name == 'executor_id') {
            data[name] != undefined && data[name].forEach((element: any) => {
              formData.append('executor_id[]', element);
            });
          } else if (name == 'recieved_at') {
            data[name] != null && formData.append('recieved_at', moment(data[name]).format("YYYY-MM-DD"));
          } else {
            data[name] != undefined && formData.append(name, data[name]); // there should be values.avatar which is a File object
          }
        }
        EditData(formData, editValues.id)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  const configureCampaignwiseDropdown = (campaignId:any =null) => {
    fetchQuoteDropdownData(campaignId).then((data: any) => {
      setManagerList(data?.managerList);
      setAccountManagerList(data?.amList);
      setTlList(data?.tlList);
      setSmeList(data?.smeList);
      setSeniorList(data?.seniorList);
      setExecutorList(data?.executorList);
    }).catch(() => {
      setManagerList([]);
      setAccountManagerList([])
    });
  }
  return (
    <Drawer
      title={editValues?.title ? "Edit " + editValues.title + " Testimonial" : "Edit Testimonial"}
      placement="right"
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={close}>
          Close
        </Button>,
        <Button
          key="1"
          hidden={editValues.quote_status > 0}
          form="editTestimonialForm"
          loading={saving}
          htmlType="submit"
          type="primary"
          onClick={async () => {
            handleSubmit({ ...form.getFieldsValue()});
          }}
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="editTestimonialForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        editValues={editValues}
        type="edit"
        allCampaignList={allCampaignList}
        fetchCampaignDetails={fetchCampaignDetails}
        accountManagerList={accountManagerList}
        onCheckChange={onCheckChange}
        sendLink={sendLink}
        tlList={tlList}
        smeList={smeList}
        seniorList={seniorList}
        executorList={executorList}
        loading={loading}
        shiftList={shiftList}
        platformList={platformList}
        managerList={managerList}
        configureCampaignwiseDropdown={configureCampaignwiseDropdown}
      />
    </Drawer>
  );
});

export default EditComponent;
