import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { EscalationMasterRequest } from "../Validation";


const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    configureCampaignwiseDropdown,
    shiftList,
    handleSubmit,
    editValues,
    allCampaignList,
    accountManagerList,
    smeList,
    seniorList,
    executorList,
    loading,
    fetchCampaignDetails,
    managerList,
    escalationTypeList,
    userList,
  }) => {
    const handleChangeCampaign = (e: any) => {
			fetchCampaignDetails(e).then((data: any) => {
				form.setFieldsValue({
					shift_id: data?.shift_id,
					tl_id: data?.tl_id,
					client_email: data?.email,
					client_name: data?.client.firstname +" "+ data?.client.lastname,
					service_type: data?.serviceType.name,
					executor_id: data?.executor_id,
					senior_id: data?.senior_id,
					sme_id: data?.sme_id,
					manager_id: data?.manager_id,
					account_manager_id: data?.account_manager_id,
				});
				configureCampaignwiseDropdown(e);
			})
		}
    // set the form values to edit

    useEffect(() => {
      if (editValues) {
				fetchCampaignDetails(editValues?.campaign_id).then(() => {
					form.setFieldsValue({
						shift_id: editValues?.campaign?.shift_id,
						tl_id: editValues?.tl_id,
						client_email: editValues?.campaign?.email,
						client_name: editValues?.campaign?.client.firstname +" "+ editValues?.campaign?.client.lastname,
						service_type: editValues?.campaign?.service_type.name,
						escalation_type_id: editValues.escalation_type_id,
						campaign_id: editValues?.campaign_id,
						executor_id: editValues.executor_id,
						senior_id: editValues.senior_id,
						primary_responsible_user_id: editValues.primary_responsible_user_id,
						secondary_responsible_user_id: editValues.secondary_responsible_user_id,
						brief: editValues.brief,
						learnings: editValues.learnings,
						manager_id: editValues.manager_id,
						account_manager_id: editValues.account_manager_id,
						sme_id: editValues.sme_id,
					});
			});
      }
    }, [loading]);


    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          initialValues={{ proposal_status: 0 }}
        //onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: escalationTypeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="escalation_type_id"
                placeholder="Escalation Type"
                label="Escalation Type"
                rules={EscalationMasterRequest().escalation_type_id('Escalation Type')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}></Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: allCampaignList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Campaign"
                label="Campaign"
                name="campaign_id"
                onChange={(e) => {
                  handleChangeCampaign(e);
                }}
                rules={EscalationMasterRequest().campaign_id('Campaign')}
              />
            </Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}></Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Client Email"
                placeholder="Client Email"
                name="client_email"
								disabled={true}
              />
            </Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Client Name"
                placeholder="Client Name"
                name="client_name"
								disabled={true}
              />
            </Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Service Type"
                placeholder="Service Type"
                name="service_type"
								disabled={true}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: shiftList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="GEO"
                label="GEO"
                name="shift_id"
								disabled={true}
              />
            </Col>

						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                required={true}
                options={{
                  list: managerList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="manager_id"
                placeholder="Manager"
                label="Manager"
								disabled={true}
              />
            </Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: smeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="sme_id"
                placeholder="SME"
                label="SME"
                required={true}
								disabled={true}
              />
            </Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: accountManagerList,
                  valueKey: "id",
                  textKey: "name"
                }}
                required={true}
                showSearch={true}
                allowClear={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="account_manager_id"
                placeholder="Account Manager"
                label="Account Manager"
								disabled={true}
              />
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: seniorList,
                  valueKey: "id",
                  textKey: "name"
                }}
                showSearch={true}
                allowClear={true}
                mode={"multiple"}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="senior_id"
                placeholder="Senior"
                label="Senior"

              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: executorList,
                  valueKey: "id",
                  textKey: "name"
                }}
                mode={"multiple"}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="executor_id"
                placeholder="Executor"
                label="Executor"

              />
            </Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.TextArea
                label="Brief"
                placeholder="Brief"
                name="brief"
              />
            </Col>

						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: userList,
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="primary_responsible_user_id"
                placeholder="Primary Person Responsible"
                label="Primary Person Responsible"

              />
            </Col> <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: userList,
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="secondary_responsible_user_id"
                placeholder="Secondary Person Responsible"
                label="Secondary Person Responsible"

              />
            </Col>
						<Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.TextArea
                label="Key Learnings"
                placeholder="Key Learnings"
                name="learnings"

              />
            </Col>

          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
