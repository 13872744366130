import React, { useEffect, useState } from "react";
import { Drawer, Form, Button } from "antd";
import useStore from "../../../../store";
import FormComponent from "./FormComponent";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import moment from "moment";

const AddComponent: React.FC<any> = observer(({ visible, close }) => {
  const { QUOTE, ROOT, COMMON } = useStore();
  const { AddData } = QUOTE;
  const { assignErrorToInput } = ROOT;
  const { clientList, serviceTypeList, proposalStatus, questionnaireStatus, campaignList, currencyList, fetchCampaign, fetchQuoteDropdownData } = COMMON;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [converted, setConverted] = useState<boolean>(false);
  const [sendLink, setSendLink] = useState(false);
  const [nbdList, setNbdList] = useState<any>([]);
  const [baList, setBaList] = useState<any>([]);
  const [accountManagerList, setAccountManagerList] = useState<any>([]);
  const [tlList, setTlList] = useState<any>([]);
  const [smeList, setSmeList] = useState<any>([]);
  const [seniorList, setSeniorList] = useState<any>([]);
  const [executorList, setExecutorList] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    fetchQuoteDropdownData().then((data: any) => {
      setNbdList(data?.bdeList);
      setAccountManagerList(data?.amList);
      setBaList(data?.baList);
      setTlList(data?.tlList);
      setSmeList(data?.smeList);
      setSeniorList(data?.seniorList);
      setExecutorList(data?.executorList);
      setLoading(false);
    }).catch(() => {
      setNbdList([]);
      setBaList([]);
      setAccountManagerList([])
      setLoading(false);
    });
  }, [visible])
  const drawerClose = () => {
    form.resetFields();
    close();
  };

  const onCheckChange = (e: any) => {
    setSendLink(e.target.checked)
  }

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        sendLink ? formData.append('questionnaire_status', '1') :  formData.append('questionnaire_status', '0');
        for (const name in data) {
          if(name == 'senior_id') {
            data[name] && data[name].forEach((element:any) => {
              formData.append('senior_id[]', element);
            });
          } else if(name == 'executor_id') {
            data[name] && data[name].forEach((element:any) => {
              formData.append('executor_id[]', element);
            });
          } else if(name == 'service_document_link') {
            data[name] && data[name] != '' && formData.append(name, data[name]);
          } else if (name == 'start_end_date') {
            data[name] ? formData.append('start_date', moment(data[name][0]).format("YYYY-MM-DD")) : '';
            data[name] ? formData.append('end_date', moment(data[name][1]).format("YYYY-MM-DD")) : '';
          } else if (name == 'tenure_start_end_date') {
            data[name] ? formData.append('tenure_start_date', moment(data[name][0]).format("YYYY-MM-DD")) : '';
            data[name] ? formData.append('tenure_end_date', moment(data[name][1]).format("YYYY-MM-DD")) : '';
          } else {
            data[name] != undefined && formData.append(name, data[name]); // there should be values.avatar which is a File object
          }
        }
        AddData(formData)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      })
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        // setDisabled(false);
      })
      .catch(() => {
        // setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title="Create Quote"
      placement="right"
      width={"70%"}
      open={visible}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Close
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="addQuoteForm"
          loading={saving}
          htmlType="submit"
          type="primary"
          onClick={async () => {
            await setConverted(false);
            handleSubmit({...form.getFieldsValue(), quote_status: 0});
            //handleSubmit({...form.getFieldsValue(), questionnaire_status: sendLink ? 1 : 0});
            //handleSubmit({...form.getFieldsValue(), questionnaire_status:form.getFieldsValue().questionnaire_status ?? 0});
          }}
        >
          Save
        </Button>,
        <Button
          key="3"
          //disabled={disabled}
          form="addQuoteForm"
          loading={saving}
          htmlType="submit"
          type="primary"
          onClick={async () => {
            await setConverted(true)
            handleSubmit({...form.getFieldsValue(), quote_status:1});
            //handleSubmit({...form.getFieldsValue(), questionnaire_status:3});
          }}
        >
          Convert To Campaign
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="addQuoteForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        type="add"
        clientList={clientList}
        serviceTypeList={serviceTypeList}
        campaignList={campaignList}
        currencyList={currencyList}
        fetchCampaign={fetchCampaign}
        nbdList={nbdList}
        baList={baList}
        accountManagerList={accountManagerList}
        proposalStatus={proposalStatus}
        questionnaireStatus={questionnaireStatus}
        onCheckChange={onCheckChange}
        sendLink={sendLink}
        converted={converted}
        tlList={tlList}
        smeList={smeList}
        seniorList={seniorList}
        executorList={executorList}
        loading={loading}
      />
    </Drawer>
  );
});

export default AddComponent;
