import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { PackageMasterRequest } from "../Validation";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Message from "../../../../../../config/Message";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    handleSubmit,
    handleChange,
    editValues,
    type,
    campaignList,
    roleList,
  }) => {

    // set the form values to edit
    useEffect(() => {
      if (editValues && type === "edit") {
        form.setFieldsValue({
          title: editValues.title,
          package_number: editValues.package_number,
          campaign_type_id: editValues.campaign_type_id,
          point_convertion_ratio: editValues.point_convertion_ratio,
        });
        const pointMasterDetails: any = [];
        editValues?.packageRoleMatrix?.forEach((element: any) => {
          pointMasterDetails.push({
            role_id: element?.role_id,
            points: element?.points
          })
        });
        form.setFieldsValue({
          point_master_data: pointMasterDetails,
        });
        handleChange();
      }
    }, [editValues, form]);
    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Title"
                placeholder="Title"
                name="title"
                rules={PackageMasterRequest().title("Title")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Package Number"
                placeholder="Package Number"
                name="package_number"
                rules={PackageMasterRequest().title("Package Number")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: campaignList,
                  valueKey: "id",
                  textKey: "name"
                }}
                allowClear={true}
                showSearch={true}
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                name="campaign_type_id"
                placeholder="Campaign Type"
                label="Campaign Type"
                rules={PackageMasterRequest().campaign_type_id('Campaign Type')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Point Convertion Ratio"
                placeholder="Point Convertion Ratio"
                name="point_convertion_ratio"
                rules={PackageMasterRequest().point_convertion_ratio("Point Convertion Ratio")}
              />
            </Col>
          </Row>
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Form.List name="point_master_data">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={15} key={key} >
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'role_id']}
                            rules={PackageMasterRequest().role_id("Role")}
                          >
                            <InputBox.Select
                              options={{
                                list: roleList,
                                valueKey: "id",
                                textKey: "name"
                              }}
                              //mode={"multiple"}
                              placeholder="Role"
                              label="Role"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>

                          <Form.Item
                            {...restField}
                            name={[name, 'points']}
                            rules={[
                              { required: true, message: Message().required.text("Points %") },
                              {
                                pattern: /^(?:100|\d{1,2})(?:\.\d{1,2})?$/,
                                message: "Please enter points % between 1 to 100 and with 2 decimal points only"
                              },
                            ]}
                          >
                            <InputBox.Text label="Points %" placeholder="Points %" />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 2 }}>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Point Type
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
