import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Form } from 'antd';
import useStore from "../../../../store";
import FormComponent from "./FormComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Notification } from "../../../../config/Global";

const AddRoleComponent: React.FC<any> = observer(({
	visible,
	closeAddRolePopup,
}) => {
	const { ROLE } = useStore();
	const {
		permissionList,
		getPermissionList,
		create,
		showPermissionModel,
		openPermissionModel,
		closePermissionModel
	} = ROLE;
	const { ROOT } = useStore();
	const { assignErrorToInput } = ROOT;

	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [selectedPermission, setPermission] = useState<any>([]);
	useEffect(() => {
		getPermissionList()
		form.setFieldsValue({ name: '' });
	}, [getPermissionList])

	const onCheckChange = (e: any) => {
		const options = selectedPermission;
		let index

		if (e.target.checked) {
			options.push(+e.target.value)
		} else {
			index = options.indexOf(+e.target.value)
			options.splice(index, 1)
		}
		setPermission(options);
	}
	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		if (!selectedPermission.length) {
			Notification.error({ message: 'Please select at least one permission for role!' });
			return;
		}

		data.permission = selectedPermission;
		setSaving(true);

		create(data)
			.then(() => {
				setSaving(false);
				setPermission([]);
				drawerClose();
			})
			.catch((e: any) => {
				const errors = e?.errors || e.data?.errors
				assignErrorToInput(form, errors);
				setSaving(false);
			});
	};
	const drawerClose = () => {
		form.resetFields();
		setPermission([]);
		closeAddRolePopup();
	}
	return (
		<Drawer
			title="Add New Role"
			placement="right"
			width={"70%"}
			onClose={drawerClose}
			destroyOnClose
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			open={visible}
			footer={[
				<Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
					Cancel
				</Button>,
				<Button
					key="1"
					form="addRoleForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<FormComponent
				formId="addRoleForm"
				form={form}
				handleSubmit={handleSubmit}
				permissionList={permissionList}
				selectedPermission={selectedPermission}
				onCheckChange={onCheckChange}
				showPermissionModel={showPermissionModel}
				closePermissionModel={closePermissionModel}
				openPermissionModel={openPermissionModel}
				saving={saving}
			/>
		</Drawer>
	);
});
export default AddRoleComponent;
