import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Drawer, Form, Button } from "antd";
import useStore from "../../../../../../store";
import FormComponent from "./FormComponent";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../../../config/Config";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { CAMPAIGN_DISCUSSION, ROOT, COMMON } = useStore();
  const { EditData, editValues } = CAMPAIGN_DISCUSSION;
  const { assignErrorToInput } = ROOT;
  const { fetchUser, fetchDiscussionType, userList, discussionTypeList } = COMMON;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    fetchDiscussionType(2);
		fetchUser();
  }, [visible])
  const drawerClose = () => {
    close();
    form.resetFields();
  };

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
					if(name === "discussion_date_time"){
						data[name] && formData.append(name, data[name].format(Config.dateTimeFormat));
					}else{
            data[name] && formData.append(name, data[name]); // there should be values.avatar which is a File object
					}
        }

        EditData(formData, editValues.id)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title={editValues.id ? "Edit Discussion: "+editValues.id :"Edit Campaign Discussion"}
      placement="right"
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="editCampaignDiscussionForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="editCampaignDiscussionForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        editValues={editValues}
        type="edit"
				discussionTypeList={discussionTypeList}
        userList={userList}
      />
    </Drawer>
  );
});

export default EditComponent;
