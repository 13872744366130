import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import RootStore from "../RootStore/RootStore";

export default class CampaignFeedbackStore {
	agGrid: any = null;
	perPage = Config.grid.local.gridOptions?.paginationPageSize;
	editValues = null;
	deleteValues = null;
	seletedCampaignId: any = null;
	rootstore: any;
	constructor() {
		makeAutoObservable(this);
		this.rootstore = new RootStore();
	}
	// Setter Functions
	private setGrid = (value: any) => {
		this.agGrid = value;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params: any) => {
		this.setGrid(params);
		this.setPageSize();
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
		this.agGrid = params;
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootstore.getServerListPayload(params);
				const queryParams = { ...payload, campaign_id: this.seletedCampaignId };
				this.fetchList(queryParams).then((data) => {
					params.success({ rowData: data?.rows, rowCount: data?.count });
				});
			},
		};
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(Number(page));
		}
	};

	setDeleteValues = (data: any) => {
		this.deleteValues = data;
	};

	setEditValues = (data: any) => {
		this.editValues = data;
	};

	setSelectedCampaign = (id: any) => {
		this.seletedCampaignId = id;
	};

	// Setter Functions

	// API Functions
	public fetchList = async (params?: any): Promise<any> => {
		return await axios
			.post(API_URL.CAMPAIGN_FEEDBACK.LIST, params)
			.then(({ data }) => {
				return data.data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	AddData = (formData: any) => {
		formData.append("campaign_id", this.seletedCampaignId);
		return axios
			.post(API_URL.CAMPAIGN_FEEDBACK.CREATE, formData)
			.then(() => {
				this.setupGrid(this.agGrid);
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	EditData = (formData: any, editId: number) => {
		formData.append("campaign_id", this.seletedCampaignId);
		return axios
			.post(API_URL.CAMPAIGN_FEEDBACK.UPDATE(editId), formData)
			.then(() => {
				this.setupGrid(this.agGrid);
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	public ChangeStatus = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.CAMPAIGN_FEEDBACK.CHANGE_STATUS(payload.id), {
				status: payload.status,
			})
			.then(({ data }) => {
				this.setupGrid(this.agGrid);
				return data.data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.CAMPAIGN_FEEDBACK.DETAILS(id)).then(({ data }) => {
			this.setEditValues(data.data);
			return data.data;
		});
	};
}
