import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Form } from 'antd';
import useStore from "../../../../../store";
import FormComponent from "./FormComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const EditRoleComponent: React.FC<any> = observer(({
	visible,
	closeEditModal,
}) => {
	const { TEMPLATE, ROOT } = useStore();
	const {
		update,
		details,
		editTemplateID,
		setTemplate,
	} = TEMPLATE;
	const drawerClose = () => {
		closeEditModal();
		form.resetFields();
	}
	const { assignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	useEffect(() => {

		if (editTemplateID) {
			form.resetFields();
			setSaving(true);

			details(editTemplateID).then((data: any) => {
				form.setFieldsValue({
					title: data.data?.title,
					subject: data.data?.subject,
					content_sms: data.data?.content_sms,
					content_email: data.data?.content_email,
				});
				setSaving(false);
			});

		}

	}, [editTemplateID])

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		update(editTemplateID, data)
			.then(() => {
				setSaving(false);
				form.resetFields();
				setTemplate();
				closeEditModal();
			})
			.catch((e: any) => {
				setSaving(false);
				e?.data?.data?.forEach((element: any) => {
					assignErrorToInput(form, element);
				});
			})
	};

	return (
		<Drawer
			title="Edit Template"
			placement="right"
			width={"70%"}
			onClose={drawerClose}
			destroyOnClose
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			open={visible}
			footer={[
				<Button key="2" htmlType="button" className="cancelBtn" onClick={closeEditModal}>
					Cancel
				</Button>,
				<Button
					key="1"
					form="editTemplateForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<FormComponent
				formId="editTemplateForm"
				form={form}
				handleSubmit={handleSubmit}
				saving={saving}
			/></Drawer>
	);
});
export default EditRoleComponent;
