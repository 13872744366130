import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import RootStore from "../RootStore/RootStore";

export default class UserStore {
  agGrid: any = null;
  perPage = Config.grid.local.gridOptions?.paginationPageSize;
  editValues = null;
  deleteValues = null;
  roleModelValues = null;
  rootstore: any;
  rewardPointsData: any[] = [];
  assignRoleToUserModel = false
  constructor() {
    makeAutoObservable(this);
    this.rootstore = new RootStore();

  }
  // Setter Functions
  private setGrid = (value: any) => {
    this.agGrid = value;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params: any) => {
    this.setGrid(params);
    this.setPageSize()
    const datasource = this.createDatasource(Config.grid.server.gridOptions);
    params.api.setServerSideDatasource(datasource);
    this.agGrid = params;
  };

  private createDatasource = (gridOptions?: GridOptions) => {
    return {
      gridOptions,
      getRows: (params: IServerSideGetRowsParams) => {
        const payload = this.rootstore.getServerListPayload(params);
        this.fetchList(payload).then((data) => {
          params.success({ rowData: data?.rows, rowCount: data?.count });
          //params.successCallback(data?.rows, data?.count);

        });
      },
    };
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.perPage) => {
    this.perPage = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(Number(page));
    }
  };

  setDeleteValues = (data: any) => {
    this.deleteValues = data;
  };

  setEditValues = (data: any) => {
    this.editValues = data;
  };


  public setAssignRoleToUserModelValues = (userData: any = null) => {
    this.roleModelValues = userData;
  }

  public setAssignRoleToUserModel = (value: boolean): void => {
    this.assignRoleToUserModel = value;
  };

  public setRewardPointsData = (value = []): void => {
    this.rewardPointsData = value;
  };

  // API Functions
  public fetchList = async (params?: any): Promise<any> => {
    return await axios
      .post(API_URL.USER.LIST, params)
      .then(({ data }) => {
        return data.data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  AddData = (formData: any) => {
    return axios
      .post(API_URL.USER.CREATE, formData)
      .then(() => {
        this.setupGrid(this.agGrid);
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  DeleteData = (id: number) => {
    return axios
      .delete(API_URL.USER.DELETE(id))
      .then(() => {
        this.setupGrid(this.agGrid);
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  EditData = (formData: any, editId: number) => {
    return axios
      .post(API_URL.USER.UPDATE(editId), formData)
      .then(() => {
        this.setupGrid(this.agGrid);
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };


  public ChangeStatus = (payload: any): Promise<any> => {
    return axios.post(API_URL.USER.CHANGE_STATUS(payload.id), { status: payload.status }).then(({ data }) => {
      this.setupGrid(this.agGrid);
      return data.data;
    }).catch((response) => {
      return Promise.reject(response);
    });
  };

  public ChangePassword = (payload: any): Promise<any> => {
    return axios.post(API_URL.CHANGE_PASSWORD, payload).then(({ data }) => {
      return data.data;
    }).catch((response) => {
      return Promise.reject(response);
    });
  };

  public details = (id: number): Promise<any> => {
    return axios.get(API_URL.USER.DETAILS(id)).then(({ data }) => {
      this.setEditValues(data.data);
      return data.data;
    }).catch((response) => {
      return Promise.reject(response);
    });
  };

  public assignRoleToUser = (payload: any): Promise<any> => {
    return axios.post(API_URL.ROLE.ASSIGN_ROLE_TO_USER, payload).then(({ data }) => {
      return data.data;
    }).catch((response) => {
      return Promise.reject(response);
    });
  };

  public sendPasswordRequest = (email: string, type: any): Promise<any> => {
    const url = (type == 1) ? API_URL.ADMIN_FORGOT_PASSWORD : API_URL.FORGOT_PASSWORD;
    const postData = (type == 1) ? { email: email } : { username: email };
    return axios.post(url, postData).then(({ data }) => {
      return data.data;
    }).catch((response) => {
      return Promise.reject(response);
    });
  };

  public getRewardPoints = (id: number): Promise<any> => {
    this.closeRewardPoints();
    return axios.post(API_URL.USER.REWARD_POINTS(id)).then(({ data }) => {
      this.setRewardPointsData(data?.data?.rows)
      return data.data;
    }).catch((response) => {
      return Promise.reject(response);
    });
  };

  public closeRewardPoints = () => {
    this.setRewardPointsData([]);
  };
  
  public getMyProfile = () => {
    return axios.get(API_URL.USER.MY_PROFILE).then(({ data }) => {
      return data.data;
    }).catch((response) => {
      return Promise.reject(response);
    });
  };

}
