import { RequestProps } from "../../../../../config/InterfacesAndTypes";
import Message from "../../../../../config/Message";
import { Regex, maxName } from "../../../../../config/Validators";




export const CampaignFeedbackRequest = (): RequestProps => ({
	name: (field?: string) => [
		{ required: true, message: Message().required.text(field) },
		{ max: maxName, message: Message().max.string(maxName, field) },
	],
	email: (field?: string) => [
		{ required: true, message: Message().required.text(field) },
		{ max: maxName, message: Message().max.string(maxName, field) },
		{ pattern: Regex.email, message: Message().regex.other(field) },
	],
	campaign_name: (field?: string) => [
		{ required: true, message: Message().required.text(field) },
		{ max: maxName, message: Message().max.string(maxName, field) },
	],
	service: (field?: string) => [
		{ required: true, message: Message().required.text(field) },
		{ max: maxName, message: Message().max.string(maxName, field) },
	],
	received_at: (field?: string) => [
		{ required: true, message: Message().required.select(field) },
	],
	link: (field?: string) => [
		{ required: true, message: Message().required.text(field) },
	]

});
