// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React from "react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { ClientKpiBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import { PageHeader } from "antd";
import RecordPerPage from "../../../../components/AgGridWrapper/RecordPerPage";
import ListComponent from "./components/ListComponent";

const ClientKPIMaster: React.FC = observer(() => {
  const { CLIENT_KPI } = useStore();
  const {
    setPageSize,
    perPage,
    setupGrid
  } = CLIENT_KPI;


  return (
    <>
      <BreadcrumbComponent items={ClientKpiBreadcrumb.path} />
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          title={ClientKpiBreadcrumb.title}
          extra={[
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
          <ListComponent
            setupGrid={setupGrid}
          />
        </ContentBox>
      </div>
    </>
  );
});

export default ClientKPIMaster;
