import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import { faHandHoldingDollar, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../config/Config";
import useStore from "../../../../store";
import AllPermissionList from "../../../../utils/AllPermissionList";
import { Switch } from "antd";
import moment from "moment";
import DateComparator from "../../../../utils/DateComparator";
import { observer } from "mobx-react";

const ListComponent: React.FC<any> = observer(
	({
		setupGrid,
		ChangeStatus,
		openEditModal,
		openRewardPointsModal
	}) => {
		const { AUTH } = useStore();
		const { user } = AUTH;
		const ActionRenderer = (props: any) => {
			return (
				<div className="action-column">
					<Button
						type="text"
						title={"Edit"}
						className="editIcon"
						hidden={
							!AUTH.checkPermission(AllPermissionList.EDIT_USER) ||
							(props?.data["role_slug"] == "super-admin" &&
								user &&
								user?.user?.userRole[0]?.role.slug !== "super-admin")
						}
						onClick={() => {
							openEditModal(props.data);
						}}
					>
						<FontAwesomeIcon icon={faPencilAlt} color={Config.blueBtn} />
					</Button>
					<Button
						type="text"
						title={"User Reward Points"}
						className="editIcon"
						hidden={!AUTH.checkPermission(AllPermissionList.LIST_USER_REWARD_POINTS)}
						onClick={() => {
							openRewardPointsModal(props.data);
						}}
					>
						<FontAwesomeIcon icon={faHandHoldingDollar} color={Config.greenBtn} />
					</Button>
				</div>
			);
		};

		useEffect(() => {
			//    getData();
		}, []);

		const changeStatus = (data: any) => {
			ChangeStatus(data)
				.then(() => {
					return;
				})
				.finally(() => {
					return;
				});
		};
		const statusRenderer = (params: any) => {
			return (
				<div>
					<Switch
						defaultChecked={params.data["status"] == 1}
						onChange={() =>
							changeStatus({
								id: params.data["id"],
								status: params.data["status"] == 1 ? 2 : 1,
							})
						}
						disabled={
							params.data?.status == 0 ||
							!AUTH.checkPermission(AllPermissionList.EDIT_USER)
						}
					/>
				</div>
			);
		};

		return (
			<>
				<AgGridWrapper
					components={{ ActionRenderer, statusRenderer }}
					onGridReady={setupGrid}
					type="serverSide"
					serverSideInfiniteScroll={true}
					suppressFieldDotNotation={true}
				>
					<AgGridColumn field="employee_id" headerName="Employee Id" />
					<AgGridColumn field="user_name" headerName="Name" />
					<AgGridColumn
						field="reporting_manager_name"
						headerName="Reporting Manager"
					/>
					<AgGridColumn field="email" headerName="Email" />
					<AgGridColumn field="phone_number" headerName="Phone Number" />
					<AgGridColumn field="role_name" headerName="Role" />
					<AgGridColumn field="shift_name" headerName="Shift" />
					<AgGridColumn field="department_name" headerName="Department" />
					<AgGridColumn
						field="created_at"
						headerName="Created At"
						valueGetter={({ data }) =>
							data["created_at"] &&
							moment(data["created_at"]).format(Config.dateTimeFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn
						field="updated_at"
						headerName="Updated At"
						valueGetter={({ data }) =>
							data["updated_at"] &&
							moment(data["updated_at"]).format(Config.dateTimeFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn field="created_by_name" headerName="Created By" />
					<AgGridColumn field="updated_by_name" headerName="Last Modified By" />
					<AgGridColumn
						field="status"
						headerName="Status"
						cellRenderer={statusRenderer}
						pinned="right"
						sortable={false}
						filter={false}
						width={120}
						minWidth={120}
					/>
					<AgGridColumn
						headerName="Actions"
						type="actionColumn"
						sortable={false}
						filter={false}
						width={120}
						minWidth={120}
						pinned="right"
					/>
				</AgGridWrapper>
			</>
		);
	}
);

export default ListComponent;
