import { RequestProps } from "../../../../../config/InterfacesAndTypes";
import Message from "../../../../../config/Message";
import { Regex } from "../../../../../config/Validators";

export const PointTypeMasterRequest = (): RequestProps => ({
    role_id: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    type: (field?: string) =>[{ required: true, message: Message().required.select(field) }],
    points: (field?: string) =>[
        { required: true, message: Message().required.select(field) },
        { min: 1, message: Message().min.select(1, field) },
        { pattern: Regex.twoDecimal, message: Message().regex.decimal(2) },
    ],
});