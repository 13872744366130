import React from "react";
import { DollarOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import useStore from "../../../../../store";
import AllPermissionList from "../../../../../utils/AllPermissionList";

const HeaderButtons: React.FC<any> = ({ open, openConversionModel }) => {
  const {AUTH} = useStore();
  return (
    <Space>
      <Button 
        icon={<DollarOutlined />}
        type="primary"
        key={'conversion'}
        hidden={!AUTH.checkPermission(AllPermissionList.EDIT_CURRENCY)}       
        onClick={openConversionModel}>
        Conversion Rate
      </Button>
      <Button 
        icon={<PlusOutlined />}
        type="primary"
        key={'add'}
        hidden={!AUTH.checkPermission(AllPermissionList.ADD_CURRENCY)}       
        onClick={open}>
        Create
      </Button>
    </Space>
  );
};

export default HeaderButtons;
