import { createContext, useContext } from "react";
import { Context } from "vm";
import AuthStore from "./AuthStore/AuthStore";
import BoosterTireMasterStore from "./BoosterTireMasterStore/BoosterTireMasterStore";
import CampaignChecklistStore from "./CampaignChecklistStore/CampaignChecklistStore";
import CampaignDiscussionStore from "./CampaignStore/CampaignDiscussionStore";
import CampaignFeedbackStore from "./CampaignStore/CampaignFeedbackStore";
import CampaignKpiStore from "./CampaignStore/CampaignKpiStore";
import CampaignStore from "./CampaignStore/CampaignStore";
import CampaignTaskStore from "./CampaignStore/CampaignTaskStore";
import ClientStore from "./ClientStore/ClientStore";
import CommonStore from "./CommonStore/CommonStore";
import DashboardStore from "./DashboardStore/DashboardStore";
import CampaignChecklistMasterStore from "./MasterStore/CampaignChecklistMasterStore/CampaignChecklistMasterStore";
import CampaignTypeStore from "./MasterStore/CampaignTypeStore/CampaignTypeStore";
import CurrencyStore from "./MasterStore/CurrencyStore/CurrencyStore";
import DiscussionTypeStore from "./MasterStore/DiscussionTypeStore/DiscussionTypeStore";
import PackageStore from "./MasterStore/PackageStore/PackageStore";
import PointTypeStore from "./MasterStore/PointTypeStore/PointTypeStore";
import ServiceTypeStore from "./MasterStore/ServiceTypeStore/ServiceTypeStore";
import ShiftGeoStore from "./MasterStore/ShiftGeoStore/ServiceTypeStore";
import EscalationTypeStore from "./MasterStore/EscalationTypeStore/EscalationTypeStore";
import TemplateStore from "./MasterStore/TemplateStore/TemplateStore";
import WorkModelStore from "./MasterStore/WorkModelStore/WorkModelStore";
import QuoteDiscussionStore from "./QuoteDiscussionStore/QuoteDiscussionStore";
import QuoteStore from "./QuoteStore/QuoteStore";
import RoleStore from "./RoleStore/RoleStore";
import RootStore from "./RootStore/RootStore";
import UserStore from "./UserStore/UserStore";
import TestimonialeStore from "./TestimonialStore/TestimonialStore";
import EscalationStore from "./EscalationStore/EscalationStore";
import PausedCampaignStore from "./CampaignStore/PausedCampaignStore";
import LostCampaignStore from "./CampaignStore/LostCampaignStore";
import ExtraPointStore from "./ExtraPointStore/ExtraPointStore";
import ClientKpiStore from "./BusinessAnalysisStore/ClientKpiStore";
import MyRewardPointStore from "./UserStore/MyRewardPointStore";

const AppContext = createContext({
  AUTH: new AuthStore(),
  BOOSTER_TIRE: new BoosterTireMasterStore(),
  CAMPAIGN_CHECKLIST: new CampaignChecklistStore(),
  CAMPAIGN_DISCUSSION: new CampaignDiscussionStore(),
  CAMPAIGN_FEEDBACK: new CampaignFeedbackStore(),
  CAMPAIGN_KPI: new CampaignKpiStore(),
  CAMPAIGN_TASK: new CampaignTaskStore(),
  CAMPAIGN_TYPE: new CampaignTypeStore(),
  CAMPAIGN: new CampaignStore(),
  PAUSED_CAMPAIGN: new PausedCampaignStore(),
  LOST_CAMPAIGN: new LostCampaignStore(),
  CHECKLIST: new CampaignChecklistMasterStore(),
  CLIENT: new ClientStore(),
  COMMON: new CommonStore(),
  CURRENCY: new CurrencyStore(),
  DASHBOARD_STORE: new DashboardStore(),
  DISCUSSION_TYPE: new DiscussionTypeStore(),
  PACKAGE: new PackageStore(),
  POINT_TYPE: new PointTypeStore(),
  QUOTE_DISCUSSION: new QuoteDiscussionStore(),
  QUOTE: new QuoteStore(),
  ROLE: new RoleStore(),
  ROOT: new RootStore(),
  SERVICE_TYPE: new ServiceTypeStore(),
  SHIFT_GEO: new ShiftGeoStore(),
  ESCALATION_TYPE: new EscalationTypeStore(),
  ESCALATION: new EscalationStore(),
  TEMPLATE: new TemplateStore(),
  TESTIMONIAL: new TestimonialeStore(),
  USER: new UserStore(),
  EXTRA_POINT: new ExtraPointStore(),
  CLIENT_KPI: new ClientKpiStore(),
  MY_REWARD_POINT_STORE: new MyRewardPointStore(),
  WORK_MODEL: new WorkModelStore(),
});

const useStore = (): Context => useContext(AppContext);

export default useStore;
