// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import useStore from "../../../../../store";
import ListComponent from "./components/ListComponent";
import EditComponent from "./components/EditComponent";
import { PageHeader } from "antd";
import RecordPerPage from "../../../../../components/AgGridWrapper/RecordPerPage";

const PointTypeMaster: React.FC = observer(() => {
  const { POINT_TYPE } = useStore();
  const {
    setPageSize,
    perPage,
    details,
    setupGrid,
  } = POINT_TYPE;

  const [editModal, setEditModal] = useState<boolean>(false);

  
  const openEditModal: any = (data: any) => {
    details(data['id']).then(() => {
      //setEditValues(data?.user);
      setEditModal(true);
    })
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  return (
    <>
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          title={"Point Types"}
          extra={[
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
          <ListComponent
            setupGrid={setupGrid}
            openEditModal={openEditModal}
          />
          <EditComponent visible={editModal} close={closeEditModal}/>
        </ContentBox>
      </div>
    </>
  );
});

export default PointTypeMaster;
