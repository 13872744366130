import React from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import useStore from "../../../../store";
import ListComponent from "./components/ListComponent";
import { TemplateMasterBreadcrumb } from "../../../../config/BreadcrumbConfig";
import AllPermissionList from "../../../../utils/AllPermissionList";
import { PageHeader } from "antd";
import EditRoleComponent from "./components/EditComponent";

const TemplateMaster: React.FC = observer(() => {
	const { TEMPLATE, AUTH } = useStore();
	const {
		listData,
		fetchList,
		changeStatus,
		openEditTemplatePopup,
		closeEditTemplatePopup,
		editTemplatePopup,
	} = TEMPLATE;

	const { permissions} = AUTH;
	const editTemplate = (id: number) => {
		openEditTemplatePopup(id);
	}

	return (
			<>
				<BreadcrumbComponent items={TemplateMasterBreadcrumb.path} />
				<div className="mainPageWrap">
					<PageHeader
						className="mainPageHeader"
						title={TemplateMasterBreadcrumb.title}
					/>
					<ContentBox>
						<ListComponent
							rowData={listData}
							getData={fetchList}
							editTemplate={editTemplate}
							ChangeStatus={changeStatus}
							key="listrolecomponent"
							editPermission={permissions && permissions.indexOf(AllPermissionList.EDIT_TEMPLATE) >= 0}
						/>
						<EditRoleComponent
							visible={editTemplatePopup}
							closeEditModal={closeEditTemplatePopup}
							key="editrolekeycomponent"
						/>
					</ContentBox>
				</div>
			</>
	);
});

export default TemplateMaster;
