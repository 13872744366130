import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Col, Form, Modal, Row, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../../store";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { CampaignStatusMasterRequest } from "../Validation";

const ChangeCampaignStatusComponent: React.FC<any> = observer(({ visible, close }) => {
  const { CAMPAIGN, COMMON, ROOT } = useStore();
  const { campaignStatus } = COMMON;
  const { ChangeStatus, editValues } = CAMPAIGN;
  const { assignErrorToInput } = ROOT;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [reasonVisible, setReasonVisible] = useState<boolean>(false);
  const drawerClose = () => {
    form.resetFields();
    setReasonVisible(false);
    close();
  }  
  const changeStatus = (data: any) => {
    setSaving(true);
    ChangeStatus({ id: editValues.id, campaign_status: data.campaign_status, reason: data.reason })
      .then(() => {
        close();
        //        form.resetFields();
        return;
      }).catch((e: any) => {
        if (e.data) {
          assignErrorToInput(e?.data.errors);
        }
      })
      .finally(() => setSaving(false));
  };
  useEffect(() => {
    form.setFieldsValue({ campaign_status: editValues?.campaign_status });
  }, [visible])
  return editValues ? (
    <Modal
      centered
      title={editValues?.order_number ? "Change " + editValues?.order_number + " Status" : "Change Status"}
      open={visible}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      onCancel={drawerClose}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          htmlType="submit"
          type="primary"
          loading={saving}
          form="changeCampaignStatusForm"
          disabled={editValues?.campaign_status >= 4}
        >
          Save
        </Button>,
      ]}
    >
      <FormBox
        form={form}
        id={'changeCampaignStatusForm'}
        onFinish={changeStatus}
        initialValues={{ campaign_status: editValues?.campaign_status, reason: editValues?.reason }}
      >
        <Row gutter={15}>
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <InputBox.Select
              options={{
                list: campaignStatus,
                valueKey: "id",
                textKey: "value"
              }}
              onChange={(e) => {setReasonVisible(e>4)}}
              //mode={"multiple"}
              disabled={editValues?.campaign_status >= 4}
              name="campaign_status"
              placeholder="Campaign Status"
              label="Campaign Status"
              rules={CampaignStatusMasterRequest().campaign_status('Campaign Status')}
            />
          </Col>
          {reasonVisible ? <Col xs={{ span: 24 }} md={{ span: 24 }} >
            <InputBox.TextArea
              hidden={!reasonVisible}
              disabled={editValues?.campaign_status >= 4}
              name="reason"
              placeholder="Reason"
              label="Reason"
              rules={ CampaignStatusMasterRequest().campaign_status('Reason') }
            />
          </Col> : ''}
          {editValues?.status>4 ? <Col xs={{ span: 24 }} md={{ span: 24 }} >
            <Space>{editValues?.reason}</Space>
          </Col> : ''}
        </Row>
      </FormBox>
    </Modal>
  ) : (
    <></>
  );
});

export default ChangeCampaignStatusComponent;
