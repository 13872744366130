import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Form, Button, Drawer, Col, Row } from "antd";
import useStore from "../../../../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { CAMPAIGN_FEEDBACK } = useStore();
  const { editValues } = CAMPAIGN_FEEDBACK;
  const [form] = Form.useForm();

  const drawerClose = () => {
    close();
    form.resetFields();
  };

  useEffect(() => {

    form.setFieldsValue({
      name: editValues.name,
      campaign_name: editValues.campaign_name,
      email: editValues.email,
      service: editValues.service,
      link: editValues.link,
    });
  });

  return (
    <Drawer
      title={editValues?.campaign_name ? editValues.campaign_name + " Feedback Details" : "Feedback details"}
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose={true}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Close
        </Button>
      ]}
    >

      <FormBox
        form={form}
        id="editCampaignFeedbackForm"
        disabled={true}
      >
        <Row gutter={15}>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputBox.Text
              label="Name"
              placeholder="Name"
              name="name"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputBox.Text
              label="Email"
              placeholder="Email"
              name="email"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputBox.Text
              label="Campaign Name"
              placeholder="Campaign Name"
              name="campaign_name"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputBox.Text
              label="Service"
              placeholder="Service"
              name="service"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <InputBox.Text
              label="Link"
              placeholder="Link"
              name="link"
            />
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5 mt-5">
            <div className="ant-row ant-form-item-row">
              <div className="ant-col ant-form-item-label">
                <label title="Client Name">Status</label>
              </div>
              <b>{editValues?.status == 1 ? "Sent" : "Recieved"}</b>
            </div>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5 mt-5">
            <div className="ant-row ant-form-item-row">
              <div className="ant-col ant-form-item-label">
                <label title="Client Name">Sent At</label>
              </div>
              <b>{moment(editValues?.created_at).format('YYYY-MM-DD hh:mm A')}</b>
            </div>
          </Col>
          {editValues?.status == 2 ?
            <>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5 mt-5">
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Client Name">Recieved At</label>
                  </div>
                  <b>{moment(editValues?.recieved_at).format('YYYY-MM-DD hh:mm A')}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5 mt-5">
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Client Name">Feedback</label>
                  </div>
                  <b>{editValues?.rating}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['4 - Good', '5 - Excellent'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">Found our services extraordinary?</label>
                  </div>
                  <b>{editValues?.feedback['have-you-found-our-services-extraordinary'] ?? "No"}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['3 - Average'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">Campaign Performance Improvement From Last Month</label>
                  </div>
                  <b>{editValues?.feedback['champing-performance-3'] ?? "-"}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['3 - Average'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">SME / Lead Expertise</label>
                  </div>
                  <b>{editValues?.feedback['sme-lead-3'] ?? "-"}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['3 - Average'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">Communication And Timely Updates</label>
                  </div>
                  <b>{editValues?.feedback['communi-time-3'] ?? "-"}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['2 - Below Average'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">Campaign Performance Improvement From Last Month</label>
                  </div>
                  <b>{editValues?.feedback['camping-performance-2'] ?? "-"}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['2 - Below Average'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">SME / Lead Expertise</label>
                  </div>
                  <b>{editValues?.feedback['sme-lead-2'] ?? "-"}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['2 - Below Average'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">Communication And Timely Updates</label>
                  </div>
                  <b>{editValues?.feedback['communication-timely-2'] ?? "-"}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['1 - Poor'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">Campaign Performance Improvement From Last Month</label>
                  </div>
                  <b>{editValues?.feedback["champaign-performance-1"] ?? "-"}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['1 - Poor'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">SME / Lead Expertise</label>
                  </div>
                  <b>{editValues?.feedback['sme-lead-1'] ?? "-"}</b>
                </div>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5" hidden={['1 - Poor'].indexOf(editValues?.rating) < 0}>
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-label">
                    <label title="Found our services extraordinary?">Communication And Timely Updates</label>
                  </div>
                  <b>{editValues?.feedback['communication-timely-1'] ?? "-"}</b>
                </div>
              </Col>
            </>
            : null}
          <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5 mt-5">
            <div className="ant-row ant-form-item-row">
              <div className="ant-col ant-form-item-label">
                <label title="Client Name">Sent By</label>
              </div>
              <b>{editValues?.createdBy?.firstname + " " + editValues?.createdBy?.lastname}</b>
            </div>
          </Col>
        </Row>
      </FormBox>
    </Drawer>
  );
});

export default EditComponent;
