// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { UserMasterBreadcrumb } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import DeleteComponent from "./components/DeleteComponent";
import EditComponent from "./components/EditComponent";
import AssignRoleToUserComponent from "./components/AssignRoleToUserComponent";
import { PageHeader } from "antd";
import RecordPerPage from "../../../components/AgGridWrapper/RecordPerPage";
import UserRewardPointsComponent from "./components/UserRewardPointsComponent";

const UserMaster: React.FC = observer(() => {
  const { USER } = useStore();
  const {
    setPageSize,
    perPage,
    details,
    setupGrid,
    ChangeStatus,
    roleModelValues,
    setAssignRoleToUserModelValues,
    getRewardPoints,
    rewardPointsData,
    closeRewardPoints,
  } = USER;

  const [addModal, setAddModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [rewardPointsModal, setRewardPointsModal] = useState<boolean>(false);
  const [assignRoleToUserModel, setAssignRole] = useState<boolean>(false);
  const [rewardPointsUser, setRewardPointsUser] = useState<any>(null);

  const openAddModal = () => {
    setAddModal(true);
  };

  const closeAddModal = () => {
    setAddModal(false);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const openEditModal: any = (data: any) => {
    details(data['id']).then(() => {
      //setEditValues(data?.user);
      setEditModal(true);
    })
  };

  const closeEditModal = () => {
    setEditModal(false);
  };
  
  const openRewardPointsModal: any = (data: any) => {
    getRewardPoints(data['id']).then(() => {
      setRewardPointsUser(data);
      //setEditValues(data?.user);
      setRewardPointsModal(true);
    })
  };

  const closeRewardPointsModal = () => {
    closeRewardPoints();
    setRewardPointsModal(false);
  };

  const openRoleModel:any = (data:any) => {
    details(data['id']).then((resp:any) => {
      setAssignRole(true)
      setAssignRoleToUserModelValues(resp);
    })
  };

  const closeRoleModel = () => {
    setAssignRole(false);
    setAssignRoleToUserModelValues();
  };

  return (
    <>
      <BreadcrumbComponent items={UserMasterBreadcrumb.path} />
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          title={UserMasterBreadcrumb.title}
          extra={[
            <HeaderButtons key={1} open={openAddModal} />,
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
          <ListComponent
            setupGrid={setupGrid}
            ChangeStatus={ChangeStatus}
            openRoleModel={openRoleModel}
            openEditModal={openEditModal}
            openRewardPointsModal={openRewardPointsModal}
          />
          <AddComponent visible={addModal} close={closeAddModal} />
          <DeleteComponent visible={deleteModal} close={closeDeleteModal} />
          <EditComponent visible={editModal} close={closeEditModal}/>
          {assignRoleToUserModel && <AssignRoleToUserComponent visible={assignRoleToUserModel} close={closeRoleModel} roleModelValues={roleModelValues} />}
          <UserRewardPointsComponent rowData={rewardPointsData} visible={rewardPointsModal} close={closeRewardPointsModal} user={rewardPointsUser} getRewardPoints={getRewardPoints} />
        </ContentBox>
      </div>
    </>
  );
});

export default UserMaster;
