import { RequestProps } from "../../../../config/InterfacesAndTypes";
import Message from "../../../../config/Message";
import { maxName } from "../../../../config/Validators";



export const CampaignChecklistMasterRequest = (): RequestProps => ({
    title: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    service_type_id: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    campaign_type_id: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    type: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
});
