import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { CampaignMasterRequest } from "../Validation";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    handleSubmit,
    handleChange,
    editValues,
    type,
    serviceTypeList
  }) => {

    // set the form values to edit
    useEffect(() => {

      if (editValues && type === "edit") {
        form.setFieldsValue({
          name: editValues.name,
          service_type_id: editValues.service_type_id,
        });

        handleChange();
      }
    }, [editValues, form]);


    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Select
                options={{
                  list: serviceTypeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="service_type_id"
                placeholder="Service Type"
                label="Service Type"
                rules={CampaignMasterRequest().service_type_id('Service Type')}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <InputBox.Text
                required
                label="Name"
                placeholder="Name"
                name="name"
                rules={CampaignMasterRequest().name("Name")}
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
