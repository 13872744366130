import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import { faCommentAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../config/Config";
import useStore from "../../../../store";
import AllPermissionList from "../../../../utils/AllPermissionList";
import moment from "moment";
import DateComparator from "../../../../utils/DateComparator";
import { observer } from "mobx-react";

const ListComponent: React.FC<any> = observer(
	({ setupGrid, openEditModal, openDiscussionModal }) => {
		const { AUTH } = useStore();
		const ActionRenderer = (props: any) => {
			return (
				<div className="action-column">
					<Button
						type="text"
						title={"Edit"}
						className="editIcon"
						hidden={!AUTH.checkPermission(AllPermissionList.EDIT_QUOTE)}
						onClick={() => {
							openEditModal(props.data);
						}}
					>
						<FontAwesomeIcon icon={faPencilAlt} color={Config.blueBtn} />
					</Button>
					<Button
						type="text"
						title={"List Discussion"}
						className="editIcon"
						hidden={!AUTH.checkPermission(AllPermissionList.LIST_DISCUSSION)}
						onClick={() => {
							openDiscussionModal(props.data);
						}}
					>
						<FontAwesomeIcon icon={faCommentAlt} color={Config.greenBtn} />
					</Button>
				</div>
			);
		};

		return (
			<>
				<AgGridWrapper
					components={{ ActionRenderer }}
					onGridReady={setupGrid}
					type="serverSide"
					serverSideInfiniteScroll={true}
					suppressFieldDotNotation={true}
				>
					<AgGridColumn field="quote_number" headerName="#QUOTE ID" />
					<AgGridColumn field="client_name" headerName="Client Name" />
					<AgGridColumn field="shift_name" headerName="GEO" />
					<AgGridColumn field="campaign_name" headerName="Campaign Name" />
					<AgGridColumn field="service_type_name" headerName="Service Type" />
					<AgGridColumn field="capmaign_type" headerName="Campaign Type" />
					<AgGridColumn field="email" headerName="Email" />
					<AgGridColumn field="billing_email" headerName="Billing Email" />
					<AgGridColumn
						field="proposal_status"
						headerName="Proposal Status"
						pinned={"right"}
					/>
					<AgGridColumn
						field="quote_status"
						headerName="Status"
						pinned={"right"}
					/>
					<AgGridColumn field="manager_name" headerName="Manager" />
					<AgGridColumn field="nbd_name" headerName="NBD" />
					<AgGridColumn field="ba_name" headerName="BA" />
					<AgGridColumn
						field="account_manager_name"
						headerName="Account Manager"
					/>
					<AgGridColumn
						field="start_date"
						headerName="Start Date"
						valueGetter={({ data }) =>
							data["start_date"] &&
							moment(data["start_date"]).format(Config.dateFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn
						field="end_date"
						headerName="End Date"
						valueGetter={({ data }) =>
							data["end_date"] &&
							moment(data["end_date"]).format(Config.dateFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn
						field="tenure_start_date"
						headerName="Tenure Start Date"
						valueGetter={({ data }) =>
							data["tenure_start_date"] &&
							moment(data["tenure_start_date"]).format(Config.dateFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn
						field="tenure_end_date"
						headerName="Tenure End Date"
						valueGetter={({ data }) =>
							data["tenure_end_date"] &&
							moment(data["tenure_end_date"]).format(Config.dateFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn field="currency_name" headerName="Currency Name" />
					<AgGridColumn
						field="amount"
						headerName="Amount"
						filter={"agNumberColumnFilter"}
					/>
					<AgGridColumn
						field="discount"
						headerName="Discount(%)"
						filter={"agNumberColumnFilter"}
					/>
					<AgGridColumn
						field="final_amount"
						headerName="Final Amount"
						filter={"agNumberColumnFilter"}
					/>
					<AgGridColumn
						field="created_at"
						headerName="Created At"
						valueGetter={({ data }) =>
							data["created_at"] &&
							moment(data["created_at"]).format(Config.dateTimeFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn
						field="updated_at"
						headerName="Updated At"
						valueGetter={({ data }) =>
							data["updated_at"] &&
							moment(data["updated_at"]).format(Config.dateTimeFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn field="created_by_name" headerName="Created By" />
					<AgGridColumn field="updated_by_name" headerName="Last Modified By" />
					<AgGridColumn
						headerName="Actions"
						type="actionColumn"
						sortable={false}
						filter={false}
						width={120}
						minWidth={120}
						pinned="right"
					/>
				</AgGridWrapper>
			</>
		);
	}
);

export default ListComponent;
