// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { CampaignMasterBreadcrumb } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import ListComponent from "./components/ListComponent";
import { PageHeader } from "antd";
import RecordPerPage from "../../../components/AgGridWrapper/RecordPerPage";
import CampaignDiscussionComponent from "./components/CampaignDiscussionMaster/CampaignDiscussionComponent";
import ChangeCampaignStatusComponent from "./components/ChangeCampaignStatusComponent";

const CampaignMaster: React.FC = observer(() => {
  const { CAMPAIGN, CAMPAIGN_DISCUSSION } = useStore();
  const {
    setPageSize,
    perPage,
    details,
    setupGrid
  } = CAMPAIGN;

  const { setDiscussionQuote } = CAMPAIGN_DISCUSSION;
  const [discussionModal, setDiscussionModal] = useState<boolean>(false);
  const [changeCampaignStatusModal, setChangeCampaignStatusModal] = useState<boolean>(false);
  
  const openDiscussionModal: any = (data: any) => {
    details(data['id']).then((data: any) => {
      //setEditValues(data?.user);
      setDiscussionQuote(data);
      setDiscussionModal(true);
    })
  };

  const closeDiscussionModal = () => {
    setDiscussionModal(false);
  };


  const openChangeCampaignStatusModal = (data: any) => {
    details(data['id']).then(() => {
      setChangeCampaignStatusModal(true);
    })
  };


  const closeChangeCampaignStatusModal = () => {
    setChangeCampaignStatusModal(false);
  };

  return (
    <>
      <BreadcrumbComponent items={CampaignMasterBreadcrumb.path} />
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          title={CampaignMasterBreadcrumb.title}
          extra={[
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
          <ListComponent
            setupGrid={setupGrid}
            openDiscussionModal={openDiscussionModal}
            openChangeCampaignStatusModal={openChangeCampaignStatusModal}
          />
          <CampaignDiscussionComponent visible={discussionModal} close={closeDiscussionModal} />
          <ChangeCampaignStatusComponent visible={changeCampaignStatusModal} close={closeChangeCampaignStatusModal} />
        </ContentBox>
      </div>
    </>
  );
});

export default CampaignMaster;
