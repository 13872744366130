import { MailOutlined } from "@ant-design/icons";
import { Col, Layout, Row } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { TemplateValidation } from "../Validation";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import Config from "../../../../../config/Config";

interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	saving: boolean;
}


const FormComponent: React.FC<FormDataProps> = observer(({
	formId,
	form,
	handleSubmit,
}) => {

	return (
		<>
			<ContentBox>
				<FormBox
					id={formId}
					form={form}
					encType="multipart/formdata"
					onFinish={handleSubmit} key="formbasic">

					<Layout style={{ padding: '0 24px' }} className="ml-5 pb-5">
						<Row gutter={15}>
							<Col xs={{ span: 24 }} md={{ span: 24 }}>
								<InputBox.Text
									size="large"
									label="Title"
									placeholder="Title"
									name="title"
									readOnly={true}
									prefix={<MailOutlined />}
									rules={TemplateValidation().title('Title')} />
							</Col>
						</Row>
						<Row gutter={12} wrap={false}>
							<Col span={24} className="form__input__textbox">
								<div className="ant-col ant-form-item-label" >
									<label className="ant-form-item-required" style={{ color: Config.redBtn }}>Don{"'"}t Remove {"{{~ keyword~}}"} from Subject and Email Content. It will remove system generated text from email which you are sending.</label>
								</div>
							</Col>
						</Row>
						<Row gutter={15}>
							<Col xs={{ span: 24 }} md={{ span: 24 }}>
								<InputBox.Text
									size="large"
									label="Subject"
									placeholder="Subject"
									name="subject"
									rules={TemplateValidation().subject('Subject')} />
							</Col>
						</Row>
						<Row gutter={15}>
							<Col xs={{ span: 24 }} md={{ span: 24 }}>
								<InputBox.TextArea
									size="large"
									label="Email Content"
									placeholder="Email Content"
									name="content_email"
									rows={20}
									rules={TemplateValidation().content_email('Email Content')} />
							</Col>
						</Row>


					</Layout>


				</FormBox>
			</ContentBox>
		</>
	);
});
export default FormComponent;
