import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../../config/ApiUrl";
import AuthStore from "../../AuthStore/AuthStore";

export default class TemplateStore {
	public listData?: any[];
	public template?: any;
	public perPage = 1;//Config.grid.local.gridOptions?.paginationPageSize;
	public editTemplatePopup?: boolean = false;
	public editTemplateID?: number;
	
	private authStore:any;
	constructor() {
		makeAutoObservable(this);
		this.authStore = new AuthStore();
		
	}
	// Setter Functions
	public openEditTemplatePopup = (id: number): void => {
		this.editTemplatePopup = true;
		this.setEditTemplateID(id);
	};
	public closeEditTemplatePopup = (): void => {
		this.editTemplatePopup = false;
		this.setEditTemplateID();
	};

	private setEditTemplateID = (id?: number): void => {
		this.editTemplateID = id;
	};

	private setListData = (value?: any[]): void => {
		this.listData = value;
	};


	public setTemplate = (value?: any): void => {
		this.template = value;
	};

	// API Functions
	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.perPage) => {
		this.perPage = page;
		this.fetchList()
	};
	
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.TEMPLATE.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data;
		});
	};

	public update = (id:number, payload: any): Promise<any> => {
		return axios
			.post(API_URL.TEMPLATE.UPDATE(id), payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.TEMPLATE.DETAILS(id)).then(({ data }) => {
			this.setTemplate(data.data);
			return data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.TEMPLATE.CHANGE_STATUS(payload.id),{status:payload.status})
		.then(({ data }) => {
			this.fetchList();
			return data;
		});
	};
}
