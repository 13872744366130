import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Drawer, Form, Button } from "antd";
import useStore from "../../../../store";
import FormComponent from "./FormComponent";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { USER, ROOT, COMMON } = useStore();
  const { EditData, editValues } = USER;
  const { assignErrorToInput } = ROOT;
  const { roleList, fetchRoles, shiftList, departmentList, reportingManagerList, fetchShift, fetchDepartment, fetchReportingManager } = COMMON;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [hideReportingManager, setHideReportingManager] = useState<boolean>(false);

  useEffect(() => {
    fetchRoles();
    fetchShift();
    fetchDepartment();
    fetchReportingManager(editValues?.roleId[0] ?? null);
  }, [visible])
  const drawerClose = () => {
    close();
    form.resetFields();
  };

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formData = new FormData();
        for (const name in data) {
          if (name == 'date_of_joining') {
            formData.append(name, moment(data[name]).format("YYYY-MM-DD"));
          } else if (name == 'role_id') {
            //data[name].forEach((element:any) => {
            formData.append('role_id[]', data[name])
            //});
          } else {
            formData.append(name, data[name]); // there should be values.avatar which is a File object
          }
        }

        EditData(formData, editValues.id)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  const handleChange = debounce(() => {
    setHideReportingManager(editValues?.roleId == 1);
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title={editValues?.firstname ? "Edit " + editValues.firstname + " " + editValues.lastname : "Edit User"}
      placement="right"
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="editUserForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormComponent
        form={form}
        id="editUserForm"
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        editValues={editValues}
        type="edit"
        roleList={roleList}
        shiftList={shiftList}
        departmentList={departmentList}
        reportingManagerList={reportingManagerList}
        hideReportingManager={hideReportingManager}
        setHideReportingManager={setHideReportingManager}
        fetchReportingManager={fetchReportingManager}
      />
    </Drawer>
  );
});

export default EditComponent;
