import { RequestProps } from "../../../../../config/InterfacesAndTypes";
import Message from "../../../../../config/Message";
import { maxDescription, maxName } from "../../../../../config/Validators";




export const CampaignChecklistRequest = (): RequestProps => ({
    comment: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxDescription, message: Message().max.string(maxName, field) },
    ],
    status: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
});
