import React from "react";
import { useRoutes } from "react-router-dom";
import AuthLayout from "../views/layouts/AuthLayout";
import MainLayout from "../views/layouts/MainLayout";
import Dashboard from "../views/app_pages/Dashboard";
import PageNotFound from "../views/app_pages/errors/PageNotFound";
import UserMaster from "../views/app_pages/UserMaster";
import RoleMaster from "../views/app_pages/RoleMaster";
import Login from "../views/app_pages/auth/Login";
import ForgetPassword from "../views/app_pages/auth/ForgetPassword";
import ResetPasswordOtp from "../views/app_pages/auth/ResetPasswordOtp";
import TemplateMaster from "../views/app_pages/MasterModule/TemplateMaster";
import ClientMaster from "../views/app_pages/ClientMaster";
import ServiceTypeAndCampaignMaster from "../views/app_pages/MasterModule/ServiceTypeAndCampaignMaster";
import CurrencyMaster from "../views/app_pages/MasterModule/CurrencyMaster";
import DiscussionTypeMaster from "../views/app_pages/MasterModule/DiscussionTypeMaster";
import QuoteMaster from "../views/app_pages/QuoteMaster";
import CampaignMaster from "../views/app_pages/CampaignMaster";
import CampaignDetailsComponent from "../views/app_pages/CampaignMaster/components/CampaignDetailsComponent";
import ChecklistMaster from "../views/app_pages/MasterModule/ChecklistMaster";
import ShiftGeoMaster from "../views/app_pages/MasterModule/ShiftGeoMaster";
import PointSystemMaster from "../views/app_pages/MasterModule/PointSystemMaster";
import EscalationTypeMaster from "../views/app_pages/MasterModule/EscalationTypeMaster";
import TestimonialMaster from "../views/app_pages/TestimonialMaster";
import EscalationMaster from "../views/app_pages/EscalationMaster";
import PausedCampaignMaster from "../views/app_pages/BusinessAnalysisMaster/CampaignMaster/PausedCamapign";
import LostCampaignMaster from "../views/app_pages/BusinessAnalysisMaster/CampaignMaster/LostCampaign";
import ExtraPointMaster from "../views/app_pages/ExtraPointMaster";
import ClientKPIMaster from "../views/app_pages/BusinessAnalysisMaster/ClientKPIMaster";
import MyRewardPointMaster from "../views/app_pages/MyProfile/MyRewardPointsMaster";
//import WorkModelMaster from "../views/app_pages/WorkModelMaster";

export default function Router() {
  const element = useRoutes(RouterConfig);
  return element;
}


export const RouterConfig = [
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgetPassword />,
      },
      {
        path: "/reset/:token",
        element: <ResetPasswordOtp />,
      },
      // {
      //   path: "/unauthorized",
      //   element: <Unauthorized />,
      // },
    ],
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/user-management",
        element: <UserMaster />,
      },
      {
        path: "/my-reward-points",
        element: <MyRewardPointMaster />,
      },
      {
        path: "/role-management",
        element: <RoleMaster />,
      },
      {
        path: "/template-management",
        element: <TemplateMaster />,
      },
      {
        path: "/client-management",
        element: <ClientMaster />,
      },
      {
        path: "/service-type-campaign-management",
        element: <ServiceTypeAndCampaignMaster />,
      },
      {
        path: "/currency-management",
        element: <CurrencyMaster />,
      },
      {
        path: "/checklist-management",
        element: <ChecklistMaster />,
      },
      {
        path: "/discussion-type-management",
        element: <DiscussionTypeMaster />,
      },
      {
        path: "/quote-management",
        element: <QuoteMaster />,
      },
      {
        path: "/campaign-management",
        element: <CampaignMaster />,
      },
      {
        path: "/business-analysis/paused-campaigns",
        element: <PausedCampaignMaster />,
      },
      {
        path: "/business-analysis/lost-campaigns",
        element: <LostCampaignMaster />,
      },
      {
        path: "/business-analysis/client-kpi-management",
        element: <ClientKPIMaster />,
      },
			{
        path: "/escalation-management",
        element: <EscalationMaster />,
      },
      {
        path: "campaign-management/details/:id",
        element: <CampaignDetailsComponent />,
      },
      {
        path: "shift-geo-management",
        element: <ShiftGeoMaster />,
      },
			{
        path: "escalation-type-management",
        element: <EscalationTypeMaster />,
      },
      {
        path: "point-system-management",
        element: <PointSystemMaster />,
      },
      {
        path: "testimonial-management",
        element: <TestimonialMaster />,
      },
      {
        path: "extra-point-management",
        element: <ExtraPointMaster />,
      },
      // {
      //   path: "/work-model-management",
      //   element: <WorkModelMaster />,
      // },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];
