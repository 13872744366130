import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import {
	faCalendarCheck,
	faCircleInfo,
	faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../config/Config";
import useStore from "../../../../store";
import AllPermissionList from "../../../../utils/AllPermissionList";
import moment from "moment";
import DateComparator from "../../../../utils/DateComparator";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

const ListComponent: React.FC<any> = observer(
	({ setupGrid, openDiscussionModal, openChangeCampaignStatusModal }) => {
		const { AUTH } = useStore();
		const ActionRenderer = (props: any) => {
			return (
				<div className="action-column">
					<Link
						type="text"
						title={"Details"}
						className="editIcon"
						to={`details/${props.data.id}`}
						hidden={!AUTH.checkPermission(AllPermissionList.EDIT_CAMPAIGN)}
					>
						<FontAwesomeIcon icon={faCircleInfo} color={Config.blueBtn} />
					</Link>
					<Button
						type="text"
						title={"List Discussion"}
						className="editIcon"
						hidden={
							!AUTH.checkPermission(AllPermissionList.LIST_CAMPAIGN_DISCUSSION)
						}
						onClick={() => {
							openDiscussionModal(props.data);
						}}
					>
						<FontAwesomeIcon icon={faCommentAlt} color={Config.greenBtn} />
					</Button>
					<Button
						type="text"
						title={"Change Status"}
						className="deleteIcon"
						hidden={
							!AUTH.checkPermission(AllPermissionList.CHANGE_CAMPAIGN_STATUS) ||
							props?.data?.status >= 4
						}
						onClick={() => {
							openChangeCampaignStatusModal(props.data);
						}}
					>
						<FontAwesomeIcon icon={faCalendarCheck} color={Config.orangeBtn} />
					</Button>
				</div>
			);
		};

		return (
			<>
				<AgGridWrapper
					components={{ ActionRenderer }}
					onGridReady={setupGrid}
					type="serverSide"
					serverSideInfiniteScroll={true}
					suppressFieldDotNotation={true}
				>
					<AgGridColumn
						field="order_number"
						headerName="#CAMPAIGN ID"
						filter={"agNumberColumnFilter"}
					/>
					<AgGridColumn field="client_name" headerName="Client Name" />
					<AgGridColumn field="shift_name" headerName="GEO" />
					<AgGridColumn field="campaign_name" headerName="Campaign Name" />
					<AgGridColumn field="service_type_name" headerName="Service Type" />
					<AgGridColumn field="capmaign_type" headerName="Campaign Type" />
					<AgGridColumn field="email" headerName="Email" />
					<AgGridColumn field="billing_email" headerName="Billing Email" />
					<AgGridColumn field="manager_name" headerName="Manager" />
					<AgGridColumn
						field="account_manager_name"
						headerName="Account Manager"
					/>
					<AgGridColumn field="team_lead_name" headerName="Team Lead" />
					<AgGridColumn field="sme_name" headerName="SME" />
					<AgGridColumn
						field="start_date"
						headerName="Start Date"
						valueGetter={({ data }) =>
							data["start_date"] &&
							moment(data["start_date"]).format(Config.dateFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn
						field="end_date"
						headerName="End Date"
						valueGetter={({ data }) =>
							data["end_date"] &&
							moment(data["end_date"]).format(Config.dateFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn
						field="tenure_start_date"
						headerName="Tenure Start Date"
						valueGetter={({ data }) =>
							data["tenure_start_date"] &&
							moment(data["tenure_start_date"]).format(Config.dateFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn
						field="tenure_end_date"
						headerName="Tenure End Date"
						valueGetter={({ data }) =>
							data["tenure_end_date"] &&
							moment(data["tenure_end_date"]).format(Config.dateFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn field="currency_name" headerName="Currency Name" />
					<AgGridColumn
						field="amount"
						headerName="Amount"
						filter={"agNumberColumnFilter"}
					/>
					<AgGridColumn
						field="discount"
						headerName="Discount(%)"
						filter={"agNumberColumnFilter"}
					/>
					<AgGridColumn
						field="final_amount"
						headerName="Final Amount"
						filter={"agNumberColumnFilter"}
					/>
					<AgGridColumn
						field="campaign_status"
						headerName="Status"
						pinned={"right"}
					/>
					<AgGridColumn
						field="created_at"
						headerName="Created At"
						valueGetter={({ data }) =>
							data["created_at"] &&
							moment(data["created_at"]).format(Config.dateTimeFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn
						field="updated_at"
						headerName="Updated At"
						valueGetter={({ data }) =>
							data["updated_at"] &&
							moment(data["updated_at"]).format(Config.dateTimeFormat)
						}
						filter={"agDateColumnFilter"}
						filterParams={{
							comparator: DateComparator,
						}}
					/>
					<AgGridColumn field="created_by_name" headerName="Created By" />
					<AgGridColumn field="updated_by_name" headerName="Last Modified By" />
					<AgGridColumn
						headerName="Actions"
						type="actionColumn"
						sortable={false}
						filter={false}
						width={120}
						minWidth={120}
						pinned="right"
					/>
				</AgGridWrapper>
			</>
		);
	}
);

export default ListComponent;
