import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Drawer } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { ConversionRateRequest } from "../Validation";
import useStore from "../../../../../store";

const ConversionRateComponent: React.FC<any> = observer(({ visible, close }) => {
  const { CURRENCY, COMMON, ROOT } = useStore();
  const { UpdateConversionRate } = CURRENCY;
  const { fetchCurrency } = COMMON;
  const { assignErrorToInput } = ROOT;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    fetchCurrency().then((data:any) => {
        const currencyDetails:any = [];
        data?.forEach((element:any) => {
            currencyDetails.push({
              id:element?.id,
              name:element?.name,
              symbol:element?.symbol,
              conversion_rate:element?.conversion_rate
            })
          });
          form.setFieldsValue({
            currency: currencyDetails,
          });
    });
  }, [visible]);
  const drawerClose = () => {
    form.resetFields();
    close();
  };

  const handleSubmit = (data: any) => {
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        

        UpdateConversionRate(data)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      })
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
       // setDisabled(false);
      })
      .catch(() => {
       // setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title="Update Currency Conversion Rate"
      width={"70%"}
      open={visible}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="updateCurrencyConversionRateForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormBox
          form={form}
          id='updateCurrencyConversionRateForm'
          onFinish={handleSubmit}
          onChange={() => { handleChange() }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
            <Form.List name="currency">
              {(fields) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row key={key} >
                      <Col xs={{ span: 24 }} md={{ span: 5 }} className="ml-10">
                        <Form.Item
                          {...restField}
                          name={[name, 'name']}
                          
                        >
                          <InputBox.Text
                            //mode={"multiple"}
                            placeholder="Currency Name"
                            label="Currency Name"
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 13 }} className="ml-5 mr-5">
                        <Form.Item
                          {...restField}
                          name={[name, 'conversion_rate']}
                          rules={ConversionRateRequest().conversion_rate('Conversion Rate')}
                        >
                          <InputBox.Text label="Currency Conversion Rate in USD $"  placeholder="Currency Conversion Rate  in USD $" />
                        </Form.Item>
                      </Col>
                     
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
              </Col>
           </Row>
        </FormBox>
    </Drawer>
  );
});

export default ConversionRateComponent;
