import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { Button, Col, Row } from "antd";
import { CampaignMasterRequest } from "../../Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useStore from "../../../../../store";
import AllPermissionList from "../../../../../utils/AllPermissionList";

const TeamFormComponent: React.FC<any> = observer(
    ({
        form,
        handleChange,
        editValues,
        serviceTypeList,
        campaignList,
        currencyList,
        //fetchCampaign,
        accountManagerList,
        packageMasterList,
        boosterTireList,
        tlList,
        smeList,
        seniorList,
        executorList,
        zoneList,
        handleSubmit,
        loading,
        saving,
        fetchPackageMasterDropdownData,
    }) => {
        const [finalamount, setfinalamount] = useState(0);
        const [currencySymbol, setCurrencySymbol] = useState('');
        const navigate = useNavigate();
        const { AUTH } = useStore();
        const [emailList, setEmailList] = useState([]);
        const [billingEmailList, setBillingEmailList] = useState([]);

        useEffect(() => {
            const emailData = editValues?.client?.email?.split(', ')?.map((email: string) => { return { id: email, value: email } }) ?? []
            const billingEmailData = editValues?.client?.billing_email?.split(', ')?.map((email: string) => { return { id: email, value: email } }) ?? []
            editValues && emailData?.filter((ele: any) => ele.value == editValues.email).length < 1 && emailData.push({ id: editValues.email, value: editValues.email });
            editValues && editValues?.billing_email && billingEmailData?.filter((ele: any) => ele.value == editValues.billing_email) < 1 && billingEmailData.push({ id: editValues.billing_email, value: editValues.billing_email });
            form.resetFields(['billing_email', "email"]);
            setEmailList(emailData);
            setBillingEmailList(billingEmailData);
            form.setFieldsValue({
                account_manager_id: editValues?.account_manager_id,
                amount: editValues?.amount,
                ba_id: editValues?.ba_id,
                billing_email: editValues?.billing_email,
                booster_tire_master_id: editValues?.booster_tire_master_id,
                campaign_name: editValues?.campaign_name,
                campaign_type_id: campaignList?.filter((element: any) => { return element.id == editValues?.campaign_type_id }).length > 0 ? editValues.campaign_type_id : null,
                client_id: editValues?.client_id,
                client_type_id: editValues?.client_type_id,
                company_name: editValues?.company_name,
                currency_id: editValues?.currency_id,
                discount: editValues?.discount,
                email: editValues?.email,
                executor_id: editValues?.executor_id,
                final_amount: editValues?.final_amount,
                nbd_id: editValues?.nbd_id,
                notes: editValues?.notes,
                package_master_id: editValues?.package_master_id,
                phone_number: editValues?.phone_number,
                questionnaire_status: editValues?.questionnaire_status,
                senior_id: editValues?.senior_id,
                service_document_link: editValues?.service_document_link,
                service_type_id: serviceTypeList?.filter((element: any) => { return element.id == editValues?.service_type_id }).length > 0 ? editValues.service_type_id : null,
                shift_id: editValues?.shift_id,
                sme_id: editValues?.sme_id,
                tl_id: editValues?.tl_id,
                zone_id: editValues?.zone_id,
            });

            if (editValues?.start_date) {
                form.setFieldsValue({
                    start_end_date: editValues.start_date ? [moment(editValues.start_date), moment(editValues.end_date)] : [null, null],
                });
            }

            if (editValues?.tenure_start_date) {
                form.setFieldsValue({
                    tenure_start_end_date: editValues.tenure_start_date ? [moment(editValues.tenure_start_date), moment(editValues.tenure_end_date)] : [null, null],
                });
            }
            fetchPackageMasterDropdownData(editValues?.campaign_type_id);
            //fetchCampaign(editValues?.service_type_id, editValues?.campaign_type_id ?? null);
            currencyList?.length && setCurrencySymbol(currencyList?.filter((ele: any) => { return ele.id == editValues?.currency_id })[0]?.symbol ?? '');
            setFinalAmount();
            handleChange();

        }, [loading])

        const setFinalAmount = () => {
            setfinalamount(form?.getFieldsValue().amount > 0 && form?.getFieldsValue().discount > 0 ? form?.getFieldsValue().amount * (100 - form?.getFieldsValue().discount ?? 0) / 100 : form?.getFieldsValue().amount);
        }


        return (
            <>
                <FormBox
                    form={form}
                    id={"editCampaignDetails"}
                    disabled={editValues?.campaign_status >= 4}
                    onChange={() => { handleChange(); }}
                >
                    <Row gutter={15}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5">
                            <div className="ant-row ant-form-item-row">
                                <div className="ant-col ant-form-item-label">
                                    <label title="Client Name">Client Name</label>
                                </div>
                                <b>{editValues?.client?.firstname + " " + editValues?.client?.lastname}</b>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-5">
                            <div className="ant-row ant-form-item-row">
                                <div className="ant-col ant-form-item-label">
                                    <label title="Campaign Name">Campaign Name</label>
                                </div>
                                <b>{editValues?.campaign_name}</b>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-20">
                            <div className="ant-row ant-form-item-row">
                                <div className="ant-col ant-form-item-label">
                                    <label title="Service Type">Service Type</label>
                                </div>
                                <b>{editValues?.serviceType?.name}</b>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="mb-20">
                            <div className="ant-row ant-form-item-row">
                                <div className="ant-col ant-form-item-label">
                                    <label title="Campaign Type">Campaign Type</label>
                                </div>
                                <b>{editValues?.campaignType?.name}</b>
                            </div>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: emailList,
                                    valueKey: "id",
                                    textKey: "value"
                                }}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="email"
                                placeholder="Select Email"
                                label="Select Email"
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: billingEmailList,
                                    valueKey: "id",
                                    textKey: "value"
                                }}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="billing_email"
                                placeholder="Select Billing Email"
                                label="Select Billing Email"
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} >
                            <InputBox.Text

                                name="service_document_link"
                                placeholder="Service Document Link"
                                label="Service Document Link"
                                rules={CampaignMasterRequest().service_document_link('Service Document Link')}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} className="mt-5">
                            <InputBox.Select
                                options={{
                                    list: accountManagerList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="account_manager_id"
                                placeholder="Account Manager"
                                label="Account Manager"
                                rules={CampaignMasterRequest().account_manager_id('Account Manager')}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: packageMasterList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                showSearch={true}
                                allowClear={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="package_master_id"
                                placeholder="Package Number"
                                label="Package Number"
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: boosterTireList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear={true}
                                name="booster_tire_master_id"
                                placeholder="Booster Tire"
                                label="Booster Tire"
                            />
                        </Col>

                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: tlList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="tl_id"
                                placeholder="Team Lead"
                                label="Team Lead"
                                rules={CampaignMasterRequest().tl_id('Team Lead')}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: smeList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="sme_id"
                                placeholder="SME"
                                label="SME"
                                rules={CampaignMasterRequest().tl_id('SME')}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: seniorList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                showSearch={true}
                                mode={"multiple"}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="senior_id"
                                placeholder="Senior"
                                label="Senior"
                                rules={CampaignMasterRequest().tl_id('Senior')}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: executorList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                mode={"multiple"}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="executor_id"
                                placeholder="Executor"
                                label="Executor"
                                rules={CampaignMasterRequest().tl_id('Executor')}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: zoneList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                allowClear={false}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="zone_id"
                                placeholder="Zone"
                                label="Zone"
                                rules={CampaignMasterRequest().tl_id('Zone')}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.DateRangePicker
                                label="Start Date - End Date"
                                name={"start_end_date"}
                                rules={CampaignMasterRequest().start_end_date("Start and end date")}
                                suffixIcon={<FontAwesomeIcon icon={faCalendar} />} />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <InputBox.DateRangePicker
                                label="Tenure Start Date - End Date"
                                name={"tenure_start_end_date"}
                                rules={CampaignMasterRequest().start_end_date("Tenure Start and end date")}
                                suffixIcon={<FontAwesomeIcon icon={faCalendar} />} />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Select
                                options={{
                                    list: currencyList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                showSearch={true}
                                filterOption={(input, option: any) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                name="currency_id"
                                placeholder="Currency"
                                label="Currency"
                                onChange={(e) => {
                                    setCurrencySymbol(currencyList.filter((ele: any) => { return ele.id == e })[0]?.symbol ?? '');
                                }}
                                rules={CampaignMasterRequest().currency_id('Currency')}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Number
                                required
                                label="Amount"
                                placeholder="Amount"
                                name="amount"
                                min={1}
                                onChange={() => { setFinalAmount() }}
                                rules={CampaignMasterRequest().amount("Amount")}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <InputBox.Number
                                required
                                size="large"
                                label="Discount %"
                                placeholder="Discount %"
                                name="discount"
                                onChange={() => { setFinalAmount() }}
                                rules={CampaignMasterRequest().discount("Discount %")}
                            />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }} >

                            <div className="ant-col ant-form-item-label"> <label className="ant-form-item-required"> Final Amount</label></div>
                            <br />
                            <b style={{ padding: '10px' }}>{currencySymbol} {finalamount}</b>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <InputBox.TextArea
                                placeholder="Notes"
                                label="Notes"
                                name={"notes"}
                            />
                        </Col>
                    </Row>
                </FormBox>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Button key="2" htmlType="button" className="cancelBtn" onClick={() => { navigate('/campaign-management') }}>
                        Back
                    </Button>
                    <Button
                        key="1"
                        hidden={!AUTH.checkPermission(AllPermissionList.EDIT_TEAM_DETAILS) || editValues?.campaign_status >= 4}
                        form="editCampaignForm"
                        loading={loading || saving}
                        className="ml-5"
                        htmlType="submit"
                        type="primary"
                        onClick={async () => {
                            handleSubmit(form.getFieldsValue());
                        }}
                        disabled={loading || saving}
                    >
                        Save
                    </Button>
                </Col>

            </>
        )
    });
export default TeamFormComponent;