import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from "antd";
import useStore from "../../../../../store";
import { debounce } from "lodash";
import moment from "moment";
import TeamFormComponent from "./TeamFormComponent";

const TeamDetails: React.FC<any> = observer(({ id }) => {
    const { CAMPAIGN, COMMON, ROOT } = useStore();
    const { assignErrorToInput } = ROOT;
    const { clientList, serviceTypeList, campaignList, currencyList, packageMasterList,
        fetchCampaign, fetchQuoteDropdownData, fetchPackageMasterDropdownData } = COMMON;
    const { editValues, editCampaignDetails } = CAMPAIGN;
    const [form] = Form.useForm();
    const [nbdList, setNbdList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [baList, setBaList] = useState<any>([]);
    const [accountManagerList, setAccountManagerList] = useState<any>([]);
    const [boosterTireList, setBoosterTireList] = useState<any>([]);
    const [tlList, setTlList] = useState<any>([]);
    const [smeList, setSmeList] = useState<any>([]);
    const [seniorList, setSeniorList] = useState<any>([]);
    const [executorList, setExecutorList] = useState<any>([]);
    const [zoneList, setZoneList] = useState<any>([]);
    const [saving, setSaving] =  useState<boolean>(false);
    useEffect(() => {
        setLoading(true);
        fetchQuoteDropdownData(id).then((data: any) => {
            setNbdList(data?.bdeList);
            setAccountManagerList(data?.amList);
            setBaList(data?.baList);
            setTlList(data?.tlList);
            setSmeList(data?.smeList);
            setSeniorList(data?.seniorList);
            setExecutorList(data?.executorList);
            setZoneList(data?.zoneList);
            setBoosterTireList(data?.boosterTireList);
            setLoading(false);
        }).catch(() => {
            setNbdList([]);
            setBaList([]);
            setAccountManagerList([]);
            setSmeList([]);
            setSeniorList([]);
            setExecutorList([]);
            setZoneList([]);
            setLoading(false);
        });
    }, [id])



    const handleSubmit = (data: any) => {
        form
            .validateFields()
            .then(() => {
                setSaving(true);
                const formData = new FormData();
                for (const name in data) {
                    if (name == 'senior_id') {
                        data[name] != undefined && data[name].forEach((element: any) => {
                            formData.append('senior_id[]', element);
                        });
                    } else if (name == 'executor_id') {
                        data[name] != undefined && data[name].forEach((element: any) => {
                            formData.append('executor_id[]', element);
                        });
                    } else if (name == 'start_end_date') {
                        data[name] != null && formData.append('start_date', moment(data[name][0]).format("YYYY-MM-DD"));
                        data[name] != null && formData.append('end_date', moment(data[name][1]).format("YYYY-MM-DD"));
                    } else if (name == 'tenure_start_end_date') {
                        data[name] != null && formData.append('tenure_start_date', moment(data[name][0]).format("YYYY-MM-DD"));
                        data[name] != null && formData.append('tenure_end_date', moment(data[name][1]).format("YYYY-MM-DD"));
                    } else {
                        data[name] != undefined && formData.append(name, data[name]); // there should be values.avatar which is a File object
                    }
                }
                editCampaignDetails(formData, editValues.id)
                    .then(() => {
                        setSaving(false);
                        //navigate('/campaign-management');
                    })
                    .catch((e: any) => {
                        if (e.data) {
                            assignErrorToInput(form, e?.data.errors);
                        }
                    })
                    .finally(() => setSaving(false));
            });
    };

    const handleChange = debounce(() => {
        form
            .validateFields()
            .then(() => {
                //setDisabled(false);
            })
            .catch(() => {
                //setDisabled(true);
            });
    }, 500);

    return (
        <>
            <TeamFormComponent
                form={form}
                id="editCampaignDetails"
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                editValues={editValues}
                type="edit"
                clientList={clientList}
                serviceTypeList={serviceTypeList}
                campaignList={campaignList}
                currencyList={currencyList}
                fetchCampaign={fetchCampaign}
                nbdList={nbdList}
                baList={baList}
                accountManagerList={accountManagerList}
                tlList={tlList}
                smeList={smeList}
                seniorList={seniorList}
                executorList={executorList}
                zoneList={zoneList}
                loading={loading}
                packageMasterList={packageMasterList}
                boosterTireList={boosterTireList}
                saving={saving}
                fetchPackageMasterDropdownData={fetchPackageMasterDropdownData}
            />
        </>
    )
});

export default TeamDetails;
