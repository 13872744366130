// import { PageHeader } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import useStore from "../../../../../store";
import EditComponent from "./components/EditComponent";
import { PageHeader } from "antd";
import RecordPerPage from "../../../../../components/AgGridWrapper/RecordPerPage";
import ListComponent from "./components/ListComponent";
import ChangeStatusComponent from "./components/ChangeStatusComponent";


const CampaignChecklistMaster: React.FC<any> = observer(({ id }) => {
  const { CAMPAIGN_CHECKLIST } = useStore();
  const {
    setPageSize,
    perPage,
    details,
    setupGrid,
    setSelectedCampaign,
//    ChangeStatus,
  } = CAMPAIGN_CHECKLIST;

  const [editModal, setEditModal] = useState<boolean>(false);
  const [changeStatusModal, setChangeStatusModal] = useState<boolean>(false);


  useEffect(() => {
    setSelectedCampaign(id);
  }, [id])
  
  const openEditModal: any = (data: any) => {
    details({campaign_id:id, campaign_checklist_master_id:data['campaign_checklist_master_id']}).then(() => {
      setEditModal(true);
    })
  };

  const closeEditModal = () => {
    setEditModal(false);
  };
  const openStatusModal: any = (data: any) => {
    details({campaign_id:id, campaign_checklist_master_id:data['campaign_checklist_master_id']}).then(() => {
      setChangeStatusModal(true);
    })
  };

  const closeStatusModal = () => {
    setChangeStatusModal(false);
  };

  return (
    <>
      <div className="mainPageWrap">
        <PageHeader
          className="mainPageHeader"
          extra={[
            <RecordPerPage
              key={2}
              style={{ width: "180px" }}
              defaultValue={perPage + " per page"}
              onChange={setPageSize}
            />,
          ]}
        />
        <ContentBox>
          <ListComponent
            setupGrid={setupGrid}
            openEditModal={openEditModal}
            openStatusModal={openStatusModal}
            selectedCampaignId={id}
          />
          {editModal && <EditComponent visible={editModal} close={closeEditModal} id={id}/>}
          {changeStatusModal && <ChangeStatusComponent visible={changeStatusModal} close={closeStatusModal} id={id}/>}
        </ContentBox>
      </div>
    </>
  );
});

export default CampaignChecklistMaster;
