import { Button, Checkbox, Drawer } from "antd";
import React from "react";

interface PermissionProps {
	permissionList: any[];
	selectedPermission: any[];
	onCheckChange: (data: any) => void;
	visible?: boolean;
	title: string;
	closePermissionModel: () => void;
	disabled?: boolean;
}

const PermissionComponent: React.FC<PermissionProps> = ({
	permissionList,
	selectedPermission,
	onCheckChange,
	visible,
	title,
	closePermissionModel,
	disabled
}) => {

	const close = () => {
		closePermissionModel()
	}
	return (
		<Drawer
			placement="right"
			title={title}
			open={visible}
			destroyOnClose={true}
			onClose={close}
			width={1000}
			key="rolepermissiondetailsmodel"
			footer={[
				<Button key="okbutton1" type="primary" onClick={close}>
					Ok
				</Button>,
			]}
		>
			<div className="permission__table">
				<table key="table">
					<thead key="thead">
						<tr key="headtr">
							<th key="modules">
								Modules
							</th>
							<th colSpan={5} key="permissions">
								Permissions
							</th>
						</tr>
					</thead>
					<tbody key={"tbody"+ Math.random().toString()}>
						{permissionList?.map((item: any) => {
							return (
								<tr key={"tr__" + item?.id + Math.random().toString()}>
									<td key={"td__" + Math.random().toString()} className="font-bold">{item.name}</td>
									<td key={"td__" + Math.random().toString()}>
										{item.permissions.map((permission: any) => (
											<>
												<div className="ant-col-12" key={"div__" + Math.random().toString()} >
													<Checkbox onChange={onCheckChange}
														name={"permission"}
														disabled={disabled}
														checked={(selectedPermission.indexOf(permission.id) >= 0) ? true : false}
														key={"checkbvalue" + permission.id.toString() + Math.random().toString()}
														defaultChecked={(selectedPermission.indexOf(permission.id) >= 0) ? true : false} /> {permission.permission_name}&nbsp;
												</div>
											</>
										))}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</Drawer>
	);
};

export default PermissionComponent;
