import { RequestProps } from "../../../config/InterfacesAndTypes";
import Message from "../../../config/Message";
import { maxName,Regex } from "../../../config/Validators";

export const CampaignMasterRequest = (): any => ({
    service_type_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    campaign_type_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    currency_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    service_document_link: (field?: string) => [{ max: maxName, message: Message().max.string(maxName, field) },],
    account_manager_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    tl_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    zone_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    sme_id: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    start_end_date: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    tenure_start_end_date: (field?: string) => [{ required: true, message: Message().required.select(field) }],
    amount: (field?: string) => [{ required: true, message: Message().required.text(field) }],
    discount: (field?: string) => [
        { pattern: Regex.percentage, message: Message().regex.other(field) },
    ],
});

export const CampaignStatusMasterRequest = (): RequestProps => ({   
    campaign_status: (field?: string) => [
        { required: true, message: Message().required.select(field) },
    ],
    reason: (field?: string) => [
        { required: true, message: Message().required.text(field) },
    ],
});
