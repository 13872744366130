import { observer } from "mobx-react-lite";
import React from "react";
import MyPointsSummery from "./MyProfile/MyRewardPointsMaster/components/MyPointsSummery";
import { Col, Row } from "antd";

const Dashboard: React.FC = observer(() => {
  return (
    <>
      <div className="dashBoardWrap">
        <Row gutter={15}>
          <Col xs={{span:12}} md={{span:8}}>
            <MyPointsSummery/>
          </Col>
        </Row>
      </div>
    </>
  );
});

export default Dashboard;
