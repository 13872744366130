import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import AllPermissionList from "../../../../utils/AllPermissionList";
import useStore from "../../../../store";

const HeaderButtons: React.FC<any> = ({ open }) => {
  const {AUTH} = useStore();

  return (
    <Space>
      <Button 
        icon={<PlusOutlined />}
        type="primary"
        hidden={!AUTH.checkPermission(AllPermissionList.ADD_USER)}       
        onClick={open}>
        Create
      </Button>
    </Space>
  );
};

export default HeaderButtons;
