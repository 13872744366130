import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../config/Config";
import moment from "moment";
import DateComparator from "../../../../../utils/DateComparator";
import { observer } from "mobx-react";

const ListComponent: React.FC<any> = observer(({
  setupGrid,
}) => {
  return (
    <>
      <AgGridWrapper
        onGridReady={setupGrid}
        type="serverSide"
        serverSideInfiniteScroll={true}
        suppressFieldDotNotation={true}
      >
        <AgGridColumn field='order_number' headerName="#CAMPAIGN ID" filter={"agNumberColumnFilter"}/>
        <AgGridColumn field='campaign_name' headerName="Campaign Name" />
        <AgGridColumn field='client_name' headerName="Client Name" />
        <AgGridColumn field='email' headerName="Email" />
        <AgGridColumn field='billing_email' headerName="Billing Email" />
        <AgGridColumn field='shift_name' headerName="GEO" />
        <AgGridColumn field='title' headerName="KPI Title" />
        <AgGridColumn field='status' headerName="KPI Status" />
        <AgGridColumn
          field="created_at"
          headerName="Created At"
          valueGetter={({ data }) =>
            data['created_at'] && moment(data['created_at']).format(Config.dateTimeFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />
        <AgGridColumn
          field="updated_at"
          headerName="Updated At"
          valueGetter={({ data }) =>
            data['updated_at'] &&
            moment(data['updated_at']).format(Config.dateTimeFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />
        <AgGridColumn
          field="created_by_name"
          headerName="Created By"
        />
        <AgGridColumn
          field="updated_by_name"
          headerName="Last Modified By"

        />

        
      </AgGridWrapper>
    </>
  );
});

export default ListComponent;
