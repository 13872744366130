import React from "react";
import { Drawer, Button } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DiscussionMaster from "../DiscussionMaster";
import useStore from "../../../../store";


const DiscussionComponent: React.FC<any> = observer(({ visible, close }) => {
	const { QUOTE } = useStore();
	const {
    editValues,
  } = QUOTE;

  const drawerClose = () => {
    close();
  };


  return (
    <Drawer
      title={editValues?.campaign_name ? "Quote Discussion: "+editValues?.campaign_name : "Quote Discussion"}
      placement="right"
      width={"70%"}
      open={visible}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Close
        </Button>,
      ]}
    >
			<DiscussionMaster />
    </Drawer>
  );
});

export default DiscussionComponent;
