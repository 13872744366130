import React, { useEffect } from "react";
import { Button, Col, Form, Row, notification } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ClientMasterRequest } from "../Validation";
import { Regex } from "../../../../config/Validators";
// import debounce from "lodash/debounce";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    handleSubmit,
    handleChange,
    editValues,
    type,
    clientTypeList,
    shiftList,
  }) => {

    // set the form values to edit
    useEffect(() => {
      let clientEmail: any = [];
      let clientBillingEmail: any = [];

      if (type === "add") {
        clientEmail.push({
          email: "",
        });
        clientBillingEmail.push({
          email: "",
        });
      }
      if (editValues && type === "edit") {
        form.setFieldsValue({
          firstname: editValues.firstname,
          lastname: editValues.lastname,
          company_name: editValues.company_name,
          phone_number: editValues.phone_number,
          client_type_id: editValues.client_type_id,
          shift_id: editValues.shift_id,
        });
        clientEmail = editValues.email.split(', ')?.map((item: any) => {
          return { email: item };
        });
        clientBillingEmail = editValues.billing_email.split(', ')?.map((item: any) => {
          return { billing_email: item };
        });

        handleChange();
      }
      form.setFieldsValue({
        email: clientEmail,
        billing_email: clientBillingEmail,
      });
    }, [editValues, form]);


    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="First Name"
                placeholder="First Name"
                name="firstname"
                rules={ClientMasterRequest().firstname("First Name")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Last Name"
                placeholder="Last Name"
                name="lastname"
                rules={ClientMasterRequest().lastname("Last Name")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                label="Company Name"
                autoComplete="false"
                placeholder="Company Name"
                name="company_name"
                rules={ClientMasterRequest().company_name("Company Name")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Phone Number"
                autoComplete="false"
                placeholder="Phone Number"
                name="phone_number"
                rules={ClientMasterRequest().phone_number("Phone Number")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <div className="ant-col ant-form-item-label">
                <label className="ant-form-item-required" title="Email">
                  Email
                </label>
              </div>
              <Form.List name="email">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <Row gutter={15} key={key}>
                        <Col xs={{ span: 24 }} md={{ span: 22 }}>

                          <InputBox.Text
                            required
                            // label="Email"
                            placeholder="Email"
                            name={[name, "email"]}
                            rules={[
                              {
                                required: true,
                                message: "Email field is required",
                              },
                              { pattern: Regex.email, message: "Please enter valid email" },

                            ]}
                          />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 2 }}>
                          {type !== "view" && name !== 0 && (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          )}
                        </Col>
                      </Row>
                    ))}
                    <Button
                      className="action-column-permission"
                      onClick={() => { if (form.getFieldsValue().email.length < 10) { add(); } else { notification.error({ message: "You can add only 10 emails!" }); } }}
                      icon={<PlusOutlined />}
                      type="primary"
                    >
                      Add Email
                    </Button>
                  </>
                )}
              </Form.List>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <div className="ant-col ant-form-item-label">
                <label className="ant-form-item-required" title="Email">
                 Billing Email
                </label>
              </div>
              <Form.List name="billing_email">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name }) => (
                      <Row gutter={15} key={key}>
                        <Col xs={{ span: 24 }} md={{ span: 22 }}>

                          <InputBox.Text
                            required
                            // label="Email"
                            placeholder="Billing Email"
                            name={[name, "billing_email"]}
                            rules={[
                              {
                                required: true,
                                message: "Billing email field is required",
                              },
                              { pattern: Regex.email, message: "Please enter valid billing email" },

                            ]}
                          />
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 2 }}>
                          {name !== 0 && (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          )}
                        </Col>
                      </Row>
                    ))}
                    <Button
                      className="action-column-permission"
                      onClick={() => { if (form.getFieldsValue().billing_email.length < 10) { add(); } else { notification.error({ message: "You can add only 10 billing emails!" }); } }}
                      icon={<PlusOutlined />}
                      type="primary"
                    >
                      Add Billing Email
                    </Button>
                  </>
                )}
              </Form.List>
            </Col>
            
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: clientTypeList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="client_type_id"
                placeholder="Select Client Type"
                label="Client Type"
                rules={ClientMasterRequest().client_type_id('Client Type')}
              />
            </Col>

            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Select
                options={{
                  list: shiftList,
                  valueKey: "id",
                  textKey: "name"
                }}
                //mode={"multiple"}
                name="shift_id"
                placeholder="GEO"
                label="GEO"
                rules={ClientMasterRequest().shift_id('GEO')}
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
