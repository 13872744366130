import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { CampaignFeedbackRequest } from "../Validation";

const FormComponent: React.FC<any> = observer(
  ({
    form,
    id,
    loading,
    handleSubmit,
    handleChange,
    editValues,
    type,
  }) => {


    // set the form values to edit
    useEffect(() => {

      if (editValues && type === "edit") {
        form.setFieldsValue({
          name: editValues.name,
          campaign_name: editValues.campaign_name,
          email: editValues.email,
          service: editValues.service,
          link: editValues.link,
        });

        handleChange();
      }
    }, [loading]);

    // https://www.mavlers.com/feedback-search-p2p/?fname=XYZ&email=xyz@test.com&campaign=Mavlers&service=SEO
    // https://dev-mavlers.pantheonsite.io/feedback-search-p2p/?fname=XYZ&email=xyz@test.com&campaign=Mavlers&service=SEO
    const handleLinkChange = () => {
      const name = form.getFieldValue("name") ?? ""
      const campaign_name = form.getFieldValue("campaign_name") ?? ""
      const email = form.getFieldValue("email") ?? ""
      const service = form.getFieldValue("service") ?? ""
      form.setFieldsValue({
        //link: 'https://www.mavlers.com/feedback-search-p2p/?' +encodeURIComponent(`fname=${name}&email=${email}&campaign=${campaign_name}&service=${service}`),
        link: 'https://dev-mavlers.pantheonsite.io/feedback-search-p2p/?' + encodeURIComponent(`fname=${name}&email=${email}&campaign=${campaign_name}&service=${service}`),
      })
    }
    return (
      <>
        <FormBox
          form={form}
          id={id}
          onFinish={handleSubmit}
          disabled={type === "edit"}
          onChange={() => { if (type === "edit") handleChange(); }}
        >
          <Row gutter={15}>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Name"
                placeholder="Name"
                name="name"
                onKeyUp={handleLinkChange}
                rules={CampaignFeedbackRequest().name("Name")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Email"
                placeholder="Email"
                name="email"
                onKeyUp={handleLinkChange}
                rules={CampaignFeedbackRequest().email("Email")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Campaign Name"
                placeholder="Campaign Name"
                name="campaign_name"
                onKeyUp={handleLinkChange}
                rules={CampaignFeedbackRequest().campaign_name("Campaign Name")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Service"
                placeholder="Service"
                name="service"
                onKeyUp={handleLinkChange}
                rules={CampaignFeedbackRequest().service("Service")}
              />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <InputBox.Text
                required
                label="Link"
                placeholder="Link"
                name="link"
                rules={CampaignFeedbackRequest().link("Link")}
              />
            </Col>
          </Row>
        </FormBox>
      </>
    );
  }
);

export default FormComponent;
