import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Button, Row, Col, Drawer } from "antd";
import useStore from "../../../../../../store";
import { debounce } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import { PointTypeMasterRequest } from "../Validation";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import Message from "../../../../../../config/Message";

const EditComponent: React.FC<any> = observer(({ visible, close }) => {
  const { POINT_TYPE, ROOT, COMMON } = useStore();
  const { EditData, editValues } = POINT_TYPE;
  const { roleList, fetchRoles } = COMMON;
  const { assignErrorToInput } = ROOT;
  const [form] = Form.useForm();
  const [saving, setSaving] = useState<boolean>(false);
  const [applicableTo, setApplicableTo] = useState<number>(1);

  useEffect(() => {
    fetchRoles().then(() => {
      if (editValues) {
        form.setFieldsValue({
          name: editValues.name,
        });
        const pontTypeDetails: any = [];
        setApplicableTo(editValues.applicable_to);
        if (editValues.applicable_to == 2) {
          editValues?.pointTypeUserMatrix?.forEach((element: any) => {
            pontTypeDetails.push({
              user_type: element?.user_type,
              role_id: element?.user_type == 1 ? "Primary User Responsible" : "Secondary User Responsible",
              type: element?.type,
              points: element?.points
            })
          });
          form.setFieldsValue({
            point_type_data: pontTypeDetails,
          });
        } else {
          editValues?.pointTypeRoleMatrix?.forEach((element: any) => {
            pontTypeDetails.push({
              role_id: element?.role_id,
              type: element?.type,
              points: element?.points
            })
          });
          form.setFieldsValue({
            point_type_data: pontTypeDetails,
          });
        }
        handleChange();
      }
    });
  }, [visible]);
  const drawerClose = () => {
    close();
    form.resetFields();
  };

  const handleSubmit = (data: any) => {
    // if (data?.point_type_data?.length <= 0) {
    //   Notification.error({ message: "Please enter atleast one point type!" });
    //   return;
    // }
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        // const formData = new FormData();
        // for (const name in data) {
        //   data[name] && formData.append(name, data[name]); // there should be values.avatar which is a File object
        // }

        EditData(data, editValues.id)
          .then(() => {
            drawerClose();
          })
          .catch((e: any) => {
            if (e.data) {
              assignErrorToInput(form, e?.data.errors);
            }
          })
          .finally(() => setSaving(false));
      });
  };

  const handleChange = debounce(() => {
    form
      .validateFields()
      .then(() => {
        //setDisabled(false);
      })
      .catch(() => {
        //setDisabled(true);
      });
  }, 500);

  return (
    <Drawer
      title={editValues?.name ? "Edit " + editValues.name + " Point Type" : "Edit Point Type"}
      width={"70%"}
      onClose={drawerClose}
      destroyOnClose
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      open={visible}
      footer={[
        <Button key="2" htmlType="button" className="cancelBtn" onClick={drawerClose}>
          Cancel
        </Button>,
        <Button
          key="1"
          //disabled={disabled}
          form="edigPointTypeForm"
          loading={saving}
          htmlType="submit"
          type="primary"
        >
          Save
        </Button>,
      ]}
    >
      <FormBox
        form={form}
        id="edigPointTypeForm"
        onFinish={handleSubmit}
      //onChange={() => { if (type === "edit") handleChange(); }}
      >
        <Row gutter={15}>
          <Col xs={{ span: 24 }} md={{ span: 24 }}>
            {applicableTo == 1 ?
              <Form.List name="point_type_data">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={15} key={key} >
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>
                          <Form.Item
                            {...restField}

                            name={[name, 'role_id']}
                            rules={PointTypeMasterRequest().role_id("Role")}
                          >
                            <InputBox.Select
                              options={{
                                list: roleList,
                                valueKey: "id",
                                textKey: "name"
                              }}
                              //mode={"multiple"}
                              placeholder="Role"
                              label="Role"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 4 }}>

                          <Form.Item
                            {...restField}
                            name={[name, 'type']}
                            rules={PointTypeMasterRequest().type("Type")}
                          >
                            <InputBox.Radio
                              options={{
                                list: [{ id: 1, value: "+" }, { id: 2, value: "-", }],
                                valueKey: "id",
                                textKey: "value"
                              }}
                              //mode={"multiple"}
                              label="Type"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 8 }}>

                          <Form.Item
                            {...restField}
                            name={[name, 'points']}
                            rules={[
                              { required: true, message: Message().required.text("Points") },
                              {
                                pattern: /^[1-9]{1,10}$|(?=^.{1,10}$)^([1-9]\d*)(\.\d{0,2})?$/,
                                message: "Please enter points with minimum 1 and with 2 decimal points only"
                              },
                            ]}
                          >
                            <InputBox.Text label="Points" placeholder="Points" />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 2 }}>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add Point Type
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List> :
              <Form.List name="point_type_data">
                {(fields) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row gutter={15} key={key} >
                        <Col xs={{ span: 24 }} md={{ span: 5 }} className="font-bold mt-30 ">
                          <h3>{form.getFieldValue('point_type_data')[key]?.role_id}</h3>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 4 }}>

                          <Form.Item
                            {...restField}
                            name={[name, 'type']}
                            rules={PointTypeMasterRequest().type("Type")}
                          >
                            <InputBox.Radio
                              options={{
                                list: [{ id: 1, value: "+" }, { id: 2, value: "-", }],
                                valueKey: "id",
                                textKey: "value"
                              }}
                              //mode={"multiple"}
                              label="Type"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'points']}
                            rules={[
                              { required: true, message: Message().required.text("Points") },
                              {
                                pattern: /^[0-9]{1,10}$|(?=^.{1,10}$)^([1-9]\d*)(\.\d{0,2})?$/,
                                message: "Please enter points with minimum 1 and with 2 decimal points only"
                              },
                            ]}
                          >
                            <InputBox.Text label="Points" placeholder="Points" />
                          </Form.Item>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>}
          </Col>
        </Row>
      </FormBox>
    </Drawer>
  );
});

export default EditComponent;
