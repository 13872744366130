import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";

export default class CommonStore {
	public clientList?: any[];
	public roleList?: any[] = [];
	public shiftList?: any[] = [];
	public departmentList?: any[] = [];
	public reportingManagerList?: any[] = [];
	public clientTypeList?: any[] = [];
	public currencyList?: any[] = [];
	public workModelList?: any[] = [];
	public serviceTypeList?: any[] = [];
	public discussionTypeList?: any[] = [];
	public campaignList?: any[] = [];
	public userList?: any[];
	public packageMasterList?: any[];
	public allCampaignList?: any[];
	public platformList?: any[];
	public escalationTypeList?: any[];
	public campaignDetails?: any;
	public campaignTeam?: any[];
	public pointTypeList?: any;
	public proposalStatus: any = [
		{ id: 0, value: "Pending" },
		{ id: 1, value: "In Progress" },
		{ id: 2, value: "Submitted" },
		{ id: 3, value: "Not Required" },
	];

	public questionnaireStatus: any = [
		{ id: 0, value: "Not Sent" },
		{ id: 1, value: "Sent" },
		{ id: 2, value: "Recieved" },
	];

	public quoteStatus: any = [
		{ id: 0, value: "In Discussion" },
		{ id: 1, value: "Confirmed" },
		{ id: 2, value: "Cancelled" },
	];

	public campaignStatus: any = [
		{ id: 0, value: "Under Review" },
		{ id: 1, value: "Assigned" },
		{ id: 2, value: "In Progress" },
		{ id: 3, value: "Notice Period" },
		{ id: 4, value: "Contract Finished" },
		{ id: 5, value: "Paused" },
		{ id: 6, value: "Lost" },
	];

	public taskStatus: any = [
		{ id: 0, value: "Not Started" },
		{ id: 1, value: "In Progress" },
		{ id: 2, value: "In Review" },
		{ id: 3, value: "Ready To Deliver" },
		{ id: 4, value: "Sent For Client Review" },
		{ id: 5, value: "Done" },
		{ id: 6, value: "Cancelled" },
	];
	public checklistStatus: any = [
		{ id: 0, value: "Pending" },
		{ id: 1, value: "Complete" },
		{ id: 2, value: "Not Applicable" },
	];

	public extraPointStatus: any = [
		{ id: 0, value: "In Review" },
		{ id: 1, value: "Approved" },
		{ id: 2, value: "Rejected" },
	];
	
	public testimonialStatus: any = [
		{ id: 0, value: "In Review" },
		{ id: 1, value: "Approved" },
		{ id: 2, value: "Rejected" },
	];

	public kpiStatusList?: any[] = [];

	private rootstore: RootStore;

	constructor() {
		this.rootstore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setClientList = (value?: any[]): void => {
		this.clientList = value;
	};
	private setUserList = (value?: any[]): void => {
		this.userList = value;
	};

	private setRoleList = (value?: any[]): void => {
		this.roleList = value;
	};

	private setShiftList = (value?: any[]): void => {
		this.shiftList = value;
	};

	private setDepartmentList = (value?: any[]): void => {
		this.departmentList = value;
	};

	private setReportingManagerList = (value?: any[]): void => {
		this.reportingManagerList = value;
	};

	private setClientTypeList = (value?: any[]): void => {
		this.clientTypeList = value;
	};

	private setServiceTypeList = (value?: any[]): void => {
		this.serviceTypeList = value;
	};

	private setCampaignList = (value?: any[]): void => {
		this.campaignList = value;
	};

	private setWorkModelList = (value?: any[]): void => {
		this.workModelList = value;
	};

	private setCurrencyList = (value?: any[]): void => {
		this.currencyList = value;
	};

	private setDiscussionTypeList = (value?: any[]): void => {
		this.discussionTypeList = value;
	};

	private setKpiStatusList = (value?: any[]): void => {
		this.kpiStatusList = value;
	};

	private setPackageMasterList = (value?: any[]): void => {
		this.packageMasterList = value;
	};

	private setAllCampaignList = (value?: any[]): void => {
		this.allCampaignList = value;
	};

	private setPointTypeList = (value?: any[]): void => {
		this.pointTypeList = value;
	};

	private setPlatformList = (value?: any[]): void => {
		this.platformList = value;
	};

	private setCampaingDetails = (value?: any): void => {
		this.campaignDetails = value;
	};
	private setCampaingTeam = (value?: any): void => {
		this.campaignTeam = value;
	};

	private setEscalationTypeList = (value?: any): void => {
		this.escalationTypeList = value;
	};

	//Api calls
	public fetchUser = (): Promise<any> => {
		return axios.post(API_URL.USER_LIST, {}).then(({ data }) => {
			this.setUserList(data.data.data);
		});
	};

	public fetchRoles = (): Promise<any> => {
		return axios.get(API_URL.ROLE_LIST).then(({ data }) => {
			this.setRoleList(data.data);
		});
	};

	public fetchShift = (): Promise<any> => {
		return axios.get(API_URL.SHIFT_LIST).then(({ data }) => {
			this.setShiftList(data.data);
		});
	};

	public fetchDepartment = (): Promise<any> => {
		return axios.get(API_URL.DEPARTMENT_LIST).then(({ data }) => {
			this.setDepartmentList(data.data);
		});
	};

	public fetchReportingManager = (roleId = null): Promise<any> => {
		return axios
			.get(API_URL.MANAGER_LIST + "?role_id=" + roleId)
			.then(({ data }) => {
				this.setReportingManagerList(data.data);
				return data.data;
			});
	};

	public fetchClientType = (): Promise<any> => {
		return axios.get(API_URL.CLIENT_TYPE_LIST).then(({ data }) => {
			this.setClientTypeList(data.data);
			return data.data;
		});
	};

	public fetchServiceType = (): Promise<any> => {
		return axios.get(API_URL.SERVICE_TYPE_LIST).then(({ data }) => {
			this.setServiceTypeList(data.data);
			return data.data;
		});
	};

	public fetchCampaign = (
		serviceTypeId = null,
		campaignTypeId = null
	): Promise<any> => {
		const queryString = [];
		serviceTypeId && queryString.push("service_type_id=" + serviceTypeId);
		campaignTypeId && queryString.push("campaign_type_id=" + campaignTypeId);
		return axios
			.get(API_URL.CAMPAIGN_LIST + "?" + queryString.join("&"))
			.then(({ data }) => {
				this.setCampaignList(data.data);
				return data.data;
			});
	};

	public fetchWorkModel = (): Promise<any> => {
		return axios.get(API_URL.WORK_MODEL_LIST).then(({ data }) => {
			this.setWorkModelList(data.data);
			return data.data;
		});
	};

	public fetchCurrency = (): Promise<any> => {
		return axios.get(API_URL.CURRENCY_LIST).then(({ data }) => {
			this.setCurrencyList(data.data);
			return data.data;
		});
	};

	public fetchDiscussionType = (discussionType: any = null): Promise<any> => {
		return axios
			.get(API_URL.DISCUSSION_TYPE_LIST + "?type=" + discussionType)
			.then(({ data }) => {
				this.setDiscussionTypeList(data.data);
				return data.data;
			});
	};
	public fetchClient = (): Promise<any> => {
		return axios.get(API_URL.CLIENT_LIST).then(({ data }) => {
			this.setClientList(data.data);
			return data.data;
		});
	};
	public fetchRoleWiseUser = (roleType = ""): Promise<any> => {
		return axios
			.get(API_URL.ROLE_WISE_USER_LIST + "?role_type=" + roleType)
			.then(({ data }) => {
				return data.data;
			});
	};
	public fetchQuoteDropdownData = (quoteId: any = null): Promise<any> => {
		return axios
			.get(API_URL.QUOTE_DROPDOWN_LIST + "?quote_id=" + quoteId)
			.then(({ data }) => {
				this.setClientList(data?.data?.client);
				this.setCurrencyList(data?.data?.currency);
				this.setCampaignList(data?.data?.campaign);
				this.setServiceTypeList(data?.data?.serviceType);
				return data.data;
			});
	};
	public fetchCampaignTeamDropdownData = (
		campaignId: any = null,
		taskId: any = null
	): Promise<any> => {
		return axios
			.get(
				API_URL.CAMPAIGN_TEAM_DROPDOWN_LIST +
					"?campaign_id=" +
					campaignId +
					"&task_id=" +
					taskId
			)
			.then(({ data }) => {
				this.setClientList(data?.data?.client);
				this.setCurrencyList(data?.data?.currency);
				this.setCampaignList(data?.data?.campaign);
				this.setServiceTypeList(data?.data?.serviceType);
				this.setPackageMasterList(data?.data?.packageList);
				return data.data;
			});
	};
	public fetchKpiListDropdownData = (): Promise<any> => {
		return axios.get(API_URL.KPI_STATUS_LIST).then(({ data }) => {
			this.setKpiStatusList(data?.data);
			return data.data;
		});
	};

	public fetchPackageMasterDropdownData = (
		campaignId: any = null
	): Promise<any> => {
		return axios
			.get(API_URL.PACKAGE_MASTER_LIST + "?campaign_type_id=" + campaignId)
			.then(({ data }) => {
				this.setPackageMasterList(data?.data);
				return data.data;
			});
	};

	public fetchCampaignDropdownData = (): Promise<any> => {
		return axios.get(API_URL.CAMPAING_LIST).then(({ data }) => {
			this.setAllCampaignList(data?.data);
			return data.data;
		});
	};

	public fetchPointTypeDropdownData = (): Promise<any> => {
		return axios.get(API_URL.POINT_TYPE_LIST).then(({ data }) => {
			this.setPointTypeList(data?.data?.data);
			return data.data;
		});
	};

	public fetchPlatformDropdownData = (): Promise<any> => {
		return axios.get(API_URL.PLATFORM_LIST).then(({ data }) => {
			this.setPlatformList(data?.data);
			return data.data;
		});
	};

	public fetchTestimonialDropdownData = (): Promise<any> => {
		return axios.get(API_URL.TESTIMONIAL_LIST).then(({ data }) => {
			this.setAllCampaignList(data?.data?.campaigns);
			this.setPlatformList(data?.data?.platforms);
			this.setShiftList(data?.data?.shifts);
			return data.data;
		});
	};
	public fetchEscalationDropdownData = (): Promise<any> => {
		return axios.get(API_URL.ESCALATION_LIST).then(({ data }) => {
			this.setAllCampaignList(data?.data?.campaigns);
			this.setPlatformList(data?.data?.platforms);
			this.setShiftList(data?.data?.shifts);
			this.setUserList(data?.data?.users);
			this.setEscalationTypeList(data?.data?.escalationTypes);
			return data.data;
		});
	};

	public fetchCampaignDetails = (id: number): Promise<any> => {
		return axios.get(API_URL.CAMPAIGN.DETAILS(id)).then(({ data }) => {
			this.setCampaingDetails(data.data);
			return data.data;
		});
	};
	public fetchCampaignTeam = (id: number): Promise<any> => {
		const campaignId = id ?? null;
		return axios
			.get(API_URL.CAMPAIGN_TEAM + "?campaign_id=" + campaignId)
			.then(({ data }) => {
				this.setCampaingTeam(data.data);
				return data.data;
			});
	};
	public clearCampaignTeam = () => {
		this.setCampaingTeam([]);
	};

	public uploadFileToServer = (formData: any): Promise<any> => {
		return axios.post(API_URL.FILE_UPLOAD, formData).then(({ data }) => {
			return data.data;
		});
	};
}
