import React from "react";
import { Layout } from "antd";
import moment from "moment";

const AppFooterView: React.FC = () => {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      <>© {moment().year()} <b>{process.env.REACT_APP_NAME}</b> (All right reserved)</>
    </Layout.Footer>
  );
};

export default AppFooterView;
