import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import useStore from "../../../../../store";
import AllPermissionList from "../../../../../utils/AllPermissionList";

const HeaderButtons: React.FC<any> = ({ open }) => {
  const {AUTH} = useStore();
  return (
    <Space>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        hidden={!AUTH.checkPermission(AllPermissionList.ADD_DISCUSSION)}
        onClick={open}>
        Create
      </Button>
    </Space>
  );
};

export default HeaderButtons;
