import { RequestProps } from "../../../../config/InterfacesAndTypes";
import Message from "../../../../config/Message";
import { maxName } from "../../../../config/Validators";



export const CurrencyMasterRequest = (): RequestProps => ({
    name: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
    symbol: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { max: maxName, message: Message().max.string(maxName, field) },
    ],
});

export const ConversionRateRequest = (): RequestProps => ({   
    conversion_rate: (field?: string) => [
        { required: true, message: Message().required.text(field) },
        { pattern: /^[1-9]{1,10}$|(?=^.{1,10}$)^([1-9]\d*)(\.\d{0,2})?$/, message: `Please enter ${field} with minimum 1 and 2 decimal points.` },
    ],
});
