import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../../components/AgGridWrapper/AgGridWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Config from "../../../../../../config/Config";
import useStore from "../../../../../../store";
import AllPermissionList from "../../../../../../utils/AllPermissionList";
import moment from "moment";
import DateComparator from "../../../../../../utils/DateComparator";
import { observer } from "mobx-react";

const ListComponent: React.FC<any> = observer(({
  setupGrid,
  openEditModal,
  selectedCampaignId,
}) => {
  const { AUTH } = useStore();
  const ActionRenderer = (props: any) => {
    return (
      <div className="action-column">
          <Button
            type="text"
            title={"Edit"}
            className="editIcon"
            hidden={!AUTH.checkPermission(AllPermissionList.EDIT_CAMPAIGN_FEEDBACK)}
            onClick={() => {
              openEditModal(props.data);
            }}
          >
            <FontAwesomeIcon icon={faCircleInfo} color={Config.blueBtn} />
          </Button>
      </div>
    );
  };

  useEffect(() => {
//    getData();
  }, [selectedCampaignId]);

  return (
    <>
      <AgGridWrapper
        components={{ ActionRenderer }}
        onGridReady={setupGrid}
        type="serverSide"
        serverSideInfiniteScroll={true}
        suppressFieldDotNotation={true}

      >
        <AgGridColumn field='email' headerName="Email" />
        <AgGridColumn field='campaign_name' headerName="Campaign" />
        <AgGridColumn field='service' headerName="Service" />
        <AgGridColumn field='feedback' headerName="Feedback" />
				<AgGridColumn
          field="created_at"
          headerName="Sent At"
          valueGetter={({ data }) =>
            data['created_at'] && moment(data['created_at']).format(Config.dateTimeFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />
				<AgGridColumn
          field="recieved_at"
          headerName="Recieved At"
          valueGetter={({ data }) =>
            data['recieved_at'] && moment(data['recieved_at']).format(Config.dateTimeFormat)
          }
          filter={"agDateColumnFilter"}
          filterParams={{
            comparator: DateComparator,
          }}
        />

				<AgGridColumn
          field="created_by_name"
          headerName="Created By"
        />
        <AgGridColumn field='campaign_feedback_status' headerName="Status" pinned={'right'} />
				 <AgGridColumn
          headerName="Actions"
          type="actionColumn"
          sortable={false}
          filter={false}
          width={120}
          minWidth={120}
          pinned="right"
        />
      </AgGridWrapper>
    </>
  );
});

export default ListComponent;
