import { makeAutoObservable } from "mobx";
import axios from "axios";
import { doLoginType } from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";
import { Notification } from "../../config/Global";
import Message from "../../config/Message";

export default class AuthStore {
  // Variables
  public user?: any;
  public token?: string;
  public appLoading: boolean;
  public spinLoading: boolean;
  public email?: string;
  private rootStore: RootStore;
  public accessMenu?: any;
  public permissions: any;

  constructor() {
    this.appLoading = true;
    this.spinLoading = true;
    this.rootStore = new RootStore();
    makeAutoObservable(this);
  }

  // Initiate Application Functions
  public InitializeApp = () => {
    this.rootStore.setAxiosBaseUrl();
    this.setAxiosInterceptor();
    this.setupHeaders();
  };

  public setEmail = (value: string): void => {
		this.email = value;
	};

  public setAxiosInterceptor = (): void => {
    axios.interceptors.request.use((config) => {
      this.setSpinLoading(true);
      let header: any = config.headers;
      header = {
        ...header,
        // Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const authToken = localStorage.getItem("token");
      const api_key = localStorage.getItem("api_key") ?? "";
      const app_id = localStorage.getItem("app_id") ?? "";

      header = authToken
        ? {
          ...header,
          Accept: "application/json",
          Authorization: "Bearer " + authToken,
        }
        : {
          ...header,
          Accept: "application/json",
        };
      if (api_key && app_id) {
        if (api_key !== "null" && app_id !== "null") {
          header = {
            ...header,
            apikey: api_key,
            appid: app_id,
          };
        }
      }
      config.headers = header;
      /** In dev, intercepts request and logs it into console for dev */
      return config;
    });

    axios.interceptors.response.use(
      (response) => {
        this.setSpinLoading(false);
        if (response?.data?.notify) {
          Notification.success({ message: response?.data?.notify });
        }
        return response;
      },
      (e) => {
        this.setSpinLoading(false);
        if (e.response) {
          if (e.response.data?.notify) {
            Notification.error({ message: e.response.data?.notify })
          }
          if (e.response.status === 401 || e.response.status === 403) {
            this.setToken();
            this.rootStore.resetStore();
            this.rootStore.setAxiosHeaders();
          }

          return Promise.reject(e.response);
        } else {
          Notification.error({
            message: Message().global.networkIssue,
          });
          return Promise.reject({
            data: [],
            message: Message().global.networkIssue,
          });
          
        }
      }
    );
  };

  public setupHeaders = async (access_token?: string): Promise<void> => {
    this.setAppLoading(true);
    this.rootStore
      .setAxiosHeaders(access_token)
      .then((token) => {
        this.setToken(token);
        this.fetchAuthUser();
      })
      .catch(() => {
        this.setToken();
        this.setUser();
				this.setAppLoading(false);
      });
  };

  // Setter Functions
  public setUser = (value?: any): void => {
    this.user = value;
  };
  public setToken = (value?: string): void => {
    this.token = value;
  };
  public setAppLoading = (value: boolean): void => {
    this.appLoading = value;
  };
  public setSpinLoading = (value: boolean): void => {
    this.spinLoading = value;
  };
  
  public setPermissions = (value:any[]): void => {
		this.permissions = value;
	};

  public getChildMenu = (children = []) => {
    return children.filter((ele:any) => {
      return this.permissions.indexOf(ele.permission)>-1 
    })
  }
	public checkPermission = (value:any): boolean => {
		return this.permissions && this.permissions.length > 0 && this.permissions?.indexOf(value)>=0;
	};

  // API Functions
  public doLogin = async (payload: doLoginType): Promise<any> => {
    return await axios
      .post(API_URL.ADMIN_LOGIN, payload)
      .then(({ data }) => {
        this.setupHeaders(data.data.token);
        this.setPermissions(data.data.permissions);
        
        return data;
      })
      .catch((data) => {
        this.setToken();
        this.setUser();
        this.setPermissions([]);
        return Promise.reject(data);
      });
  };

	public fetchAuthUser = (): Promise<any> => {
		return axios
			.get(API_URL.ME)
			.then(({ data }) => {
				this.setUser(data.data);
        this.setPermissions(data.data.permissions);
				return data;
			})
			.catch(({ data }) => {
				this.rootStore.resetStore();
        this.setPermissions([]);
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			})
			.finally(() => this.setAppLoading(false));
	};

  public doLogout = async (): Promise<any> => {
    return await axios
      .post(API_URL.LOGOUT)
      .then(() => {
        this.rootStore.resetStore();
        this.setToken();
        this.setUser();
        this.setPermissions([]);
      })
      .catch((data) => {
        this.setPermissions([]);
        return Promise.reject(data);
      });
  };

	public forgetPassword = (payload: any): Promise<any> => {
		return axios.post(API_URL.FORGET_PASSWORD, payload).then(() => {
			this.setEmail(payload.email);
		});
	};

	public resetPasswordWithOtp = (otp:string, payload: any): Promise<any> => {
		return axios.post(API_URL.RESET_PASSWORD_OTP(otp), payload).then((data:any) => {
			return data;
		});
	};
}
