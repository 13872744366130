import { Button, Col, Form, Modal, Row } from "antd";
import React, { useState } from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import useStore from "../../../../store";
import { AssignRoleValidation } from "../Validation";

interface AssignRoleProps {
    userList: any[];
    roleList: any[];
    visible?: boolean;
    title: string;
    closeAssignRoleModel: () => void;

}

const AssignRoleComponent: React.FC<AssignRoleProps> = ({
    userList,
    roleList,
    visible,
    title,
    closeAssignRoleModel
}) => {
    const { ROOT, USER } = useStore();
    const { assignErrorToInput } = ROOT;
    const { assignRoleToUser } = USER;
    const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);
    const close = () => {
        closeAssignRoleModel()
    }

    const handleAssignRole = (data: any) => {
        setSaving(true);
        const payLoad = {user_id:data?.user_id,role_id : [data.role_id]}
        
        assignRoleToUser(payLoad).then(() => {
            close();
        }).catch((e:any) => {
            const errors = e?.errors || e.data?.errors
            assignErrorToInput(form, errors);         
        })
        .finally(() => setSaving(false));
    }
    return (
        <Modal
            centered
            title={title}
            open={visible}
            destroyOnClose={true}
            onCancel={close}
            width={1000}
            key="Assignrolemodel"
            footer={[
                <Button
                    key="okbuttonassignrole"
                    loading={saving}
                    form="ASSIGN_ROLE_PERMISSION_MODEL"
                    type="primary"
                    htmlType="submit"
                >
                    Assign Role
                </Button>,
                <Button key="cancelbuttonassignrole" htmlType="button"  onClick={close} className="cancelBtn">
                    Cancel
                </Button>,
            ]}
        >
            <FormBox
                id={"ASSIGN_ROLE_PERMISSION_MODEL"}
                form={form}
                encType="multipart/formdata"
                onFinish={handleAssignRole}
                key="formbasichandelassignrole"
            >
                   <Row gutter={6} wrap={false}>
                        <Col span={8} className="form__input__textbox form__input__selectBox">
                            <InputBox.Select
                                options={{
                                    list: userList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                name="user_id"
                                placeholder="Select User"
                                label="Select User"
                                rules={AssignRoleValidation().user_id()}
                            />
                        </Col>
                    </Row>
                    <Row gutter={6} wrap={false}>
                        <Col span={8} className="form__input__textbox form__input__selectBox">
                            <InputBox.Select
                                options={{
                                    list: roleList,
                                    valueKey: "id",
                                    textKey: "name"
                                }}
                                //mode={"multiple"}
                                name="role_id"
                                placeholder="Select Role"
                                label="Select Role"
                                rules={AssignRoleValidation().roleIds()}
                            />
                        </Col>
                    </Row>
            </FormBox>
        </Modal>
    );
};

export default AssignRoleComponent;
